import {
  Anchor,
  Divider,
  Flex,
  Group,
  Loader,
  Menu,
  Modal,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  IconAlertTriangle,
  IconCheck,
  IconDotsVertical,
  IconFileZip,
  IconTableExport,
  IconX,
} from "@tabler/icons";
import { useState } from "react";
import { Button } from "reactstrap";
import { mutate } from "swr";
import customInstance from "../../api/axios-instance";
import {
  getGetApiOrganizationGetAllOrgsCompilazioniKey,
  postApiPuiUpdatePuiCompilazioneStatus,
  postApiTurUpdateTurCompilazioneStatus,
} from "../../api/endpoints";
import {
  Compilazionetype,
  GetApiFileUploadGetZippedFilesParams,
  GetExcelCompilazioniRequest,
  PuiCompilazioneDto,
  TurCompilazioneDto,
} from "../../api/model";

interface ViewButtonOrgProps {
  orgId?: string;
  orgName?: string;
  compilazione: TurCompilazioneDto | PuiCompilazioneDto;
  listWarnings: string[];
  compilazioneType: Compilazionetype;
  status: string;
  protocollo: string;
  createAt?: string;
  redirectUrl: (step: string) => void;
}

export const ViewButtonOrg = ({
  orgId,
  orgName,
  compilazione,
  listWarnings,
  compilazioneType,
  status,
  protocollo,
  createAt,
  redirectUrl,
}: ViewButtonOrgProps) => {
  const [opened, setOpened] = useState(false);
  const [openedWarning, setOpenedWarning] = useState(false);
  const [compStatus, setCompStatus] = useState("");
  const [zipLoader, setZipLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [excelLoader, setExcelLoader] = useState(false);
  const [note, setNote] = useState("");
  const matches = useMediaQuery("only screen and (max-width: 35rem)");

  const getApiFileUploadGetZippedFilesCustom = (
    params?: GetApiFileUploadGetZippedFilesParams
  ) => {
    return customInstance<Blob>({
      url: `/api/FileUpload/GetZippedFiles`,
      method: "get",
      responseType: "blob",
      params,
    });
  };

  const postApiDraftCompilazioneExcelTurCompilazioneCustom = (
    data?: GetExcelCompilazioniRequest
  ) => {
    return customInstance<Blob>({
      url: `/api/DraftCompilazione/DownloadExcelCompilazioni`,
      method: "post",
      responseType: "blob",
      data,
    });
  };

  function changeStatus({
    id,
    type,
    status,
    note,
  }: {
    id?: string;
    type?: number;
    status?: string;
    note?: string;
  }) {
    setStatusLoader(true);
    if (type === 0) {
      postApiTurUpdateTurCompilazioneStatus({
        turId: id,
        status: status,
        note: note,
      })
        .then((response) => {
          if (response) {
            mutate(getGetApiOrganizationGetAllOrgsCompilazioniKey()).then(() =>
              setStatusLoader(false)
            );
          }
          showNotification({
            title: "Gestione stato",
            message: "Stato della candidatura cambiata con successo!",
            icon: <IconCheck size="1.1rem" />,
            color: "teal",
          });
        })
        .catch((e) => {
          console.log("Error: ", e);
          showNotification({
            title: "Gestione stato",
            message:
              "C'è un problema nel cambio dello stato della candidatura!",
            icon: <IconX size="1.1rem" />,
            color: "red",
          });
        });
      setOpened(false);
    } else {
      postApiPuiUpdatePuiCompilazioneStatus({
        puiId: id,
        status: status,
        note: note,
      })
        .then((response) => {
          if (response) {
            mutate(getGetApiOrganizationGetAllOrgsCompilazioniKey()).then(() =>
              setStatusLoader(false)
            );
            showNotification({
              title: "Gestione stato",
              message: "Stato della candidatura cambiata con successo!",
              icon: <IconCheck size="1.1rem" />,
              color: "teal",
            });
          }
        })
        .catch((e) => {
          console.log("Error: ", e);
          showNotification({
            title: "Gestione stato",
            message:
              "C'è un problema nel cambio dello stato della candidatura!",
            icon: <IconX size="1.1rem" />,
            color: "red",
          });
        });
      setOpened(false);
    }
  }

  function downloadZip(orgId: string, type: number, orgName: string) {
    const typeComp = type === 0 ? "tur" : "pui";
    setZipLoader(true);
    getApiFileUploadGetZippedFilesCustom({ orgId: orgId, type: typeComp }).then(
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Allegati${type === 0 ? "Tur" : "Pui"}-${orgName}.zip`
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        setZipLoader(false);
      }
    );
  }

  function downloadCandidaturaExcel(
    compilazioneId: string[],
    type: string,
    orgName: string
  ) {
    setExcelLoader(true);
    postApiDraftCompilazioneExcelTurCompilazioneCustom({
      compilazioniId: compilazioneId,
      callType: type,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Candidatura${type}-${orgName}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        setExcelLoader(false);
      })
      .catch((e) => {
        console.log("🚀 ~ file: ViewButtonOrg.tsx:213 ~ e:", e);
        setExcelLoader(false);
      });
  }

  return (
    <>
      {listWarnings && (
        <Modal
          opened={openedWarning}
          onClose={() => setOpenedWarning(false)}
          overlayBlur={0.5}
          shadow="xl"
          size={"xl"}
          centered
          radius={8}
          title={
            <Text fw={600} size={20}>
              {listWarnings?.length === 1
                ? `C'è un avviso`
                : `Ci sono ${listWarnings?.length} avvisi`}
            </Text>
          }
        >
          {listWarnings?.map((l, index) => (
            <Group
              key={index}
              pt={16}
              pb={16}
              position={matches ? "center" : undefined}
            >
              <IconAlertTriangle color="#d8ae2e" />
              <Text style={{ flexWrap: "wrap", flex: 1 }}>{l}</Text>
            </Group>
          ))}
        </Modal>
      )}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayColor={"trasparent"}
        overlayBlur={0.5}
        shadow="xl"
        centered
        radius={8}
        title={
          <Text fw={600} size={20}>
            Gestione stato
          </Text>
        }
      >
        <Text>
          Sei sicuro di voler settare lo stato in{" "}
          <span style={{ color: "blue" }}>"{compStatus}"</span>?
        </Text>

        <Textarea
          mt="xl"
          label="Note"
          placeholder="Inserisci una nota inerente al cambio di stato"
          onChange={(e) => setNote(e.target.value)}
        />
        <Group position="center" mt={24}>
          <Button
            onClick={() =>
              changeStatus({
                id: compilazione?.id,
                type: compilazioneType,
                status: compStatus,
                note: note,
              })
            }
            className="btn-primary"
          >
            Conferma
          </Button>
          <Button
            onClick={() => setOpened(false)}
            className="btn-primary-outline"
          >
            Annulla
          </Button>
        </Group>
      </Modal>
      <Group position="apart">
        {statusLoader ? (
          <Loader variant="bars" size={28} ml={20} />
        ) : (
          <>
            <Flex direction={"column"}>
            {status == "Non avviata" ? (
                <Text  size={12} opacity={0.5}></Text>
                ) : (status !== "Aperta"  ? (
                <Text size={12} opacity={0.5}>
                  {protocollo}
                </Text>
              ):(
                <Text  size={12} opacity={0.5}>
                  -
                </Text>
              ))}

              {status !== "Non avviata"  ? (
                <Text>{status}</Text>
              ) : (
                <Text color="grey" opacity={0.5}>
                  Non avviata
                </Text>
              )}
              
              {status !== "Non avviata"  && (
                <Text size={12} opacity={0.5}>
                  {createAt}
                </Text>
              )}
            </Flex>

            <Group style={{ marginLeft: matches ? 0 : -10 }}>
              {status !== "Non avviata" && listWarnings?.length && (
                <Tooltip label={"Controlla avvisi"} withArrow>
                  <div>
                    <IconAlertTriangle
                      color="#d8ae2e"
                      cursor={"pointer"}
                      style={{ marginTop: 5 }}
                      onClick={() => setOpenedWarning(true)}
                    />
                  </div>
                </Tooltip>
              )}
              {status === "Non avviata" || status === "Aperta" ? (
                <></>
              ) : (
                <>
                  {zipLoader ? (
                    <Loader size={24} mt={3} />
                  ) : excelLoader ? (
                    <></>
                  ) : (
                    <Anchor
                      onClick={() =>
                        downloadZip(orgId!, compilazioneType, orgName!)
                      }
                    >
                      <Tooltip label={"Scarica Allegati"} withArrow>
                        <div>
                          <IconFileZip cursor={"pointer"} />
                        </div>
                      </Tooltip>
                    </Anchor>
                  )}
                  {status === "Monitoraggio Chiuso" ? (
                    <></>
                  ) : excelLoader ? (
                    <Loader size={24} mt={3} />
                  ) : zipLoader ? (
                    <></>
                  ) : (
                    <Anchor
                      onClick={() =>
                        downloadCandidaturaExcel(
                          [compilazione?.id!],
                          compilazioneType === 0 ? "Tur" : "Pui",
                          orgName!
                        )
                      }
                    >
                      <Tooltip label={"Scarica Excel"} withArrow>
                        <div>
                          <IconTableExport cursor={"pointer"} />
                        </div>
                      </Tooltip>
                    </Anchor>
                  )}
                  <Menu>
                    {zipLoader || excelLoader ? (
                      <></>
                    ) : (
                      <Menu.Target>
                        <Anchor>
                          <Tooltip label={"Gestione stato"}>
                            <div>
                              <IconDotsVertical cursor={"pointer"} />
                            </div>
                          </Tooltip>
                        </Anchor>
                      </Menu.Target>
                    )}
                    <Menu.Dropdown>
                      <Menu.Label>
                        Gestione stato
                        <Divider />
                      </Menu.Label>
                      {status !== "Istruttoria Sottomessa" && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("Aperta");
                          }}
                        >
                          Aperta
                        </Menu.Item>
                      )}
                      {(status === "In valutazione" || 
                        status === "Istruttoria Sottomessa") && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("Rifiutata");
                          }}
                        >
                          {" "}
                          Rifiutata{" "}
                        </Menu.Item>
                      )}
                      {(status === "Candidatura Sottomessa" ||
                        status === "Integrazione Sottomessa") && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("In valutazione");
                          }}
                        >
                          {" "}
                          In valutazione{" "}
                        </Menu.Item>
                      )}
                      {status === "In valutazione" && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("In integrazione");
                          }}
                        >
                          {" "}
                          In integrazione{" "}
                        </Menu.Item>
                      )}
                      {(status === "In valutazione" || 
                        status === "Istruttoria Sottomessa") && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("Ammissibile");
                          }}
                        >
                          {" "}
                          Ammissibile{" "}
                        </Menu.Item>
                      )}
                      {status === "Istruttoria Sottomessa" && (
                        <Menu.Item
                          onClick={() => {
                            setOpened(true);
                            setCompStatus("Deliberata");
                          }}
                        >
                          {" "}
                          Deliberata{" "}
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </>
              )}
            </Group>
          </>
        )}
      </Group>
      {status !== "Non avviata" && (
        <>
          <Divider mt={10} ml={-10} mr={-10} />
          <Group position="center" mt={20}>
            <Anchor onClick={() => redirectUrl("Domanda")}>Domanda</Anchor>
            {(status === "Ammissibile" ||
              status === "Istruttoria Sottomessa" ||
              status === "Deliberata" ||
              status === "Monitoraggio Aperto" ||
              status === "Monitoraggio Sottomesso" ||
              status === "Monitoraggio Chiuso") && (
              <Anchor onClick={() => redirectUrl("Istruttoria")}>
                Istruttoria
              </Anchor>
            )}
            {(status === "Monitoraggio Aperto" ||
              status === "Monitoraggio Sottomesso" ||
              status === "Monitoraggio Chiuso") && (
              <Anchor onClick={() => redirectUrl("Monitoraggio")}>
                Monitoraggio
              </Anchor>
            )}
          </Group>
        </>
      )}
    </>
  );
};
