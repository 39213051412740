import { Button, Center, Flex, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin, isAuthenticated, user } = useAuth();

  return (
    <Center h="100%" pos={"absolute"} right={0} left={0}>
      <Flex direction={"column"} align={"center"}>
        <Title sx={{ fontSize: 64 }}>404</Title>
        <Text size={36} fw={600}>
          Pagina non trovata
        </Text>
        <Text size={14} fw={400} c={"#A0A0A0"}>
          La pagina richiesta non è al momento disponibile
        </Text>
        <Button
          className="btn-primary"
          style={{ width: 240 }}
          mt={40}
          variant="filled"
          onClick={() =>
            navigate(
              isAuthenticated
                ? isAdmin
                  ? "/admin-panel"
                  : user?.organizations?.length
                  ? "/calls"
                  : "flow-organization"
                : "/"
            )
          }
        >
          Torna alla pagina principale
        </Button>
      </Flex>
    </Center>
  );
};

export default PageNotFound;
