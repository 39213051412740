import { useEffect, useState } from "react";
import {
  getApiDraftCompilazioneGetPuiDraft,
  getApiDraftCompilazioneGetTurDraft,
  getApiDraftCompilazioneHasSubmittedPui,
  getApiDraftCompilazioneHasSubmittedTur,
  getApiPuiGetPuiCompilazione,
  getApiTurGetTurCompilazione,
  useGetApiOrganizationGetOrganization,
} from "../api/endpoints";
import { PuiCompilazioneDto, TurCompilazioneDto } from "../api/model";
import useSelectedOrganizationId from "./useSelectedOrganizationId";

export const useGetCompilazione = ({ callType }: { callType?: string }) => {
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();
  const { data: org, isLoading: isLoadingOrg } =
    useGetApiOrganizationGetOrganization();
  const [candidaturaPui, setCandidaturaPui] = useState<PuiCompilazioneDto>();
  const [candidaturaTur, setCandidaturaTur] = useState<TurCompilazioneDto>();
  // const [orgId] = useState(
  //   localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  // );
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const isLoadingPui = selectedOrgId === null || candidaturaPui === undefined;
  const isLoadingTur = selectedOrgId === null || candidaturaTur === undefined;

  useEffect(() => {
    if (callType === "PUI") {
      getApiDraftCompilazioneHasSubmittedPui({ orgId: org?.id }).then(
        (hasSub) => setHasSubmitted(hasSub)
      );
    } else if (callType === "TUR") {
      getApiDraftCompilazioneHasSubmittedTur({ orgId: org?.id }).then(
        (hasSub) => setHasSubmitted(hasSub)
      );
    }
  }, [callType, org]);

  useEffect(() => {
    if (org) {
      // const firstOrg = Array.isArray(orgs) && orgs.length && orgs[0];
      // if (firstOrg) {
      //   setSelectedOrgId(firstOrg.id!);
      // }
      setSelectedOrgId(org.id!);
    }
  }, [selectedOrgId, org, setSelectedOrgId]);

  useEffect(() => {
    getApiPuiGetPuiCompilazione({ orgId: org?.id }).then((e) => {
      if (e.id === "00000000-0000-0000-0000-000000000000") {
        getApiDraftCompilazioneGetPuiDraft({ orgId: org?.id }).then((draft) => {
          if (draft != null) {
            setCandidaturaPui(draft);
          }
        });
      } else {
        setCandidaturaPui(e);
      }
    });
    getApiTurGetTurCompilazione({ orgId: org?.id }).then((e) => {
      if (e.id === "00000000-0000-0000-0000-000000000000") {
        getApiDraftCompilazioneGetTurDraft({ orgId: org?.id }).then((draft) => {
          if (draft !== null) {
            setCandidaturaTur(draft);
          }
        });
      } else {
        setCandidaturaTur(e);
      }
    });
  }, [isLoadingPui, isLoadingTur, isLoadingOrg, org, selectedOrgId]);

  return {
    candidaturaPui,
    candidaturaTur,
    hasSubmitted,
    setCandidaturaPui,
    setCandidaturaTur,
    isLoadingPui,
    isLoadingTur,
    isLoadingOrg,
  };
};
