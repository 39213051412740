import {
  Avatar,
  Burger,
  Divider,
  Flex,
  Group,
  Header,
  Menu,
  Text,
  createStyles,
} from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useGetApiOrganizationGetOrganization,
  useGetApiUser,
  useGetApiUserIsUserAdmin,
} from "../api/endpoints";
import { ReactComponent as LogoFinit } from "../assets/svg/logo_finint.svg";
import { useAuth } from "../auth/AuthContext";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { DrawerTopBar } from "./DrawerTopBar";

const useStyles = createStyles((theme) => ({
  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1rem solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    "@media (max-width: 51.27em)": {
      display: "none",
    },
  },

  hiddenDesktop: {
    "@media (min-width: 51.27em)": {
      display: "none",
    },
  },
}));

function TopBar() {
  const { classes } = useStyles();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { data: user } = useGetApiUser();
  const { data: org } = useGetApiOrganizationGetOrganization();
  const [dropdown, setDropdown] = useState(false);
  const { data: isAdmin } = useGetApiUserIsUserAdmin();
  const matches = useMediaQuery("only screen and (max-width: 33rem)");

  return !isAuthenticated ? (
    <></>
  ) : (
    <>
      <Header
        p="xs"
        fixed
        dir="row"
        zIndex={100}
        px={20}
        height={80}
        withBorder={isAuthenticated ?? false}
      >
        <Flex justify="space-between" align={"center"}>
          <Group mt={matches ? 14 : 4} align={"center"}>
            <Link
              to={
                isAuthenticated && user?.organizations?.length !== 0
                  ? isAdmin
                    ? "admin-panel"
                    : "/calls"
                  : "/flow-organization"
              }
            >
              <LogoFinit height={"100%"} width={matches ? "5rem" : "7rem"} />
              <img
                src={"./finint-investments_logo.jpg"}
                alt="logo"
                height={"auto"}
                width={matches ? "75rem" : "130rem"}
                style={{ marginLeft: matches ? 15 : 30 }}
              />
              <img
                src={"./LOGO SINLOC.jpg"}
                alt="logo"
                height={"100%"}
                width={matches ? "75em" : "130rem"}
                style={{ marginLeft: matches ? 15 : 30 }}
              />
            </Link>
          </Group>
          <Group className={classes.hiddenMobile}>
            <Group>
              {user && isAuthenticated && (
                <Menu
                  trigger="click"
                  openDelay={100}
                  closeDelay={400}
                  opened={dropdown}
                  onChange={() => setDropdown(!dropdown)}
                >
                  <Menu.Target>
                    <Group>
                      <Text size={16} fw={400}>
                        {user?.firstName} {user?.lastName}
                      </Text>
                      <Avatar color="cyan" radius="xl">
                        {user.firstName &&
                          user.lastName &&
                          (
                            user?.firstName![0] + user?.lastName![0]
                          ).toUpperCase()}
                      </Avatar>
                      {dropdown ? (
                        <IconChevronUp style={{ width: 20, marginLeft: -10 }} />
                      ) : (
                        <IconChevronDown
                          style={{ width: 20, marginLeft: -10 }}
                        />
                      )}
                    </Group>
                  </Menu.Target>
                  <Menu.Dropdown
                    style={{
                      borderRadius: 8,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderColor: "#E0E0E0",
                      borderTop: 1,
                    }}
                    ml={-22}
                    mt={14}
                  >
                    <Menu.Label w={240} mb={3} pt={15} pb={15}>
                      <Group>
                        <Avatar color="cyan" radius="xl" size={40}>
                          {user?.firstName &&
                            user?.lastName &&
                            (
                              user?.firstName![0] + user?.lastName![0]
                            ).toUpperCase()}
                        </Avatar>
                        <Flex direction={"column"}>
                          <Text size={12} fw={400} c={"black"}>
                            {user?.email}
                          </Text>
                          {isAdmin ? (
                            <></>
                          ) : (
                            <Text size={12} fw={600} c={"black"}>
                              {org?.name}
                            </Text>
                          )}
                        </Flex>
                      </Group>
                    </Menu.Label>
                    <Divider ml={-5} mr={-5} c={"#E0E0E0"} />
                    <Menu.Item
                      w={240}
                      pt={15}
                      pb={15}
                      component={Link}
                      to={"/account"}
                    >
                      <Text size={14} fw={400} mt={-2}>
                        {t("components.account")}
                      </Text>
                    </Menu.Item>
                    {isAdmin || !user?.organizations?.length ? (
                      <></>
                    ) : (
                      <Menu.Item
                        w={240}
                        pt={15}
                        pb={15}
                        component={Link}
                        to={`/your-organization/${org?.id}`}
                      >
                        <Text size={14} fw={400} mt={-2}>
                          {t("components.your-organization")}
                        </Text>
                      </Menu.Item>
                    )}
                    {isAdmin && (
                      <Menu.Item
                        component="a"
                        pt={15}
                        pb={15}
                        href={"/admin-panel"}
                      >
                        <Text size={14} fw={400} mt={-2}>
                          Pannello Admin
                        </Text>
                      </Menu.Item>
                    )}
                    <Menu.Item
                      component="a"
                      pt={15}
                      pb={15}
                      c={"red"}
                      href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/logout?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}`}
                    >
                      <Text size={14} fw={400} mt={-2}>
                        Esci
                      </Text>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          </Group>
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
            mt={10}
          />
        </Flex>
      </Header>
      <DrawerTopBar
        opened={drawerOpened}
        onClose={closeDrawer}
        user={user}
        org={org}
        isAdmin={isAdmin}
        isAuthenticated={isAuthenticated}
        classes={classes.hiddenDesktop}
      />
    </>
  );
}

export default TopBar;
