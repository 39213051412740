import { Button, Group, Modal, Paper, Text, Title } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { useState } from "react";
import { mutate } from "swr";
import {
  getApiFileUploadRemoveAttachement,
  getApiPuiGetPuiCompilazione,
  getApiTurGetTurCompilazione,
  getGetApiFileUploadGetAttachmentIntegrationsKey,
  getGetApiFileUploadGetAttachmentTypesKey,
  getGetApiOrganizationGetAllOrgsCompilazioniKey,
  postApiFileUploadUploadFile,
  postApiPuiUpdatePuiCompilazioneStatus,
  postApiTurUpdateTurCompilazioneStatus,
  useGetApiFileUploadGetAttachmentIntegrations,
  useGetApiFileUploadGetAttachmentTypes,
  useGetApiUserIsUserAdmin,
} from "../../api/endpoints";
import { PostApiFileUploadUploadFileBody } from "../../api/model";
import Loading from "../../components/Loading";
import { useGetCompilazione } from "../../hooks/useGetCompilazione";
import { RowAllegato } from "./RowAllegato";
import { RowAllegatoIntegrazione } from "./RowAllegatoIntegrazione";

function SurveyAllegati({
  orgid,
  calltype,
  disabled = false,
  onclick,
  statusTUR,
  statusPUI,
}: {
  orgid: string;
  statusTUR?: string;
  statusPUI?: string;
  calltype: string;
  disabled?: boolean;
  onclick: () => void;
}) {
  const { data: isAdmin } = useGetApiUserIsUserAdmin();
  const [loaderUpload, setLoaderUpload] = useState({ id: "", loader: false });
  const [loaderRemove, setLoaderRemove] = useState({ id: "", loader: false });
  const [valueAttach, setValueAttach] = useState<boolean[]>([]);
  const [downloadId, setDownloadId] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string[]>([]);
  const [statusLoader, setStatusLoader] = useState(false);
  const [opened, setOpened] = useState(false);
  const [orgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );

  const AddRow = () => {
    return (
      <RowAllegatoIntegrazione
        title={`Aggiungi Allegato`}
        loader={loaderUpload}
        valueAttach={false}
        upload={(file: FileWithPath) => {
          uploadFile(file, calltype);
        }}
      />
    );
  };

  const {
    candidaturaPui,
    candidaturaTur,
    hasSubmitted,
    setCandidaturaPui,
    setCandidaturaTur,
  } = useGetCompilazione({ callType: calltype });

  const {
    data: dataAttachments,
    isLoading: isLoadingAttachments,
    error: errorAttachments,
  } = useGetApiFileUploadGetAttachmentTypes({
    orgId: orgid,
    callType: calltype,
  });

  const {
    data: dataAttachintegration,
    isLoading: isLoadingAttachmentintegration,
    error: errorAttachmentIntegration,
  } = useGetApiFileUploadGetAttachmentIntegrations({
    orgId: orgid,
    callType: calltype,
  });

  const idAtt = dataAttachments?.filter(
    (d) => d?.attachedTypeName === "Aggiungi allegato"
  )[0]?.id;

  function downloadFile(url: string, filename: string) {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  }

  function uploadFile(file: FileWithPath, callType: string) {
    setLoaderUpload({
      id: "",
      loader: true,
    });
    const fileUploadReq: PostApiFileUploadUploadFileBody = {
      Nome: file.name,
      Descrizione: file.name,
      TipoFileId: idAtt ?? "",
      File: file,
      OrgId: orgid,
      CallType: callType,
    };
    postApiFileUploadUploadFile(fileUploadReq)
      .then((ret) => {
        setValueAttach([...valueAttach, true]);
        setDownloadId([...downloadId, ret.id!]);
        setFileName([...fileName, ret.fileName!]);
        mutate(
          getGetApiFileUploadGetAttachmentIntegrationsKey({
            orgId: orgid,
            callType: calltype,
          })
        ).then(() => {
          setLoaderUpload({
            id: ret.id!,
            loader: false,
          });
        });
        onclick();
      })
      .catch((e) => {
        console.log("🚀 ~ file: SurveyAllegati.tsx:114 ~ uploadFile ~ e:", e);
      });
  }

  function removeFileUploaded(idDownload: string, index?: number) {
    setLoaderRemove({
      id: idDownload,
      loader: true,
    });
    getApiFileUploadRemoveAttachement({ downloadId: idDownload }).then(() => {
      mutate(
        getGetApiFileUploadGetAttachmentIntegrationsKey({
          orgId: orgid,
          callType: calltype,
        })
      ).then(() =>
        setLoaderRemove({
          id: idDownload,
          loader: false,
        })
      );
      setValueAttach(valueAttach.splice(index!, 1));
      setDownloadId(downloadId.splice(index!, 1));
      setFileName(fileName.splice(index!, 1));
    });
  }

  function integrationCompleted(calltype: string) {
    setOpened(false);
    setStatusLoader(true);

    calltype === "PUI"
      ? postApiPuiUpdatePuiCompilazioneStatus({
          puiId: candidaturaPui?.id,
          status: "Integrazione Sottomessa",
        })
          .then((response) => {
            if (response) {
              mutate(getGetApiOrganizationGetAllOrgsCompilazioniKey()).then(
                () => setStatusLoader(false)
              );
              showNotification({
                title: "Completa Integrazione",
                message: "Integrazione completata con succeso!",
                icon: <IconCheck size="1.1rem" />,
                color: "teal",
              });
            }
            getApiPuiGetPuiCompilazione({ orgId }).then((res) => {
              setCandidaturaPui(res);
            });
          })
          .catch((e) => {
            console.log("Error: ", e);
            showNotification({
              title: "Completa Integrazione",
              message: "C'è un problema nel completamento dell'integrazione!",
              icon: <IconX size="1.1rem" />,
              color: "red",
            });
            setStatusLoader(false);
          })
      : postApiTurUpdateTurCompilazioneStatus({
          turId: candidaturaTur?.id,
          status: "Integrazione Sottomessa",
        })
          .then((response) => {
            if (response) {
              mutate(getGetApiOrganizationGetAllOrgsCompilazioniKey()).then(
                () => setStatusLoader(false)
              );
            }
            showNotification({
              title: "Completa Integrazione",
              message: "Integrazione completata con succeso!",
              icon: <IconCheck size="1.1rem" />,
              color: "teal",
            });
            getApiTurGetTurCompilazione({ orgId }).then((res) => {
              setCandidaturaTur(res);
            });
          })
          .catch((e) => {
            console.log("Error: ", e);
            showNotification({
              title: "Errore Integrazione",
              message: "C'è un problema nel completamento dell'integrazione!",
              icon: <IconX size="1.1rem" />,
              color: "red",
            });
            setStatusLoader(false);
          });
  }

  if (isLoadingAttachments) {
    return <Loading />;
  }

  if (!Array.isArray(dataAttachments) || errorAttachments) {
    mutate(
      getGetApiFileUploadGetAttachmentTypesKey({
        orgId: orgid,
        callType: calltype,
      })
    );
    return <Loading />;
  }

  if (isLoadingAttachmentintegration) {
    return <Loading />;
  }

  if (!Array.isArray(dataAttachintegration) || errorAttachmentIntegration) {
    mutate(
      getGetApiFileUploadGetAttachmentIntegrationsKey({
        orgId: orgid,
        callType: calltype,
      })
    );
    return <Loading />;
  }

  return (
    <Paper p={24} radius={12}>
      <Title
        sx={(theme) => ({
          fontSize: 34,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xl,
          },
        })}
      >
        Allegati
      </Title>
      <Text
        sx={(theme) => ({
          fontSize: theme.fontSizes.lg,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.md,
          },
        })}
        mt={38}
        mb={38}
      >
        Per completare la candidatura carica i seguenti allegati.
      </Text>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        centered
        radius={8}
        title={
          <Text fw={600} size={20}>
            Completa Integrazione
          </Text>
        }
      >
        <Text>
          Sei sicuro di voler procedere? Dopo la conferma, lo stato della
          candidatura passerá a{" "}
          <span style={{ color: "blue" }}>"Integrazione Sottomessa"</span>e non
          sará piú possibile tornare indiero.
        </Text>
        <Group position="center" mt={24}>
          <Button
            onClick={() => integrationCompleted(calltype)}
            className="btn-primary"
          >
            Conferma
          </Button>
          <Button
            onClick={() => setOpened(false)}
            className="btn-primary-outline"
          >
            Annulla
          </Button>
        </Group>
      </Modal>
      {dataAttachments
        ?.filter((item) => item.attachedTypeName !== "Aggiungi allegato")
        ?.map((att) => (
          <RowAllegato
            key={att.id}
            onclick={onclick}
            id={att.id}
            title={att.attachedTypeName!}
            type={att.attachedType}
            asterisk={att.mandatoryInput}
            orgid={orgid}
            downloadId={att.downloadId}
            fileName={att.fileName}
            calltype={calltype}
            download={
              att.attachedTypeName ===
              "Dichiarazione attestante il rispetto dei vincoli DNSH-PUI"
                ? "/DNSH/DICHIARAZIONE DNSH - PUI.pdf"
                : att.attachedTypeName ===
                  "Dichiarazione attestante il rispetto dei vincoli DNSH-TUR"
                ? "/DNSH/DICHIARAZIONE DNSH - TUR.pdf"
                : att.attachedTypeName ===
                  "Privacy - sottoscrizione consenso trattamento dati (modulo allegato)"
                ? "/Mod_042_Informativa_Privacy_Cliente PG_IT.pdf"
                : att.attachedTypeName ===
                  "Accordo di riservatezza (modulo allegato)"
                ? "/Accordo di riservatezza.doc"
                : ""
            }
            disabled={disabled}
          />
        ))}
      {dataAttachintegration.length >= 0 &&
        !isAdmin &&
        hasSubmitted &&
        ((candidaturaPui?.status === "In integrazione" && calltype === "PUI") ||
          (candidaturaTur?.status === "In integrazione" &&
            calltype === "TUR")) && (
          <Group
            position="apart"
            mb="xl"
            sx={{
              justifyContent: "space-between",
              alignItems: "baseline",
              "@media (max-width: 51.27em)": {
                justifyContent: "center",
              },
            }}
          >
            <Title
              mt="xl"
              order={2}
              sx={(theme) => ({
                fontSize: 34,
                "@media (max-width: 51.27em)": {
                  fontSize: theme.fontSizes.xl,
                },
              })}
            >
              Allegati Integrazione
            </Title>
            <Button loading={statusLoader} onClick={() => setOpened(true)}>
              Completa integrazione
            </Button>
          </Group>
        )}
      {((candidaturaPui?.status === "In integrazione" && calltype === "PUI") ||
        (candidaturaTur?.status === "In integrazione" && calltype === "TUR") ||
        (candidaturaPui?.status === "Integrazione Sottomessa" &&
          calltype === "PUI") ||
        (candidaturaTur?.status === "Integrazione Sottomessa" &&
          calltype === "TUR") ||
        statusTUR === "In integrazione" ||
        statusPUI === "In integrazione" ||
        statusPUI === "Integrazione Sottomessa" ||
        statusTUR === "Integrazione Sottomessa") &&
      dataAttachintegration.length > 0
        ? dataAttachintegration.map((att) => (
            <RowAllegatoIntegrazione
              key={att.id}
              title={`Allegato ${att.fileName ? `-  ${att.fileName}` : ""}`}
              loader={loaderRemove}
              type={att.attachedType}
              valueAttach={true}
              idDownload={att.downLoadId}
              download={() =>
                downloadFile(
                  `https://${process.env.REACT_APP_BACKEND_HOSTNAME}/api/FileUpload/GetFile?downloadId=${att.downLoadId}`,
                  att.fileName!
                )
              }
              removeFile={() => removeFileUploaded(att.downLoadId!)}
              disabled={
                isAdmin ||
                (candidaturaPui?.status === "Integrazione Sottomessa" &&
                  calltype === "PUI") ||
                (candidaturaTur?.status === "Integrazione Sottomessa" &&
                  calltype === "TUR")
              }
            />
          ))
        : ((candidaturaPui?.status === "In integrazione" &&
            calltype === "PUI") ||
            (candidaturaTur?.status === "In integrazione" &&
              calltype === "TUR")) &&
          dataAttachintegration.length === 0 && <AddRow />}
      {dataAttachintegration.length > 0 &&
        !isAdmin &&
        hasSubmitted &&
        ((candidaturaPui?.status === "In integrazione" && calltype === "PUI") ||
          (candidaturaTur?.status === "In integrazione" &&
            calltype === "TUR")) && <AddRow />}
    </Paper>
  );
}

export default SurveyAllegati;
