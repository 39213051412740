import {
  Button,
  Card,
  Center,
  Flex,
  Group,
  Image,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface CallProps {
  state: string;
  img: string;
  callType: string;
  monitoraggio: boolean;
}

export const CallCard = ({ state, img, callType, monitoraggio }: CallProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [orgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );
  const statusIstruttoria =
    state === "Ammissibile" ||
    state === "Istruttoria Sottomessa" ||
    state === "Deliberata";
  const statusMonitoraggio =
    state === "Monitoraggio Aperto" ||
    state === "Monitoraggio Sottomesso" ||
    state === "Monitoraggio Chiuso";

  return (
    <Card shadow="sm" radius="md">
      <Card.Section withBorder p="xl" inheritPadding py="xs">
        <Center>
          <Group mt={64} mb={64}>
            <Image src={img} alt="logo" height={64} />
          </Group>
        </Center>
      </Card.Section>
      <Card.Section withBorder style={{ paddingBlock: 30, height: 100 }}>
        {state === null || state === undefined ? (
          <Center>
            <Button
              component="a"
              size="md"
              onClick={() => navigate(`/survey/${callType}_candidatura`)}
            >
              {t("pages.call-card.button-submit")}
            </Button>
          </Center>
        ) : (
          <SimpleGrid>
            <>
              {statusIstruttoria || statusMonitoraggio ? (
                <Flex align={"center"} justify={"center"}>
                  <Button
                    component="a"
                    size="md"
                    variant="outline"
                    onClick={() => navigate(`/survey/${callType}_candidatura`)}
                  >
                    Visualizza domanda
                  </Button>
                </Flex>
              ) : (
                <Flex
                  align={"center"}
                  justify={"space-between"}
                  ml={30}
                  mr={30}
                >
                  <Flex direction={"column"}>
                    <Text fw={400} size={12} c="#B1B1B1">
                      STATO DOMANDA:
                    </Text>
                    <Text fw={600} size={16} c="#212121">
                      {state}
                    </Text>
                  </Flex>
                  {state === "In integrazione" ? (
                    <Button
                      component="a"
                      size="md"
                      variant="outline"
                      onClick={() =>
                        navigate(`/survey/${callType}_candidatura`, {
                          state: {
                            numberStep: 4,
                          },
                        })
                      }
                    >
                      Vai agli allegati
                    </Button>
                  ) : (
                    <Button
                      component="a"
                      size="md"
                      variant="outline"
                      href={`/survey/${callType}_candidatura`}
                    >
                      {state === "Candidatura Sottomessa" ||
                      state === "In valutazione" ||
                      state === "Rifiutata" ||
                      state === "Integrazione Sottomessa"
                        ? "Visualizza"
                        : "Compila"}
                    </Button>
                  )}
                </Flex>
              )}
            </>
          </SimpleGrid>
        )}
      </Card.Section>
      {statusMonitoraggio ? (
        <Card.Section withBorder style={{ paddingBlock: 30, height: 100 }}>
          <Flex align={"center"} justify={"center"}>
            <Button
              component="a"
              size="md"
              variant="outline"
              onClick={() =>
                navigate(`/survey/${callType}_istruttoria/${orgId}`, {
                  state: {
                    isReadOnly: true,
                  },
                })
              }
            >
              Visualizza Istruttoria
            </Button>
          </Flex>
        </Card.Section>
      ) : (
        statusIstruttoria && (
          <Card.Section withBorder style={{ paddingBlock: 30, height: 100 }}>
            {state === "Ammissibile" ? (
              <Center>
                <Button
                  component="a"
                  size="md"
                  onClick={() =>
                    navigate(`/survey/${callType}_istruttoria/${orgId}`)
                  }
                >
                  Compila istruttoria
                </Button>
              </Center>
            ) : state === "Istruttoria Sottomessa" || "Deliberata" ? (
              <SimpleGrid>
                <>
                  <Flex
                    align={"center"}
                    justify={"space-between"}
                    ml={30}
                    mr={30}
                  >
                    <Flex direction={"column"}>
                      <Text fw={400} size={12} c="#B1B1B1">
                        STATO ISTRUTTORIA:
                      </Text>
                      <Text fw={600} size={16} c="#212121">
                        {state === "Istruttoria Sottomessa"
                          ? state.split("Istruttoria ")
                          : state}
                      </Text>
                    </Flex>
                    <Button
                      component="a"
                      size="md"
                      variant="outline"
                      onClick={() =>
                        navigate(`/survey/${callType}_istruttoria/${orgId}`, {
                          state: {
                            isReadOnly: true,
                          },
                        })
                      }
                    >
                      Visualizza
                    </Button>
                  </Flex>
                </>
              </SimpleGrid>
            ) : (
              <></>
            )}
          </Card.Section>
        )
      )}
      {statusMonitoraggio && (
        <Card.Section withBorder style={{ paddingBlock: 30, height: 100 }}>
          {state === "Monitoraggio Aperto" ||
          state === "Monitoraggio Chiuso" ? (
            <Flex align={"center"} justify={"center"}>
              <Button
                component="a"
                size="md"
                variant={state === "Monitoraggio Aperto" ? "filled" : "outline"}
                onClick={() =>
                  navigate(`/survey/${callType}_istruttoria/${orgId}`, {
                    state: {
                      monitoraggio: true,
                      isReadOnly: state === "Monitoraggio Chiuso",
                    },
                  })
                }
              >
                {state === "Monitoraggio Aperto"
                  ? "Monitora il tuo progetto"
                  : "Visualizza monitoraggio"}
              </Button>
            </Flex>
          ) : (
            <SimpleGrid>
              <>
                <Flex
                  align={"center"}
                  justify={"space-between"}
                  ml={30}
                  mr={30}
                >
                  <Flex direction={"column"}>
                    <Text fw={400} size={12} c="#B1B1B1">
                      STATO MONITORAGGIO:
                    </Text>
                    <Text fw={600} size={16} c="#212121">
                      {state.split("Monitoraggio ")}
                    </Text>
                  </Flex>
                  <Button
                    component="a"
                    size="md"
                    variant="outline"
                    onClick={() =>
                      navigate(`/survey/${callType}_istruttoria/${orgId}`, {
                        state: {
                          monitoraggio: true,
                          isReadOnly: true,
                        },
                      })
                    }
                  >
                    Visualizza
                  </Button>
                </Flex>
              </>
            </SimpleGrid>
          )}
        </Card.Section>
      )}
    </Card>
  );
};
