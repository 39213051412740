import { Container, Stack, Text, Title } from '@mantine/core';
import { MonitoringRow } from './MonitoringRow';
import fakeDataMonitoring from '../../utils/fakeDataMonitoring.json'


export const Monitoraggio = () => {
  const { data } = fakeDataMonitoring

  return (
    <Container>
      <Stack spacing="xs" mb="xl">
        <Title order={1}>
          Monitoraggio
        </Title>
        <Text>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </Text>
      </Stack>
      {data.map((row, index) => <MonitoringRow key={index} title={row.title} compilata={row.compilata} link={row.link} />)
      }    </Container>

  )
}

