import {
  Center,
  Container,
  Divider,
  Grid,
  Loader,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
  getGetApiUserKey,
  postApiUserUpdateUser,
  useGetApiUser,
} from "../../api/endpoints";
import { UserDto } from "../../api/model";
import { EditButtonInput } from "../../components/EditButtonInput";
import { useMediaQuery } from "@mantine/hooks";

export const AccountPage = () => {
  const { data: user } = useGetApiUser();
  const [enableName, setEnableName] = useState(true);
  const [enableLastName, setEnableLastName] = useState(true);
  const { t } = useTranslation();
  const matches = useMediaQuery("only screen and (max-width: 35rem)");

  const form = useForm<UserDto>({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      roles: user?.roles,
      id: user?.id,
    },
    validate: {
      firstName: (value) =>
        !value || value.length < 3 ? "Campo Nome obbligatorio" : null,
      lastName: (value) =>
        !value || value.length < 3 ? "Campo Cognome obbligatorio" : null,
    },
  });

  const updateUser = (values: UserDto) => {
    form.setValues({
      firstName: values.firstName ?? user?.firstName,
      lastName: values.lastName ?? user?.lastName,
      email: user?.email,
      roles: user?.roles,
      id: user?.id,
    });
    postApiUserUpdateUser(form.values)
      .then((res) => {
        showNotification({
          title: t("components.pages.your-organization.success"),
          message: "Cambio dati avvenuto con successo",
          icon: <IconCheck size="1.1rem" />,
          color: "teal",
        });
        mutate(getGetApiUserKey());
        form.resetDirty({
          firstName: values.firstName,
          lastName: values.lastName,
        });
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: RegisterOrganization.tsx:46 ~ postApiOrganizationCreateOrganization ~ error:",
          error
        );
        showNotification({
          title: t("pages.account.error"),
          message: t("pages.account.error-values-fields"),
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      });
    setEnableName(true);
    setEnableLastName(true);
  };

  return !user ? (
    <Center style={{ height: "100%" }}>
      <Loader />
    </Center>
  ) : (
    <Container>
      <Stack spacing="xs" mb="xl">
        <Title order={1}>{t("pages.account.account")}</Title>
      </Stack>
      <form onSubmit={form.onSubmit((values) => updateUser(values))}>
        <Grid align="center" grow>
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              label={t("pages.account.name")}
              variant="unstyled"
              disabled={enableName}
              defaultValue={user?.firstName}
              minLength={3}
              {...form.getInputProps("firstName")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          <Grid.Col span={matches ? 4 : 2}>
            <EditButtonInput
              enabled={enableName}
              setEnabled={() => setEnableName(!enableName)}
              setCancel={() => {
                setEnableName(!enableName);
                form.setValues({ firstName: user?.firstName });
              }}
              disabled={!form.isDirty("firstName")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              label={t("pages.account.surname")}
              minLength={3}
              variant="unstyled"
              disabled={enableLastName}
              defaultValue={user?.lastName}
              {...form.getInputProps("lastName")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          <Grid.Col span={matches ? 4 : 2}>
            <EditButtonInput
              enabled={enableLastName}
              setEnabled={() => setEnableLastName(!enableLastName)}
              setCancel={() => {
                setEnableLastName(!enableLastName);
                form.setValues({ lastName: user?.lastName });
              }}
              disabled={!form.isDirty("lastName")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Email"
              variant="unstyled"
              placeholder="Email"
              defaultValue={user?.email}
              disabled
              style={{ width: "300px" }}
              {...form.getInputProps("email")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
