import { Tabs, Text, Title } from "@mantine/core";
import { ViewOrganization } from "./ViewOrganization";
import { PbiReport } from "../powerBi/PbiReport";

export const AdminPanel = () => {
  return (
    <>
      <Title order={1}>Pannello Admin</Title>
      <Tabs defaultValue="ListaOrg">
        <Tabs.List mt={25} mb={25}>
          <Tabs.Tab value="ListaOrg">
            <Text size={16} fw={600}>
              Gestione organizzazioni
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="Report">
            <Text size={16} fw={600}>
              Reportistica
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="ListaOrg">
          <ViewOrganization />
        </Tabs.Panel>
        <Tabs.Panel value="Report">
          <PbiReport />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
