import { EventHandler, PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { EmbedParams } from "../../api/model";
import { useState } from "react";
import { Embed } from 'powerbi-client';
import {
  Button,
  Text,
} from "@mantine/core";
import {
  IconMaximize
} from "@tabler/icons";




function PbiReportComponent({ pbi }: { pbi: EmbedParams }) {
  
  const [report, setReport] = useState<Embed>();
  const fullscreen  = () => {
           
    report!.fullscreen(); // Displays the report in full screen mode.
  }
  return (
    <>
    <Button
            radius={"xl"}
            bg={"#B7D5EF"}
            c={"black"}
            rightIcon={<IconMaximize size={12} />}
            onClick={() => {
              fullscreen();
              
            }}
            sx={{ ":hover": { color: "white", background: "#B7D5EF" } }}
          >
            <Text fw={400} size={12}>
              Fullscreen
            </Text>
          </Button>
    
    <PowerBIEmbed
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        //@ts-ignore
        id: pbi?.embedReport[0]?.reportId,
        //@ts-ignore
        embedUrl: pbi?.embedReport[0]?.embedUrl,
        accessToken: pbi?.embedToken?.token,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: pbi?.showFilters,
            },
          },
          background: models.BackgroundType.Default,
        },
      }}
      eventHandlers={
        new Map<string, EventHandler>([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
            },
          ],
          [
            "error",
            function (event) {
              console.log(event?.detail);
            },
          ],
        ])
      }
      cssClassName={"report-style-class"}
      getEmbeddedComponent={(embeddedReport) => {
        //console.log("embeddedReport",embeddedReport)
        //@ts-ignore
        setReport(embeddedReport);
      }}
      />
      </>
  );
}

export default PbiReportComponent;
