import { Flex, Button } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface EditButtonProps {
  enabled: boolean;
  setEnabled: () => void;
  setCancel: () => void;
  disabled?: boolean;
}

export const EditButtonInput = ({
  setEnabled,
  setCancel,
  enabled,
  disabled,
}: EditButtonProps) => {
  const { t } = useTranslation();

  return !enabled ? (
    <Flex direction={"column"}>
      <Button
        type="submit"
        variant="subtle"
        size="xs"
        td="underline"
        c="#1E9CFF"
        mr={-8}
        disabled={disabled}
      >
        Salva
      </Button>
      <Button
        variant="subtle"
        size="xs"
        td="underline"
        c="black"
        mr={-8}
        onClick={setCancel}
      >
        {t("components.cancel")}
      </Button>
    </Flex>
  ) : (
    <Flex justify="center">
      <Button
        variant="subtle"
        size="xs"
        td="underline"
        c="black"
        mr={-8}
        onClick={setEnabled}
      >
        {t("pages.account.edit")}
      </Button>
    </Flex>
  );
};
