import {
  Anchor,
  Button,
  Center,
  Flex,
  Group,
  Image,
  Loader,
  Text,
  Title,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../assets/svg/logo_finint.svg";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { useEffect, useState } from "react";

const WelcomePage = () => {
  const { t } = useTranslation();
  const { isAuthenticated, user, isAdmin } = useAuth();
  const matches = useMediaQuery("only screen and (max-width: 35rem)");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (isAuthenticated) {
    if (isAdmin) {
      navigate("/admin-panel");
    } else if (user?.organizations?.length) {
      navigate("/calls");
    } else {
      navigate("/flow-organization");
    }
  }

  return (

    <Center h="90%" mt={45}>
      {loader ? (
        <Loader />
      ) : (
        <Flex direction={"column"} align={matches ? "center" : "start"}>
          <Group position={matches ? "center" : "left"}>
            <Logo
              height={"100%"}
              width={"14rem"}
              style={{ marginBottom: "2rem" }}
            />
            <Image
              src={"./finint-investments_logo.jpg"}
              alt="logo"
              height={"100%"}
              maw={200}
              m={20}
            />
            <Image
              src={"./LOGO SINLOC.jpg"}
              alt="logo"
              height={"100%"}
              maw={200}
              m={20}
            />
            <Title
              align={matches ? "center" : "start"}
              sx={{
                fontSize: 48,
                "@media (max-width: 51.27em)": {
                  fontSize: 30,
                },
              }}
            >
              Benvenuto nella piattaforma di candidatura
            </Title>
            <Text size={24} fw={400}>
              {t("pages.welcome-text")}
            </Text>
          </Group>
          <Group my={52} align="center">
            <Anchor
              href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/authorize?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}&scope=offline_access`}
            >
              <Button className="btn-primary-outline">Accedi</Button>
            </Anchor>
            <Anchor
              href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/register?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}`}
            >
              <Button className="btn-primary" variant="filled" ml="xs">
                {t("pages.welcome-signin-label")}
              </Button>
            </Anchor>
          </Group>
        </Flex>
      )}
    </Center>
  );
};

export default WelcomePage;
