import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Divider,
  Grid,
  Group,
  NumberInput,
  Paper,
  Select,
  SelectItem,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconAlertCircle,
  IconAsteriskSimple,
  IconCheck,
  IconX,
} from "@tabler/icons";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  getApiTypologyGetComunefromProv,
  getApiTypologyGetprovfromRegion,
  getGetApiTypologyGetAllFormaGiuridicaKey,
  getGetApiTypologyGetAllRegioneKey,
  getGetApiUserGetUserStatusKey,
  getGetApiUserKey,
  postApiOrganizationCreateOrganization,
  useGetApiTypologyGetAllFormaGiuridica,
  useGetApiTypologyGetAllRegione,
} from "../../api/endpoints";
import { CreateOrgDtoBody } from "../../api/model";
import Loading from "../Loading";
import { useMediaQuery } from "@mantine/hooks";
import toIsoString from "../../utils/FixDateIsoStringFormat";

function RegisterOrganization() {
  const { state: pIva } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectProvince, setSelectProvince] = useState<
    SelectItem[] | undefined
  >();
  const [selectComune, setSelectComune] = useState<SelectItem[] | undefined>();
  const matches = useMediaQuery("only screen and (max-width: 35rem)");

  const {
    data: formaGiuridica,
    isLoading: isLoadingFormaGiuridica,
    error: errorFormaGiuridica,
  } = useGetApiTypologyGetAllFormaGiuridica();
  const {
    data: regionList,
    isLoading: isLoadingRegionList,
    error: errorRegionList,
  } = useGetApiTypologyGetAllRegione();

  const form = useForm<CreateOrgDtoBody>({
    initialValues: {
      name: "",
      formaGiuridica: "",
      pIva: pIva,
      codiceAteco: "",
      altro: "",
      publicParticipation: false,
      percentParticipation: undefined,
      description: "",
      email: "",
      telephone: "",
      privacy: false,
      address: "",
      codiceFiscale: "",
      comune: "",
      contactReference: "",
      luogo_di_nascita: "",
      natura_del_soggetto_proponente: "",
      numCivico: "",
      pec: "",
      province: "",
      region: "",
      role: "",
      website: "",
      data_di_costituzione: undefined,
      data_di_nascita: undefined,
    },
    validate: {
      name: (value) => (!value ? "Campo obbligatorio" : null),
      formaGiuridica: (value) => (!value ? "Campo obbligatorio" : null),
      pIva: (value) => (!value ? "Campo obbligatorio" : null),
      codiceAteco: (value) => (!value ? "Campo obbligatorio" : null),
      description: (value) => (!value ? "Campo obbligatorio" : null),
      email: (value) => (!value ? "Campo obbligatorio" : null),
      telephone: (value) => (!value ? "Campo obbligatorio" : null),
      privacy: (value) => (!value ? "Campo obbligatorio" : null),
      contactReference: (value) => (!value ? "Campo obbligatorio" : null),
    },
  });

  const handleRegion = useCallback(
    (region: string, resetFields: boolean) => {
      if (resetFields) {
        form.setFieldValue("comune", "");
        form.setFieldValue("province", "");
      }
      getApiTypologyGetprovfromRegion({
        regId: region,
      }).then((result) => {
        // form.setValues({ province: "" });
        setSelectProvince(
          result?.map((provincia) => {
            return {
              value: provincia?.id!.toString(),
              label: provincia?.name!.toString(),
            };
          })!
        );
      });
      getApiTypologyGetComunefromProv({
        provId: form.values.province,
      }).then((result) => {
        // form.setValues({ comune: "" });
        setSelectComune(
          result?.map((comune) => {
            return {
              value: comune?.id!.toString(),
              label: comune?.name!.toString(),
            };
          })!
        );
      });
    },
    [form.values]
  );

  const handleProv = useCallback(
    (prov: string, resetFields: boolean) => {
      if (resetFields) {
        form.setFieldValue("comune", "");
      }
      getApiTypologyGetComunefromProv({
        provId: prov,
      }).then((result) => {
        // form.setValues({ comune: "" });
        setSelectComune(
          result?.map((comune) => {
            return {
              value: comune?.id!.toString(),
              label: comune?.name!.toString(),
            };
          })!
        );
      });
    },
    [form.values]
  );

  useEffect(() => {
    handleRegion(form.values.region!, false);
  }, [form.values.region]);

  useEffect(() => {
    handleProv(form.values.province!, false);
  }, [form.values.province]);

  if (isLoadingFormaGiuridica || isLoadingRegionList) {
    return <Loading />;
  }

  if (
    (!Array.isArray(formaGiuridica) || errorFormaGiuridica) &&
    (!Array.isArray(regionList) || errorRegionList)
  ) {
    mutate(getGetApiTypologyGetAllFormaGiuridicaKey());
    mutate(getGetApiTypologyGetAllRegioneKey());
    return <Loading />;
  }

  const handleSubmit = (values: CreateOrgDtoBody) => {
    console.log(
      "🚀 ~ file: RegisterOrganization.tsx:78 ~ handleSubmit ~ values:",
      values
    );
    values.province = values?.province?.toString();
    values.comune = values?.comune?.toString();
    postApiOrganizationCreateOrganization(values)
      .then((res) => {
        if (!res.error) {
          localStorage.removeItem("selectedOrganizationId");
          localStorage.setItem("selectedOrganizationId", res.orgId!);
          console.log("selectedOrganizationId", res.orgId!);
          mutate(getGetApiUserKey());
          mutate(getGetApiUserGetUserStatusKey());
        }
        showNotification({
          title: t(
            `components.forms.register-organization.organization-${
              res.error ? "notRegistered" : "registered"
            }`
          ),
          message: res.error
            ? "Errore durante il salvataggio dell'Organizzazione"
            : t(
                "components.forms.register-organization.registered-successfully"
              ),
          icon: res.error ? (
            <IconX size="1.1rem" />
          ) : (
            <IconCheck size="1.1rem" />
          ),
          color: res.error ? "red" : "teal",
        });
        // navigate("/invite-collaborate");
        navigate("/calls");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: RegisterOrganization.tsx:46 ~ postApiOrganizationCreateOrganization ~ error:",
          error
        );
        showNotification({
          title: "Errore",
          message: "Errore durante il salvataggio",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      });
    closeAllModals();
  };

  const registrationConditions =
    !form.values?.name ||
    !form.values?.pIva ||
    !form.values?.formaGiuridica ||
    !form.values?.codiceAteco ||
    !form.values?.description ||
    !form.values?.contactReference ||
    !form.values?.email ||
    !form.values?.telephone ||
    !form.values?.privacy ||
    (form.values.publicParticipation && !form.values.percentParticipation);

  return (
    <Paper p={24} radius={12} mt={16} withBorder>
      <Text size={32} fw={600} mb={20}>
        {t("components.forms.register-organization.title")}
      </Text>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid align="center">
          <Grid.Col span={12} pb={12}>
            <TextInput
              withAsterisk
              label="Denominazione/Ragione Sociale"
              placeholder="Denominazione/Ragione Sociale"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              withAsterisk
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={11}
              label="P. IVA"
              placeholder="P. IVA"
              {...form.getInputProps("pIva")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <Select
              data={
                formaGiuridica?.map((e) => {
                  return {
                    value: e.id?.toString()!,
                    label: e.name!,
                  };
                })!
              }
              withAsterisk
              label="Forma Giuridica"
              placeholder="Forma Giuridica"
              {...form.getInputProps("formaGiuridica")}
            />
          </Grid.Col>
          {form.values.formaGiuridica === "8" && (
            <Grid.Col span={matches ? 12 : 6} pb={12}>
              <TextInput
                withAsterisk
                label="Inserisci altro tipo di Forma Giuridica"
                {...form.getInputProps("altro")}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              withAsterisk
              label="Codice ATECO"
              placeholder="[es. 55.14]"
              rightSection={
                <Tooltip
                  label="Come riportato in visura camerale nella sezione Attività"
                  position="top-end"
                  withArrow
                >
                  <div>
                    <IconAlertCircle
                      size="1rem"
                      style={{ display: "block", opacity: 0.5 }}
                    />
                  </div>
                </Tooltip>
              }
              {...form.getInputProps("codiceAteco")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              label="Natura del soggetto proponente"
              placeholder="Natura del soggetto proponente"
              {...form.getInputProps("natura_del_soggetto_proponente")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 3} pb={12}>
            <DatePicker
              locale="it"
              label="Data di costituzione"
              placeholder="Data di costituzione"
              value={
                form.values.data_di_costituzione
                  ? new Date(form.values.data_di_costituzione)
                  : undefined
              }
              onChange={(e) =>
                form.setFieldValue(
                  "data_di_costituzione",
                  e ? toIsoString(e) : undefined
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 4} pb={12} mt={22}>
            <Switch
              ml={33}
              fw={"600"}
              label="Partecipazione pubblica"
              labelPosition="left"
              size="md"
              onLabel="SI"
              offLabel="NO"
              onClick={() => form.setFieldValue("percentParticipation", 0)}
              {...form.getInputProps("publicParticipation")}
            />
          </Grid.Col>
          {form.values.publicParticipation && (
            <Grid.Col span={matches ? 12 : 5} pb={12}>
              <NumberInput
                withAsterisk
                min={0}
                maxLength={3}
                label="% partecipazione"
                placeholder="%"
                {...form.getInputProps("percentParticipation")}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              label="PEC"
              placeholder="PEC"
              {...form.getInputProps("pec")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              label="Website"
              placeholder="Website"
              {...form.getInputProps("website")}
            />
          </Grid.Col>
          <Grid.Col pb={12}>
            <Textarea
              withAsterisk
              maxLength={400}
              error="Limite caratteri max superato"
              label="Descrizione attività impresa"
              placeholder="Inserire una breve descrizione della società (anni di attività, campi di applicazione)"
              rightSection={
                <Tooltip
                  label="Inserire una breve descrizione della società (anni di attività, campi di applicazione)"
                  position="top-end"
                  withArrow
                >
                  <div>
                    <IconAlertCircle
                      size="1rem"
                      style={{ display: "block", opacity: 0.5 }}
                    />
                  </div>
                </Tooltip>
              }
              {...form.getInputProps("description")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 5} pb={12}>
            <Select
              data={
                regionList?.map((e) => {
                  return {
                    value: e.id!,
                    label: e.name!,
                  };
                })!
              }
              label="Regione sede legale"
              placeholder="Regione sede legale"
              value={form.values.region}
              onChange={(value) => {
                form.setFieldValue("region", value!);
                handleRegion(value!, true);
              }}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 7} pb={12}>
            <Select
              data={selectProvince ?? []}
              label="Provincia sede legale"
              placeholder="Provincia sede legale"
              value={form.values.province}
              onChange={(value) => {
                form.setFieldValue("province", value!);
                handleProv(value!, true);
              }}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 5} pb={12}>
            <Select
              data={selectComune ?? []}
              label="Comune sede legale"
              placeholder="Comune sede legale"
              {...form.getInputProps("comune")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 5} pb={12}>
            <TextInput
              label="Indirizzo sede legale"
              placeholder="Indirizzo sede legale"
              {...form.getInputProps("address")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 2} pb={12}>
            <TextInput
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              label="Numero civico"
              placeholder="Numero civico"
              {...form.getInputProps("numCivico")}
            />
          </Grid.Col>
          <Grid.Col span={12} pb={12}>
            <TextInput
              withAsterisk
              label="Contatto di riferimento"
              placeholder="Nome e cognome"
              rightSection={
                <Tooltip
                  label="Inserire una persona di riferimento per la candidatura e i relativi recapiti"
                  position="top-end"
                  withArrow
                >
                  <div>
                    <IconAlertCircle
                      size="1rem"
                      style={{ display: "block", opacity: 0.5 }}
                    />
                  </div>
                </Tooltip>
              }
              {...form.getInputProps("contactReference")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 9} pb={12}>
            <TextInput
              label="Luogo di nascita"
              placeholder="Luogo di nascita"
              {...form.getInputProps("luogo_di_nascita")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 3} pb={12}>
            <DatePicker
              locale="it"
              label="Data di nascita"
              placeholder="Data di nascita"
              value={
                form.values.data_di_nascita
                  ? new Date(form.values.data_di_nascita)
                  : undefined
              }
              onChange={(e) =>
                form.setFieldValue(
                  "data_di_nascita",
                  e ? toIsoString(e) : undefined
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              label="Codice fiscale"
              placeholder="Codice fiscale"
              {...form.getInputProps("codiceFiscale")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              label="Ruolo"
              placeholder="Ruolo"
              {...form.getInputProps("role")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              withAsterisk
              type={"email"}
              label="Email"
              placeholder="inserisci un email valida"
              {...form.getInputProps("email")}
            />
          </Grid.Col>
          <Grid.Col span={matches ? 12 : 6} pb={12}>
            <TextInput
              maxLength={10}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              withAsterisk
              label="Numero di telefono"
              placeholder="inserisci numero di telefono"
              {...form.getInputProps("telephone")}
            />
          </Grid.Col>
          <Grid.Col mt={10}>
            <Group>
              <Checkbox
                required={true}
                label={
                  <>
                    Accetto i{" "}
                    <Anchor
                      href="/Mod_042_Informativa_Privacy_Cliente PG_IT.pdf"
                      target="_blank"
                    >
                      termini e condizioni
                    </Anchor>
                  </>
                }
                {...form.getInputProps("privacy")}
              />
              <IconAsteriskSimple
                color="red"
                size={7}
                style={{ marginLeft: -10 }}
              />
            </Group>
          </Grid.Col>
        </Grid>
        <Divider my={30} ml={-25} mr={-25} />
        <Center>
          <Button
            type="submit"
            className="btn-primary"
            disabled={registrationConditions}
          >
            {t("components.forms.register-organization.submit")}
          </Button>
        </Center>
      </form>
    </Paper>
  );
}

export default RegisterOrganization;
