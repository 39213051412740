import { useEffect, useState } from "react";
import {
  getApiSJSurveyGetSurveyByType,
  getApiSJSurveyGetSurveyJsonStructBySurveyID,
  useGetApiPuiGetPuiCompilazione,
  useGetApiSJSurveyGetSurveyTypeList,
  useGetApiTurGetTurCompilazione
} from "../api/endpoints";

export const useGetSetSurvey = ({ callType }: { callType?: string }) => {
  const [istruttoriaJson, setIstruttoriaJson] = useState<string | undefined>();
  const [gresbJson, setgresbJson] = useState<string | undefined>();
  const [surveyIdIstruttoria, setsurveyIdIstruttoria] = useState<string | undefined>();
  const [surveyIdGresb, setsurveyIdGresb] = useState<string | undefined>();
  const { data: surveyType, isLoading: isLoadingSurveyType } = useGetApiSJSurveyGetSurveyTypeList();
  const [localStorOrgId] = useState(localStorage.getItem("selectedOrganizationId")?.replace(/"/g, ""));
  const [puiCompilazioneId, setPuiCompilazioneId] = useState<string | undefined>();
  const [turCompilazioneId, setTurCompilazioneId] = useState<string | undefined>();
  const { data: puiCompilazione } = useGetApiPuiGetPuiCompilazione({ orgId: localStorOrgId! });
  const { data: turCompilazione } = useGetApiTurGetTurCompilazione({ orgId: localStorOrgId! });
   
  useEffect(() => {
    if (callType === "PUI" && !isLoadingSurveyType) {
      const idSurveyName = surveyType?.filter((f) => f.name?.includes("Pui")).map((i) => i.name!);
      const puiIstruttoria = idSurveyName?.filter((t) => t === "Istruttoria-Pui",)[0];
      const puiGresb = idSurveyName?.filter((t) => t === "Gresb-Pui")[0];

      getApiSJSurveyGetSurveyByType({ type: puiIstruttoria }).then((type) => {
        setsurveyIdIstruttoria(type?.id);
        getApiSJSurveyGetSurveyJsonStructBySurveyID({ surveyID: type?.id }).then((json) => {
          setIstruttoriaJson(json)
        })
      });

      getApiSJSurveyGetSurveyByType({ type: puiGresb }).then((type) => {
        setsurveyIdGresb(type?.id);
        getApiSJSurveyGetSurveyJsonStructBySurveyID({ surveyID: type?.id }).then((json) => {
          setgresbJson(json);
        })
      });
      setPuiCompilazioneId(puiCompilazione?.id);
    } else {
      const idSurveyName = surveyType?.filter((f) => f.name?.includes("Tur")).map((i) => i.name!);
      const turIstruttoria = idSurveyName?.filter((t) => t === "Istruttoria-Tur",)[0];
      const turGresb = idSurveyName?.filter((t) => t === "Gresb-Tur")[0];

      getApiSJSurveyGetSurveyByType({ type: turIstruttoria }).then((type) => {
        setsurveyIdIstruttoria(type?.id);
        getApiSJSurveyGetSurveyJsonStructBySurveyID({ surveyID: type?.id }).then((json) => {
          setIstruttoriaJson(json)
        })
      });

      getApiSJSurveyGetSurveyByType({ type: turGresb }).then((type) => {
        setsurveyIdGresb(type?.id);
        getApiSJSurveyGetSurveyJsonStructBySurveyID({ surveyID: type?.id }).then((json) => {
          setgresbJson(json);
        })
      });
      setTurCompilazioneId(turCompilazione?.id);
    }
    
  },[callType, isLoadingSurveyType, puiCompilazione?.id, surveyType, turCompilazione?.id])

  return {
    istruttoriaJson,
    gresbJson,
    surveyIdGresb,
    surveyIdIstruttoria,
    puiCompilazioneId,
    turCompilazioneId,
  };
};
