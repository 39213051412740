import { Anchor, Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  getGetApiTypologyGetAllRegioneKey,
  useGetApiTypologyGetAllRegione,
  useGetApiUser,
} from "../../api/endpoints";
import Loading from "../../components/Loading";
import { TabRegistry } from "./TabRegistry";
import { IconArrowLeft } from "@tabler/icons";

export const YourOrganizationPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: user,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useGetApiUser();

  const {
    data: regionList,
    isLoading: isLoadingRegionList,
    error: errorRegionList,
  } = useGetApiTypologyGetAllRegione();

  if (isLoadingUser || isLoadingRegionList) {
    return <Loading />;
  }

  if ((!user || errorUser) && (!Array.isArray(regionList) || errorRegionList)) {
    mutate(getGetApiTypologyGetAllRegioneKey());
    return <Loading />;
  }

  return (
    <>
      {location?.state?.isAdmin && (
        <Anchor
          onClick={() => navigate(`/admin-panel`)}
          sx={() => ({
            fontSize: 14,
          })}
          underline={false}
        >
          <IconArrowLeft size={18} /> Torna alla lista delle organizzazioni
        </Anchor>
      )}
      <Title
        order={1}
        mt={25}
        sx={() => ({
          "@media (max-width: 51.27em)": {
            width: 350,
            lineBreak: "anywhere",
          },
        })}
      >
        {location?.state?.orgUser?.name ??
          t("components.pages.your-organization.title")}
      </Title>
      <Stack mt={40}>
        <TabRegistry
          org={location?.state?.orgUser ?? user?.organizations![0]}
          reg={regionList}
          isAdmin={location?.state?.isAdmin}
        />
      </Stack>
    </>
  );
};
