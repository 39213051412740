import { Grid, Group, Title } from "@mantine/core";
import Loading from "../../components/Loading";
import { useGetCompilazione } from "../../hooks/useGetCompilazione";
import { CallCard } from "./CallCard";
import {
  useGetApiMonitoraggioShowMonitoraggioPui,
  useGetApiMonitoraggioShowMonitoraggioTur,
} from "../../api/endpoints";
import { useEffect, useState } from "react";

function CallList() {
  const [loader, setLoader] = useState(true);
  const { candidaturaPui, candidaturaTur, isLoadingOrg } = useGetCompilazione({
    callType: undefined,
  });

  const { data: monitoraggioTur } = useGetApiMonitoraggioShowMonitoraggioTur({
    candidaturaId: candidaturaTur?.id,
  });
  const { data: monitoraggioPui } = useGetApiMonitoraggioShowMonitoraggioPui({
    candidaturaId: candidaturaPui?.id,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  console.log("TUR: ", monitoraggioTur);
  console.log("PUI: ", monitoraggioPui);

  return loader || isLoadingOrg ? (
    <Loading />
  ) : (
    <>
      <Group mb="xl">
        <Title order={1}>Fondi</Title>
      </Group>
      <Grid align="flex-start">
        <Grid.Col xs={12} sm={6}>
          <CallCard
            state={candidaturaTur?.status!}
            img={"/esteso/BancaFinint-Fondo-turismo-sostenibile-RGB.png"}
            callType="TUR"
            monitoraggio={monitoraggioTur!}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          <CallCard
            state={candidaturaPui?.status!}
            img={"/esteso/BancaFinint-Fondo-piani-urbani-integrati-RGB.png"}
            callType="PUI"
            monitoraggio={monitoraggioPui!}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default CallList;
