import { Flex, ScrollArea } from "@mantine/core";
// import AcceptAnInvitation from "./AcceptAnInvitation";
// import DividerOrganization from "./DividerOrganization";
import CardConnectOrganization from "./CardConnectOrganization";

function ConnectOrganization() {
  return (
    <ScrollArea h={"auto"} mt={-30} mb={-105} offsetScrollbars mr={-16}>
      <Flex direction={"column"} mt={15} mb={20}>
        <CardConnectOrganization />
        {/* <DividerOrganization />
        <AcceptAnInvitation /> */}
      </Flex>
    </ScrollArea>
  );
}

export default ConnectOrganization;
