import { Button, Divider, Grid, Group, Loader, Text } from "@mantine/core";
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  MS_POWERPOINT_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { IconPaperclip } from "@tabler/icons";

interface IntegrazioneProps {
  type?: string;
  title: string;
  loader?: { id: string; loader: boolean };
  valueAttach: boolean;
  idDownload?: string;
  disabled?: boolean;
  upload?: (file: FileWithPath) => void;
  download?: () => void;
  removeFile?: () => void;
}

export const RowAllegatoIntegrazione = ({
  type,
  title,
  loader,
  valueAttach,
  idDownload,
  disabled,
  upload,
  download,
  removeFile,
}: IntegrazioneProps) => {
  return (
    <>
      <Grid grow align={"center"}>
        <Grid.Col xs={12} sm={7}>
          <Group>
            <Text
              w={"70%"}
              sx={{
                fontSize: 14,
                width: "70%",
                "@media (max-width: 51.27em)": {
                  width: "100%",
                },
              }}
            >
              {title}
              <Text fw={400} size={14} color="dimmed">
                {type ? "" : "(PDF/Excel/Doc/Img/*)"}
              </Text>
            </Text>
          </Group>
        </Grid.Col>
        {(loader?.id === "" && loader?.loader === true) ||
        loader?.id === idDownload ? (
          <Grid.Col span={4}>
            <Loader size={24} />
          </Grid.Col>
        ) : (
          <>
            <Grid.Col span={2}>
              {valueAttach ? (
                <Button
                  variant="outline"
                  w={94}
                  h={34}
                  c={"#1E9CFF"}
                  radius={40}
                  style={{ fontSize: 12, cursor: "default" }}
                  mt={12}
                  mb={11}
                >
                  CARICATO
                </Button>
              ) : (
                upload && (
                  <Dropzone
                    onDrop={(file) => upload(file[0])}
                    onReject={(files) =>
                      console.log(
                        "🚀 ~ file: SurveyAllegati.tsx:204 ~ rejected files:",
                        files
                      )
                    }
                    sx={{ border: 0, width: 52 }}
                    accept={{
                      IMAGE_MIME_TYPE,
                      PDF_MIME_TYPE,
                      MS_WORD_MIME_TYPE,
                      MS_EXCEL_MIME_TYPE,
                      MS_POWERPOINT_MIME_TYPE,
                    }}
                  >
                    <IconPaperclip size={20} color={"#929292"} />
                  </Dropzone>
                )
              )}
            </Grid.Col>
            <Grid.Col span={3} mr={-38}>
              {valueAttach && (
                <>
                  {download && (
                    <Button
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        textDecoration: "underline",
                      }}
                      color="dark"
                      variant="subtle"
                      onClick={() => download()}
                    >
                      Scarica
                    </Button>
                  )}

                  {removeFile && !disabled && (
                    <Button
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        textDecoration: "underline",
                      }}
                      color="dark"
                      variant="subtle"
                      onClick={() => removeFile()}
                    >
                      Rimuovi
                    </Button>
                  )}
                </>
              )}
            </Grid.Col>
          </>
        )}
      </Grid>
      <Divider size="xs" mt={8} mb={8} />
    </>
  );
};
