import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth, useRefreshToken } from "../auth/AuthContext";
import useSelectedOrganizationId from "../hooks/useSelectedOrganizationId";

const LogoutCallback = () => {
  const { setAccessToken } = useAuth();
  const [_, setRefreshToken] = useRefreshToken();
  const navigate = useNavigate();
  const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();

  useEffect(() => {
    console.log("LOGGING OUT");
    setAccessToken(null);
    setRefreshToken(null, 30);
    setSelectedOrgId(null);
    localStorage.clear();
    localStorage.removeItem('selectedOrganizationId');

    navigate("/");
  });

  return <></>;
};
export default LogoutCallback;
