import {
  Text,
  Input,
  Button,
  Group,
  Paper,
  Flex,
  Grid,
  Center,
} from "@mantine/core";
import { IconCheck, IconCircle } from "@tabler/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { postApiSendEmailSendEmail, useGetApiUser } from "../../api/endpoints";
import { LogEmailDto } from "../../api/model";

function InviteCollaborate({ isAdminPanel }: { isAdminPanel?: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState<string[]>([]);
  const { data: user } = useGetApiUser();
  const inviteEmail: LogEmailDto = {
    emails: [email],
    userId: user?.id,
    subject: "",
    message: "",
  };

  function sendEmailToMember(email: string) {
    postApiSendEmailSendEmail(inviteEmail)
      .then(() => setEmailList([...emailList, email]))
      .catch(() => "Error");
  }

  return (
    <>
      <Paper p={24} radius={12} withBorder={!isAdminPanel}>
        <Text size={32} fw={600}>
          {isAdminPanel
            ? t("components.invites-collaborate.invite-other-people-admin")
            : t("components.invites-collaborate.invite-other-people")}
        </Text>
        <Grid grow pt={12}>
          <Grid.Col span={isAdminPanel ? 9 : 10}>
            <Input
              placeholder="Email"
              type={"email"}
              value={email}
              onChange={(event: any) => setEmail(event.target.value)}
            />
          </Grid.Col>
          <Grid.Col span={isAdminPanel ? 3 : 2}>
            <Button
              className="btn-primary"
              disabled={!email.includes("@")}
              onClick={() => sendEmailToMember(email)}
            >
              {t("components.invites-collaborate.invite")}
            </Button>
          </Grid.Col>
        </Grid>
        <Grid mt={26}>
          {!isAdminPanel ? (
            <Grid.Col span={10}>
              <Group position="apart">
                <Flex align={"center"}>
                  <IconCircle
                    size={48}
                    color={"black"}
                    strokeWidth={0.5}
                    style={{ marginRight: 4 }}
                  />
                  <Text size={14} fw={400}>
                    Mario Rossi{" "}
                    <span style={{ color: "#e0e0e0" }}>
                      ({t("components.invites-collaborate.you")})
                    </span>
                  </Text>
                </Flex>
                <Text size={14} fw={400}>
                  {t("components.invites-collaborate.admin")}
                </Text>
              </Group>
            </Grid.Col>
          ) : (
            <></>
          )}
          {emailList.map((e) => (
            <Grid.Col
              span={isAdminPanel ? 9 : 10}
              pl={isAdminPanel ? 8 : 58}
              pt={22}
            >
              <Group position="apart">
                <Text size={14} fw={400}>
                  {e}
                </Text>
                <Text size={14} fw={400} c={"#1E9CFF"}>
                  {t("components.invites-collaborate.invited")}
                  <IconCheck style={{ marginTop: -5 }} size={20} />
                </Text>
              </Group>
            </Grid.Col>
          ))}
        </Grid>
      </Paper>
      {!isAdminPanel ? (
        <Center mt={23}>
          <Button
            className={emailList.length ? "btn-primary" : "btn-primary-outline"}
            onClick={() => navigate("/calls")}
          >
            {emailList.length
              ? t("components.invites-collaborate.continue")
              : t("components.invites-collaborate.skip")}
          </Button>
        </Center>
      ) : (
        <></>
      )}
    </>
  );
}

export default InviteCollaborate;
