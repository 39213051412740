import {
  Alert,
  Button,
  CSSObject,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  SegmentedControl,
  Select,
  SelectItem,
  Stepper,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconAlertCircle,
  IconCheck,
  IconCurrencyEuro,
  IconX,
} from "@tabler/icons";
import "dayjs/locale/it";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  getApiDraftCompilazioneGetTurDraft,
  getApiDraftCompilazioneHasSubmittedTur,
  getApiFileUploadFileUploadValidationTur,
  getApiTypologyGetComunefromProv,
  getApiTypologyGetprovfromRegion,
  getGetApiOrganizationGetOrganizationKey,
  getGetApiTypologyGetAllRegioneKey,
  getGetApiTypologyGetAllTourismKey,
  postApiDraftCompilazioneCreateDraftCompilazione,
  postApiTurCreateUpdateTur,
  useGetApiOrganizationGetOrganization,
  useGetApiTypologyGetAllDesignlevel,
  useGetApiTypologyGetAllRegione,
  useGetApiTypologyGetAllTourism,
  useGetApiTypologyGetTipoprogettiFinanzTur,
} from "../../api/endpoints";
import { TurCompilazioneDto } from "../../api/model";
import Loading from "../../components/Loading";
import deepEquals from "../../utils/deepEquals";
import SurveyAllegati from "./SurveyAllegati";
import SurveyCompleted from "./SurveyCompleted";

function SurveyTUR() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [loaded, setLoaded] = useState(false);

  const [localStorOrgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );

  const [selectProvince, setSelectProvince] = useState<SelectItem[]>();
  const [selectComune, setSelectComune] = useState<SelectItem[]>();
  const [opened, { close, open }] = useDisclosure(false);
  const [visible, setVisible] = useState(false);
  const [percInterv, setPercInterv] = useState<number | undefined>(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [draftLoaded, setDraftLoaded] = useState(false);
  const [checkAllegati, setCheckAllegati] = useState<boolean>();
  const [warning, setWarning] = useState<string[]>([]);

  const numberStep = location?.state?.numberStep;
  const isAdmin = location?.state?.isAdmin;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [active, setActive] = useState(numberStep ?? 0);

  const {
    data: allTourism,
    error: errorTourism,
    isLoading: isLoadingTourism,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetApiTypologyGetAllTourism();

  const {
    data: allRegion,
    error: errorRegione,
    isLoading: isLoadingRegione,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetApiTypologyGetAllRegione();

  const {
    data: org,
    error: errorOrg,
    isLoading: isLoadingrOrg,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetApiOrganizationGetOrganization();

  const {
    data: designLevel,
    error: errorDesignLevel,
    isLoading: isLoadingDesignLevel,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetApiTypologyGetAllDesignlevel();

  const {
    data: tipoProgetti,
    error: errorTipoProgetti,
    isLoading: isLoadingTipoProgetti,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetApiTypologyGetTipoprogettiFinanzTur();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = useForm<TurCompilazioneDto>({
    initialValues: {
      organizationId: localStorOrgId,
      progettista: "",
      altroTipoIntervento: "",
      costiCapex: 0,
      costiDdebito: 0,
      costiEquity: 0,
      costiFontiCopertura: 0,
      costiIintervento: 0,
      costiImmobili: 0,
      costiNomeCofinanziamento: "",
      costiPubblico: 0,
      costiTecnicaIntervento: "",
      costiTotale: 0,
      costiTotaleCapexTransDigital: 0,
      costiTotaleCapexTransEnerg: 0,
      costiTotaleCircolante: 0,
      descrizione: "",
      impresaEsecutriceDeilavori: "",
      modalitaEsecuzione: "",
      percTotInvest: undefined,
      percTransEnerg: undefined,
      percTransDigital: undefined,
      previsioneInizioLavori: false,
      progAreaTematica: "",
      progComune: "",
      progNome: "",
      progProvincia: "",
      progRegione: "",
      tempiDescrizione: "",
      tempiFineLavori: "",
      tempiInizioLavori: new Date().toISOString(),
      tempiDurataLavori: 0,
      tempiLivello: "",
      tempiPermessi: undefined,
      tempiStadioIter: "",
      tempiTipoAut: "",
      tipoIntervento: [],
      warningsTur: [],
      turSurveyDto: {
        ristrutturazione: "",
        mqSLP: undefined,
        tipoInterventoPrevisione: [],
        consumoMWh: undefined,
        classeEnergeticaPartenza: [],
        classeEnergeticaRaggiunta: [],
        energiaRinnovabile: undefined,
        potenzaFER: undefined,
        quantitaFER: undefined,
        superficieCopertaRecuperata: undefined,
        superficieCoperta: "",
        superficieVerde: "",
        superficieVerdeAggiunta: undefined,
        piantumazioni: "",
        numeroPiantumazioni: undefined,
        forzaLavoro: undefined,
        prevIncrementoTuristi: "",
        incrementoTuristi: undefined,
        prevIncrementoForzaLavoro: undefined,
        contestoPaesaggistico: "",
        tipoContestoPaesaggistico: "",
        mobilitaSostenibile: "",
        tipoMobilitaSostenibile: [],
        numInterventiMobilitaSostenibile: undefined,
        noteEmissioneFaseCostruzione: "",
        noteEmissioneFaseEsercizio: "",
        noteFontiEnergiaRinnovabile: "",
        noteConsumoSuolo: "",
        noteAreeVerdi: "",
        noteAlberi: "",
        noteNuoviPostiLavoroIntervento: "",
        noteNuoviPostiLavoroTurismo: "",
        noteSpesaTuristi: "",
        noteTutelaPaesaggio: "",
        notePromozioneMezziTrasportoAlternativi: "",
      },
    },

    validate: (value) => ({
      progNome: !value.progNome ? "Campo obbligatorio" : null,
      progRegione: !value.progRegione ? "Campo obbligatorio" : null,
      progProvincia: !value.progProvincia ? "Campo obbligatorio" : null,
      progComune: !value.progComune ? "Campo obbligatorio" : null,
      descrizione: !value.descrizione ? "Campo obbligatorio" : null,
      progAreaTematica: !value.progAreaTematica ? "Campo obbligatorio" : null,
      tipoIntervento: !value.tipoIntervento ? "Campo obbligatorio" : null,
      costiTotale: !value.costiTotale
        ? "Campo obbligatorio"
        : value.costiTotale !== value.costiFontiCopertura
          ? `I valori "Totale investimento" e "Fonti di copertura" devono essere uguali`
          : null,
      costiFontiCopertura: !value.costiFontiCopertura
        ? "Campo obbligatorio"
        : null,
      costiImmobili: !value.costiImmobili ? "Campo obbligatorio" : null,
      costiCapex: !value.costiCapex ? "Campo obbligatorio" : null,
      costiTotaleCapexTransEnerg: !value.costiTotaleCapexTransEnerg
        ? "Campo obbligatorio"
        : null,
      costiTotaleCapexTransDigital: !value.costiTotaleCapexTransDigital
        ? "Campo obbligatorio"
        : null,
      costiTotaleCircolante: !value.costiTotaleCircolante
        ? "Campo obbligatorio"
        : null,
      costiIintervento: !value.costiIintervento ? "Campo obbligatorio" : null,
      costiDdebito: !value.costiDdebito ? "Campo obbligatorio" : null,
      costiEquity: !value.costiEquity ? "Campo obbligatorio" : null,
      costiPubblico: !value.costiPubblico ? "Campo obbligatorio" : null,
      costiNomeCofinanziamento: !value.costiNomeCofinanziamento
        ? "Campo obbligatorio"
        : null,
      costiTecnicaIntervento: !value.costiTecnicaIntervento
        ? "Campo obbligatorio"
        : null,
      tempiLivello: !value.tempiLivello ? "Campo obbligatorio" : null,
      tempiStadioIter: !value.tempiStadioIter ? "Campo obbligatorio" : null,
      tempiTipoAut: !value.tempiTipoAut ? "Campo obbligatorio" : null,
      tempiDescrizione: !value.tempiDescrizione ? "Campo obbligatorio" : null,
      tempiPermessi: !value.tempiPermessi ? "Campo obbligatorio" : null,
      tempiDurataLavori: !value.tempiDurataLavori ? "Campo obbligatorio" : null,
      tempiInizioLavori: !value.tempiInizioLavori ? "Campo obbligatorio" : null,
    }),
  });

  const nextStep = () =>
    setActive((current: any) => (current < 6 ? current + 1 : current));
  const prevStep = () =>
    setActive((current: any) => (current > 0 ? current - 1 : current));

  const transformValues = (values: typeof form.values) => ({
    ...values,
    organizationId: localStorOrgId,
    progProvincia: values?.progProvincia!.toString(),
    progComune: values?.progComune!.toString(),
  });

  const calcoloData = (inizioData: any, mesi: number) => {
    if (inizioData === undefined || inizioData === null) {
      return;
    } else {
      const fineData = new Date(inizioData);
      fineData.setMonth(fineData.getMonth() + mesi);
      return (form.values.tempiFineLavori = fineData.toISOString());
    }
  };

  const getPerc = (value: number) => {
    const percIntervFond = (value / form?.values?.costiTotale!) * 100;
    if (isNaN(percIntervFond) || percIntervFond === Infinity) {
      setPercInterv(0);
      form.setFieldValue("percTotInvest", 0);
    } else {
      setPercInterv(percIntervFond);
      form.setFieldValue("percTotInvest", percIntervFond);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    form.setFieldValue("costiFontiCopertura", sommaFontiCopertura);
    return;
  }, [
    form.values.costiIintervento,
    form.values.costiDdebito,
    form.values.costiEquity,
    form.values.costiPubblico,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getPerc(form?.values?.costiIintervento!);
  }, [form.values.costiTotale, form.values.costiIintervento]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const orgSurveyTur = location?.state?.orgSurveyTur;
    const draftSurveyTur = location?.state?.draftSurveyTur;
    mutate(getGetApiTypologyGetAllRegioneKey());

    getApiDraftCompilazioneHasSubmittedTur({
      orgId: orgSurveyTur
        ? orgSurveyTur?.organizationId
        : draftSurveyTur
          ? draftSurveyTur?.organizationId
          : localStorOrgId!,
    }).then((hasSub: boolean | ((prevState: boolean) => boolean)) =>
      setHasSubmitted(hasSub)
    );

    getApiDraftCompilazioneGetTurDraft({
      orgId: orgSurveyTur
        ? orgSurveyTur?.organizationId
        : draftSurveyTur
          ? draftSurveyTur?.organizationId
          : localStorOrgId!,
    })
      .then((data) => {
        if (data != null) {
          form.setValues(data);
          setDraftLoaded(true);
          if (data.progRegione !== null) {
            getApiTypologyGetprovfromRegion({
              regId: data.progRegione,
            }).then((result) => {
              setSelectProvince(
                result?.map((provincia) => {
                  return {
                    value: provincia?.id!,
                    label: provincia?.name!,
                  };
                })
              );
              form.setFieldValue("progProvincia", data.progProvincia);
            });
          }
          if (data.progProvincia !== null) {
            getApiTypologyGetComunefromProv({
              provId: data.progProvincia,
            }).then((result) => {
              setSelectComune(
                result?.map((comune) => {
                  return {
                    value: comune?.id!,
                    label: comune?.name!,
                  };
                })!
              );
              form.setFieldValue("progComune", data.progComune);
            });
          }
        }
      })
      .finally(() => {
        setLoaded(true);
        if (form.values.tempiInizioLavori !== undefined) {
          calcoloData(
            form.values.tempiInizioLavori,
            form.values.tempiDurataLavori!
          );
        }
      });
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!hasSubmitted && loaded && !isAdmin) {
      mutate(getGetApiTypologyGetAllRegioneKey());
      // form.setFieldValue("tempiInizioLavori", dataInizio?.toISOString());
      form.setFieldValue("warningsTur", warning);

      getApiFileUploadFileUploadValidationTur({
        orgId: localStorOrgId,
        callType: "TUR",
      }).then((res) => {
        setCheckAllegati(res);
      });

      form.values.status = "Aperta";
      form.values.percTransDigital = percDigital;
      form.values.percTransEnerg = percTransEnerg;
      postApiDraftCompilazioneCreateDraftCompilazione({
        compilazioneType: 0,
        json: JSON.stringify({
          ...form.values,
          // tempiInizioLavori: dataInizio?.toISOString(),
        }),
        organizationId: localStorOrgId,
      });
    }
  }, [active]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getApiTypologyGetprovfromRegion({ regId: form.values.progRegione }).then(
      (result) => {
        setSelectProvince(
          result?.map((provincia) => {
            return {
              value: provincia?.id!,
              label: provincia?.name!,
            };
          })!
        );
      }
    );
  }, [form.values.progRegione]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getApiTypologyGetComunefromProv({ provId: form.values.progProvincia }).then(
      (result) => {
        setSelectComune(
          result?.map((comune) => {
            return {
              value: comune?.id!,
              label: comune?.name!,
            };
          })!
        );
      }
    );
  }, [form.values.progProvincia]);
  const percTransEnerg = (form?.values?.costiTotaleCapexTransEnerg! / form?.values?.costiIintervento!) * 100;
  console.log("🚀 ~ file: SurveyTUR.tsx:427 ~ SurveyTUR ~ percTransEnerg:", percTransEnerg)
  const percDigital = (form?.values?.costiTotaleCapexTransDigital! / form?.values?.costiIintervento!) * 100;
  console.log("🚀 ~ file: SurveyTUR.tsx:429 ~ SurveyTUR ~ percDigital:", percDigital)
  const tenPercWarning = form?.values?.costiImmobili! > 0.1 * form?.values?.costiIintervento!;
  const percTransEnergWarning = percTransEnerg < 50;
  const percDigitalWarning = percDigital < 20;
  const valoreSupWarning = form.values.costiIintervento! > 16000000;
  const percIntervWarning = percInterv! > 50;
  const dateExceeded =
    new Date(form?.values?.tempiFineLavori!) > new Date("2026-08-31");

  const tenPercMessage =
    "Spesa acquisto immobili/terreni superiore al 10% del totale investimento";
  const valoreMessage =
    "Il valore dell'entità intervento fondo non può superare i 16 milioni di euro";
  const percIntervMessage = `La percentuale intervento fondo su totale investimento non può superare il 50%`;
  const percTransEnergMessage = `La percentuale  "transizione energetica su intervento FTT" non può essere inferiore al 50%`;
  const percDigitalMessage = `La percentuale  "digitalizzazione su intervento FTT" non può essere inferiore al 20%`;

  if (!warning.includes(tenPercMessage) && tenPercWarning) {
    setWarning([...warning, tenPercMessage]);
  } else if (warning.includes(tenPercMessage) && !tenPercWarning) {
    const warningList = warning.filter((message) => message !== tenPercMessage);
    setWarning(warningList);
  }

  if (!warning.includes(valoreMessage) && valoreSupWarning) {
    setWarning([...warning, valoreMessage]);
  } else if (warning.includes(valoreMessage) && !valoreSupWarning) {
    const warningList = warning.filter((message) => message !== valoreMessage);
    setWarning(warningList);
  }

  if (!warning.includes(percIntervMessage) && percIntervWarning) {
    setWarning([...warning, percIntervMessage]);
  } else if (warning.includes(percIntervMessage) && !percIntervWarning) {
    const warningList = warning.filter(
      (message) => message !== percIntervMessage
    );
    setWarning(warningList);
  }

  if (!warning.includes(percTransEnergMessage) && percTransEnergWarning) {
    setWarning([...warning, percTransEnergMessage]);
  } else if (
    warning.includes(percTransEnergMessage) &&
    !percTransEnergWarning
  ) {
    const warningList = warning.filter(
      (message) => message !== percTransEnergMessage
    );
    setWarning(warningList);
  }

  if (!warning.includes(percDigitalMessage) && percDigitalWarning) {
    setWarning([...warning, percDigitalMessage]);
  } else if (warning.includes(percDigitalMessage) && !percDigitalWarning) {
    const warningList = warning.filter(
      (message) => message !== percDigitalMessage
    );
    setWarning(warningList);
  }
  const locationOrgId = location?.state?.organizationId;
  const statusTUR = location?.state?.turCompilazione?.status;

  const sommaFontiCopertura =
    form.values.costiIintervento! +
    form.values.costiDdebito! +
    form.values.costiEquity! +
    form.values.costiPubblico!;

  if (
    isLoadingTourism ||
    isLoadingRegione ||
    isLoadingrOrg ||
    isLoadingDesignLevel ||
    isLoadingTipoProgetti ||
    !draftLoaded
  ) {
    return <Loading />;
  }

  if (
    (!Array.isArray(allTourism) || errorTourism) &&
    (!Array.isArray(allRegion) || errorRegione) &&
    (!Array.isArray(designLevel) || errorDesignLevel) &&
    (!Array.isArray(tipoProgetti) || errorTipoProgetti) &&
    (!org || errorOrg)
  ) {
    mutate(getGetApiTypologyGetAllTourismKey());
    mutate(getGetApiTypologyGetAllRegioneKey());
    mutate(getGetApiOrganizationGetOrganizationKey());
    return <Loading></Loading>;
  }

  function handleSubmit(values: TurCompilazioneDto): void {
    setVisible(true);
    values.allegatoId = "c56c2e42-1fa3-45e1-b262-5fe079d06006";
    values.percTransEnerg = percTransEnerg;
    values.percTransDigital = percDigital;

    getApiDraftCompilazioneGetTurDraft({ orgId: localStorOrgId! }).then(
      (data) => {
        console.log("draft and form values match: ", deepEquals(data, values));
      }
    );

    if (hasSubmitted) return;

    values.status = "Candidatura Sottomessa";
    postApiTurCreateUpdateTur(transformValues(values))
      .then((res) => {
        showNotification({
          title: !!res.error
            ? t("notifications.submission-error")
            : t("notifications.submission"),
          message: res.error
            ? "Controlla che tutti i campi obbligatori siano compilati"
            : "Salvataggio avvenuto con successo",
          icon: res.error ? (
            <IconX size="1.1rem" />
          ) : (
            <IconCheck size="1.1rem" />
          ),
          color: res.error ? "red" : "teal",
        });
        navigate("/candidaturaCompletata");
        setVisible(false);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SurveyTUR.tsx:484 ~ handleSubmit ~ error:",
          error
        );
        values.status = "";
        showNotification({
          title: "Errore",
          message: "Controlla che tutti i campi obbligatori siano compilati",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
        setVisible(false);
      });
  }

  const openModal = () =>
    openConfirmModal({
      title: "Conferma",
      children: (
        <Text size="sm">
          Sicuro di inviare la candidatura. Non sarà più possibile modificarla.
        </Text>
      ),
      labels: { confirm: "Invia", cancel: "Indietro" },
      onCancel: () => {
        console.log("Cancel");
      },
      onConfirm: () => {
        console.log("Confirmed");
        handleSubmit(form.values);
      },
    });

  function handleAllegati() {
    getApiFileUploadFileUploadValidationTur({
      orgId: localStorOrgId,
      callType: "TUR",
    }).then((res) => {
      setCheckAllegati(res);
    });
  }

  const progettoConditions =
    !form.values?.progNome ||
    !form.values?.progRegione ||
    !form.values?.progProvincia ||
    !form.values?.progComune ||
    !form.values?.progAreaTematica ||
    form.values?.tipoIntervento?.length === 0 ||
    !form.values?.descrizione;

  const costiConditions =
    form.values?.costiTotale === undefined ||
    form.values?.costiImmobili === undefined ||
    form.values?.costiCapex === undefined ||
    form.values?.costiTotaleCapexTransEnerg === undefined ||
    form.values?.costiTotaleCapexTransDigital === undefined ||
    form.values?.costiTotaleCircolante === undefined ||
    form.values?.costiIintervento === undefined ||
    form.values?.costiDdebito === undefined ||
    form.values?.costiEquity === undefined ||
    form.values?.costiPubblico === undefined ||
    !form.values?.costiNomeCofinanziamento ||
    !form.values?.costiTecnicaIntervento;

  const tempisticheConditions =
    !form.values?.tempiLivello ||
    !form.values?.tempiStadioIter ||
    !form.values?.tempiTipoAut ||
    form.values?.tempiPermessi === undefined ||
    !form.values?.tempiDescrizione ||
    form.values?.tempiDurataLavori === undefined ||
    !form.values?.tempiInizioLavori;

  const sxStepperStepStyles = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }): CSSObject => ({
    "& .mantine-Stepper-stepIcon": {
      borderColor:
        isAdmin || hasSubmitted
          ? "#228BE6"
          : active > activeNumber || active < activeNumber
            ? condition
              ? "#F1F3F5"
              : "#228BE6"
            : "",
      backgroundColor:
        isAdmin || hasSubmitted
          ? active === activeNumber
            ? "white"
            : "#228BE6"
          : active > activeNumber || active < activeNumber
            ? condition
              ? "#F1F3F5"
              : "#228BE6"
            : "#F1F3F5",
      color:
        isAdmin || hasSubmitted
          ? active === activeNumber
            ? "#228BE6"
            : "#F1F3F5"
          : active > activeNumber || active < activeNumber
            ? condition
              ? "#F1F3F5"
              : "white"
            : "#228BE6",
    },
  });

  const iconStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted ? null : active > activeNumber ||
      active < activeNumber ? (
      condition ? (
        <Text c={"#495057"}>{activeNumber + 1}</Text>
      ) : (
        <IconCheck />
      )
    ) : null;

  const colorStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted
      ? "#228BE6"
      : active > activeNumber || active < activeNumber
        ? condition
          ? "#F1F3F5"
          : "#228BE6"
        : "";

  const completedIconStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted ? (
      <>{activeNumber + 1}</>
    ) : active > activeNumber || active < activeNumber ? (
      condition ? (
        <Text c={"#495057"}>{activeNumber + 1}</Text>
      ) : (
        <IconCheck />
      )
    ) : (
      <></>
    );
  return (
    <Paper p={24} radius={12} withBorder>
      <Title
        sx={(theme) => ({
          fontSize: 34,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xl,
          },
        })}
        mb="xl"
      >
        Candidatura Fondo Tematico Turismo Sostenibile
      </Title>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        <Stepper
          size={"xs"}
          radius={"xl"}
          active={active}
          onStepClick={setActive}
          breakpoint={"sm"}
          styles={{
            steps: {
              "@media (max-width: 51.27em)": {
                display: "flex",
                flexDirection: "row",
                overflowX: "hidden",
                overflow: "scroll",
                height: "50px",
              },
            },
          }}
        >
          <Stepper.Step
            label="Progetto"
            sx={sxStepperStepStyles({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            icon={iconStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            color={colorStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
          >
            <Grid grow>
              <Grid.Col>
                <Title order={4}>Progetto</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Nome"
                  placeholder="Nome Progetto"
                  {...form.getInputProps("progNome")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={
                    allRegion?.map((o) => {
                      return {
                        value: o.id!,
                        label: o.name!,
                      };
                    })!
                  }
                  withAsterisk
                  label="Regione"
                  placeholder="Regione"
                  {...form.getInputProps("progRegione")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={!selectProvince || hasSubmitted || isAdmin}
                  data={selectProvince ?? []}
                  withAsterisk
                  label="Provincia"
                  placeholder="Provincia"
                  onChange={(e) => {
                    form.setFieldValue("progProvincia", e!);
                  }}
                  value={form.values.progProvincia}
                />
              </Grid.Col>

              <>
                <Grid.Col xs={12} sm={6}>
                  <Select
                    data={selectComune ?? []}
                    disabled={!selectComune || hasSubmitted || isAdmin}
                    withAsterisk
                    label="Comune"
                    placeholder="Comune"
                    onChange={(e) => {
                      form.setFieldValue("progComune", e!);
                    }}
                    value={form.values.progComune}
                  />
                </Grid.Col>
              </>

              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={
                    allTourism?.map((o) => {
                      return {
                        value: o.name!,
                        label: o.name!,
                      };
                    })!
                  }
                  withAsterisk
                  label="Area Tematica"
                  placeholder="Scegliere il campo riconducibile alla natura dell'operazione"
                  rightSection={
                    <Tooltip
                      label="Scegliere il campo riconducibile alla natura dell'operazione"
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("progAreaTematica")}
                />
              </Grid.Col>
              <Grid.Col span={5}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  clearable
                  withAsterisk
                  label="Tipologia intervento"
                  placeholder="Seleziona tipologia interevento"
                  creatable
                  data={
                    tipoProgetti?.map((e) => {
                      return {
                        value: e?.id!,
                        label: e?.name!,
                      };
                    })!
                  }
                  {...form.getInputProps("tipoIntervento")}
                  style={{ maxWidth: 830 }}
                />
              </Grid.Col>
              <Tooltip
                label="Scegliere una o più tipologie di intervento riconducibili alla natura del progetto"
                position="bottom"
                withArrow
                width={220}
                multiline
                events={{ hover: true, focus: true, touch: true }}
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{ display: "block", opacity: 0.5, marginTop: 42 }}
                  />
                </div>
              </Tooltip>
              <Grid.Col span={12}>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  maxLength={500}
                  minLength={200}
                  maxRows={4}
                  label="Descrizione"
                  placeholder="Sintetica descrizione progetto, con focus su interventi energetici e digitali"
                  rightSection={
                    <Tooltip
                      label="Sintetica descrizione progetto, con focus su interventi energetici e digitali"
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("descrizione")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  label="Progettista"
                  placeholder="Nome progettista"
                  {...form.getInputProps("progettista")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  label="Impresa esecutrice dei lavori"
                  placeholder="Nome Impresa esecutrice dei lavori"
                  {...form.getInputProps("impresaEsecutriceDeilavori")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Fonti e impieghi"
            sx={sxStepperStepStyles({
              activeNumber: 1,
              condition: costiConditions,
            })}
            icon={iconStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
            color={colorStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
          >
            <Grid>
              <Grid.Col>
                <Title order={4}>Fonti e impieghi</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  hideControls
                  withAsterisk
                  label="Totale Investimento"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiTotale")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  hideControls
                  label="di cui acquisto immobili/terreni"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiImmobili")}
                />
              </Grid.Col>
              {isAdmin && tenPercWarning ? (
                <>
                  <Grid.Col span={6} offset={2}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="yellow"
                    >
                      {tenPercMessage}
                    </Alert>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col sm={8}></Grid.Col>
              )}
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="di cui capex"
                  withAsterisk
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  rightSection={
                    <Tooltip
                      multiline
                      label="CAPEX: Spese per la realizzazione di investimenti in attività immobilizzate di natura operativa (es. costo realizzazione opere, spese tecniche)"
                      position="top-end"
                      withArrow
                      width={220}
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("costiCapex")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="di cui capex per transizione energetica"
                  withAsterisk
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  rightSection={
                    <Tooltip
                      multiline
                      label={`Le spese "capex per transizione energetica" devono essere pari almeno al 50% dell'intervento del Fondo`}
                      position="top-end"
                      withArrow
                      width={220}
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("costiTotaleCapexTransEnerg")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>
              {isAdmin && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled
                      icon={"%"}
                      maxLength={3}
                      label="% Componente transizione energetica su intervento FTT"
                      value={isNaN(percTransEnerg!) ? 0 : percTransEnerg}
                    />
                  </Grid.Col>
                  {percTransEnergWarning ? (
                    <Grid.Col sm={6} xs={12}>
                      <Alert
                        icon={<IconAlertCircle size="1rem" />}
                        title="Attenzione!"
                        color="yellow"
                      >
                        {percTransEnergMessage}
                      </Alert>
                    </Grid.Col>
                  ) : (
                    <Grid.Col sm={8}></Grid.Col>
                  )}
                </>
               )} 

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="di cui capex per transizione digitale"
                  withAsterisk
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  rightSection={
                    <Tooltip
                      multiline
                      label={`Le spese "capex per transizione digitale" devono essere pari almeno al 20% dell'intervento del Fondo`}
                      position="top-end"
                      withArrow
                      width={220}
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("costiTotaleCapexTransDigital")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              {isAdmin && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled
                      icon={"%"}
                      maxLength={3}
                      label="% Componente digitalizzazione su intervento FTT"
                      value={isNaN(percDigital!) ? 0 : percDigital}
                    />
                  </Grid.Col>
                  {percDigitalWarning ? (
                    <Grid.Col sm={6} xs={12}>
                      <Alert
                        icon={<IconAlertCircle size="1rem" />}
                        title="Attenzione!"
                        color="yellow"
                      >
                        {percDigitalMessage}
                      </Alert>
                    </Grid.Col>
                  ) : (
                    <Grid.Col sm={8}></Grid.Col>
                  )}
                </>
               )} 

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="di cui circolante"
                  withAsterisk
                  hideControls
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiTotaleCircolante")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col mt="md">
                <Title order={4}>Fonti di copertura</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled
                  hideControls
                  label="Fonti di copertura"
                  value={isNaN(sommaFontiCopertura) ? 0 : sommaFontiCopertura}
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Entità intervento fondo"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiIintervento")}
                />
              </Grid.Col>
              {isAdmin && valoreSupWarning ? (
                <>
                  <Grid.Col sm={6} xs={12}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="yellow"
                    >
                      {valoreMessage}
                    </Alert>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col sm={8}></Grid.Col>
              )}
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Cofinanziamento con debito bancario"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiDdebito")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  hideControls
                  label="Cofinanziamento con equity"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiEquity")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  hideControls
                  withAsterisk
                  label="Cofinanziamento risorse pubbliche"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiPubblico")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Nome della misura di cofinanziamento"
                  placeholder="Nome della misura di cofinanziamento"
                  {...form.getInputProps("costiNomeCofinanziamento")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled
                  icon={"%"}
                  maxLength={3}
                  label="% intervento fondo su totale investimento"
                  value={isNaN(percInterv!) ? 0 : percInterv}
                />
              </Grid.Col>
              {isAdmin && percIntervWarning ? (
                <>
                  <Grid.Col sm={6} xs={12}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="yellow"
                    >
                      {percIntervMessage}
                    </Alert>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col sm={8}></Grid.Col>
              )}

              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    { value: "debito", label: "debito" },
                    { value: "equity", label: "equity" },
                    { value: "quasi equity", label: "quasi equity" },
                  ]}
                  withAsterisk
                  label="Forma tecnica di intervento"
                  placeholder="Forma tecnica di intervento"
                  {...form.getInputProps("costiTecnicaIntervento")}
                />
              </Grid.Col>
              <Grid.Col sm={8}></Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Tempistiche"
            sx={sxStepperStepStyles({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            icon={iconStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            color={colorStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
          >
            <Grid grow align="center">
              <Grid.Col>
                <Title order={4}>Tempistiche</Title>
              </Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={
                    designLevel?.map((e) => {
                      return {
                        value: e.name!,
                        label: e.name!,
                      };
                    })!
                  }
                  withAsterisk
                  label="Livello di progettazione"
                  placeholder="Livello di progettazione"
                  {...form.getInputProps("tempiLivello")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    { value: "chiuso", label: "chiuso" },
                    { value: "in corso", label: "in corso" },
                    { value: "da avviare", label: "da avviare" },
                  ]}
                  withAsterisk
                  label="Stadio iter urbanistico - procedurale"
                  placeholder="Stadio iter urbanistico - procedurale"
                  {...form.getInputProps("tempiStadioIter")}
                />
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Tipologia di autorizzazione necessaria"
                  placeholder="Inserire la tipologia di autorizzazione urbanistica (PdC, SCIA, ecc.) necessaria per avviare i lavori"
                  withAsterisk
                  rightSection={
                    <Tooltip
                      label="Inserire la tipologia di autorizzazione urbanistica (PdC, SCIA, ecc.) necessaria per avviare i lavori"
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("tempiTipoAut")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={hasSubmitted || isAdmin}
                  step={1}
                  type="number"
                  withAsterisk
                  icon="gg"
                  label="Tempistiche attese per ottenimento permessi (giorni)"
                  placeholder="Inserire i tempi attesi per arrivare ad autorizzazione urbanistica (0 se titoli abilitativi già ritirati)"
                  rightSection={
                    <Tooltip
                      label="Inserire i tempi attesi per arrivare ad autorizzazione urbanistica (0 se titoli abilitativi già ritirati)"
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("tempiPermessi")}
                />
              </Grid.Col>
              <Grid.Col xs={9} sm={4}>
                <DatePicker
                  withAsterisk
                  disabled={hasSubmitted || isAdmin}
                  locale="it"
                  label="Data inizio lavori prevista"
                  placeholder="Seleziona data inizio"
                  clearable={false}
                  // {...form.getInputProps("tempiInizioLavori")}
                  value={
                    form.values.tempiInizioLavori
                      ? new Date(form.values.tempiInizioLavori)
                      : new Date()
                  }
                  onChange={(e) => {
                    form.setFieldValue("tempiInizioLavori", e?.toISOString());
                    calcoloData(e, form?.values?.tempiDurataLavori!);
                  }}
                />
              </Grid.Col>
              <Grid.Col
                sx={{
                  marginTop: 22,
                  "@media (max-width: 51.27em)": {
                    marginTop: 0,
                  },
                }}
                span={2}
              >
                <Popover
                  width={200}
                  position="bottom"
                  withArrow
                  shadow="md"
                  opened={opened}
                >
                  <Popover.Target>
                    <Checkbox
                      checked={form.values.previsioneInizioLavori}
                      disabled={hasSubmitted || isAdmin}
                      onMouseEnter={open}
                      onMouseLeave={close}
                      label="Previsionale"
                      {...form.getInputProps("previsioneInizioLavori")}
                    />
                  </Popover.Target>
                  <Popover.Dropdown sx={{ pointerEvents: "none" }}>
                    <Text size="sm">
                      Selezionare questa casella nel caso in cui non si ha una
                      data certa dell'inizio dei lavori. Bisogna inserire
                      comunque una data
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  withAsterisk
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={
                    hasSubmitted || isAdmin || !form.values.tempiInizioLavori
                  }
                  hideControls
                  label="Durata lavori prevista (mesi)"
                  placeholder="Durata lavori prevista (mesi)"
                  value={form.values.tempiDurataLavori}
                  onChange={(e) => {
                    form.setFieldValue("tempiDurataLavori", e);
                    console.log(
                      "🚀 ~ file: SurveyTUR.tsx:1656 ~ SurveyTUR ~ form.values.tempiDurataLavori:",
                      form.values.tempiDurataLavori
                    );
                    calcoloData(form.values.tempiInizioLavori, e ?? 0);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Group>
                  <Text
                    mt={24}
                    align={"center"}
                    {...form.getInputProps("tempiFineLavori")}
                  >
                    Mese fine:{" "}
                    {form.values.tempiDurataLavori === undefined ||
                      form.values.tempiDurataLavori === 0
                      ? "Inserisci data inizio e durata lavori"
                      : form.values.tempiFineLavori
                        ? new Date(
                          form.values.tempiFineLavori!
                        ).toLocaleDateString("it-IT", {
                          month: "long",
                          year: "numeric",
                        })
                        : "Inserisci data inizio e durata lavori"}
                  </Text>
                  {dateExceeded && (
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="red"
                    >
                      La data non puo' superare il 31/08/2026
                    </Alert>
                  )}
                </Group>
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  withAsterisk
                  disabled={hasSubmitted || isAdmin}
                  maxLength={500}
                  minLength={200}
                  label="Descrizione iter procedurale"
                  placeholder="Inserisci descrizione"
                  {...form.getInputProps("tempiDescrizione")}
                />
              </Grid.Col>
              <Grid.Col>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    { value: "corpo", label: "A corpo" },
                    {
                      value: "funzionali",
                      label: "A successivi lotti funzionali",
                    },
                  ]}
                  label="Modalità di esecuzione dell'opera"
                  placeholder="Inserisci descrizione"
                  {...form.getInputProps("modalitaEsecuzione")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Valutazione d'Impatto"
            sx={
              hasSubmitted
                ? sxStepperStepStyles({
                  activeNumber: 3,
                  condition: true,
                })
                : undefined
            }
            icon={
              hasSubmitted
                ? iconStepperStep({
                  activeNumber: 3,
                  condition: true,
                })
                : undefined
            }
            color={
              hasSubmitted
                ? colorStepperStep({
                  activeNumber: 3,
                  condition: true,
                })
                : undefined
            }
            completedIcon={
              hasSubmitted
                ? completedIconStepperStep({
                  activeNumber: 3,
                  condition: true,
                })
                : undefined
            }
          >
            <Grid grow>
              <Grid.Col>
                <Title order={4}>
                  Intervento architettonico - Qualità dell'aria
                </Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Emissioni CO2 - fase di costruzione</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600}>
                    Il progetto prevede la ristrutturazione (ad eccezione di
                    demolizione e ricostruzione)?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.ristrutturazione === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.ristrutturazione &&
                        form.setFieldValue("turSurveyDto.mqSLP", undefined);
                    }}
                    {...form.getInputProps("turSurveyDto.ristrutturazione")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.turSurveyDto?.ristrutturazione === "true" && (
                <>
                  <Grid.Col span={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      label="Quanti mq (SLP) sono interessati da ristrutturazione?"
                      placeholder="0"
                      icon="mq"
                      {...form.getInputProps("turSurveyDto.mqSLP")}
                      rightSection={
                        <Tooltip
                          label="Si intende superficie lorda di pavimento (orizzontale) anche in caso di interventi su facciata e involucro esterno"
                          position="top-end"
                          withArrow
                          width={220}
                          multiline
                          events={{ hover: true, focus: true, touch: true }}
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={6}></Grid.Col>
                </>
              )}
              <Grid.Col span={11}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  clearable
                  label="Che tipologia di intervento si sta prevedendo? "
                  placeholder="Seleziona tipologia interevento"
                  data={[
                    { value: "1", label: " Ristrutturazione interna" },
                    { value: "2", label: " Interventi sull'involucro esterno" },
                    { value: "3", label: " Sostituzione impianti" },
                    { value: "4", label: " Restauro" },
                    {
                      value: "5",
                      label: "  Altro (specificare in campo note)",
                    },
                  ]}
                  {...form.getInputProps(
                    "turSurveyDto.tipoInterventoPrevisione"
                  )}
                  style={{ maxWidth: 830 }}
                />
              </Grid.Col>
              <Tooltip
                label="É possibile selezionare più di una risposta multipla. "
                position="bottom"
                withArrow
                width={220}
                multiline
                events={{ hover: true, focus: true, touch: true }}
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{ display: "block", opacity: 0.5, marginTop: 42 }}
                  />
                </div>
              </Tooltip>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps(
                    "turSurveyDto.noteEmissioneFaseCostruzione"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Emissioni CO2 - fase di esercizio</Title>
              </Grid.Col>
              <Grid.Col span={7}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Qual è il consumo complessivo in MWh/anno atteso per l'edificio?"
                  placeholder="0"
                  rightSection={
                    <Tooltip
                      label="Se il dato non è disponibile, compilare solo la domanda successiva"
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("turSurveyDto.consumoMWh")}
                />
              </Grid.Col>
              <Grid.Col span={6}></Grid.Col>

              <Grid.Col span={12}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  label="Qual è la classe energetica di partenza (allo stato precedente i lavori)?"
                  placeholder="Classe energetica di partenza"
                  data={[
                    {
                      value: "A+ (<15 kWh/mq annui)",
                      label: "- A+ (<15 kWh/mq annui)",
                    },
                    {
                      value: "A (15÷30 kWh/mq annui)",
                      label: "- A (15÷30 kWh/mq annui)",
                    },
                    {
                      value: "B (31÷50 kWh/mq annui)",
                      label: "- B (31÷50 kWh/mq annui)",
                    },
                    {
                      value: "C (51÷70 kWh/mq annui)",
                      label: "- C (51÷70 kWh/mq annui)",
                    },
                    {
                      value: "D (71÷90 kWh/mq annui)",
                      label: "- D (71÷90 kWh/mq annui)",
                    },
                    {
                      value: "E (91÷120 kWh/mq annui)",
                      label: "- E (91÷120 kWh/mq annui)",
                    },
                    {
                      value: "F (121÷160 kWh/mq annui)",
                      label: "- F (121÷160 kWh/mq annui)",
                    },
                    {
                      value: "G (> 160 kWh/mq annui)",
                      label: "- G (> 160 kWh/mq annui)",
                    },
                    {
                      value: "NA perchè nuova costruzione",
                      label: "- n.a. perchè nuova costruzione",
                    },
                  ]}
                  {...form.getInputProps(
                    "turSurveyDto.classeEnergeticaPartenza"
                  )}
                  style={{ maxWidth: 830 }}
                />
              </Grid.Col>
              <Grid.Col>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  label="Quale classe energetica raggiunge il progetto?"
                  placeholder="Classe energetica da raggiungere"
                  data={[
                    { value: "A+", label: "- A+ (<15 kWh/mq annui)" },
                    { value: "A", label: "- A (15÷30 kWh/mq annui)" },
                    { value: "B", label: "- B (31÷50 kWh/mq annui)" },
                    { value: "C", label: "- C (51÷70 kWh/mq annui)" },
                    { value: "D", label: "- D (71÷90 kWh/mq annui)" },
                    { value: "E", label: "- E (91÷120 kWh/mq annui)" },
                    { value: "F", label: "- F (121÷160 kWh/mq annui)" },
                    { value: "G", label: "- G (> 160 kWh/mq annui)" },
                    { value: "NA", label: "- n.a. perchè nuova costruzione" },
                  ]}
                  style={{ maxWidth: 830 }}
                  {...form.getInputProps(
                    "turSurveyDto.classeEnergeticaRaggiunta"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps(
                    "turSurveyDto.noteEmissioneFaseEsercizio"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>
                  Intervento architettonico - Energia pulita
                </Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Fonti di energia rinnovabile</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  disabled={hasSubmitted || isAdmin}
                  label="Quanti MWh/anno di energia verranno prodotti da fonti rinnovabili?"
                  placeholder="0"
                  {...form.getInputProps("turSurveyDto.energiaRinnovabile")}
                />
              </Grid.Col>
              <Grid.Col xs={0} sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Se il dato non è disponibile, inserire la potenza degli impianti FER previsti nel progetto con indicazione della tipologia "
                  placeholder="Potenza impianti FER"
                  {...form.getInputProps("turSurveyDto.potenzaFER")}
                />
              </Grid.Col>
              <Grid.Col xs={0} sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  disabled={hasSubmitted || isAdmin}
                  label="Se il dato non è disponibile, inserire la quantità degli impianti FER previsti nel progetto con indicazione della tipologia "
                  placeholder="Quantità impianti FER"
                  {...form.getInputProps("turSurveyDto.quantitaFER")}
                />
              </Grid.Col>
              <Grid.Col span={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps(
                    "turSurveyDto.noteFontiEnergiaRinnovabile"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Consumo di suolo</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede di recuperare la superficie coperta
                    esistente?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.superficieCoperta === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.superficieCoperta &&
                        form.setFieldValue(
                          "turSurveyDto.superficieCopertaRecuperata",
                          undefined
                        );
                    }}
                    {...form.getInputProps("turSurveyDto.superficieCoperta")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values?.turSurveyDto?.superficieCoperta === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Qual è la superficie coperta esistente che viene recuperata?"
                      rightSection={
                        <Tooltip
                          label="Valida in questo caso anche demolizione e ricostruzione se si mantiene la stessa superficie coperta (Sc)"
                          position="top-end"
                          events={{ hover: true, focus: true, touch: true }}
                          withArrow
                          width={220}
                          multiline
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps(
                        "turSurveyDto.superficieCopertaRecuperata"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={0} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps("turSurveyDto.noteConsumoSuolo")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Aree verdi</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede di aggiungere superficie verde rispetto
                    allo stato di fatto?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.superficieVerde === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.superficieVerde &&
                        form.setFieldValue(
                          "turSurveyDto.superficieVerdeAggiunta",
                          undefined
                        );
                    }}
                    {...form.getInputProps("turSurveyDto.superficieVerde")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.turSurveyDto?.superficieVerde === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quanta superficie verde viene aggiunta rispetto allo stato di fatto?"
                      rightSection={
                        <Tooltip
                          label="Valida in questo caso anche demolizione e ricostruzione se si mantiene la stessa superficie coperta (Sc)"
                          position="top-end"
                          withArrow
                          width={220}
                          multiline
                          events={{ hover: true, focus: true, touch: true }}
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps(
                        "turSurveyDto.superficieVerdeAggiunta"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={0} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps("turSurveyDto.noteAreeVerdi")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Alberi</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede nuove piantumazioni?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.piantumazioni === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.piantumazioni &&
                        form.setFieldValue(
                          "turSurveyDto.numeroPiantumazioni",
                          undefined
                        );
                    }}
                    {...form.getInputProps("turSurveyDto.piantumazioni")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.turSurveyDto?.piantumazioni === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      hideControls
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quante nuove piante vengono piantumate?"
                      {...form.getInputProps(
                        "turSurveyDto.numeroPiantumazioni"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={0} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps("turSurveyDto.noteAlberi")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Occupazione</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Nuovi posti di lavoro</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  placeholder="0"
                  label="Qual è l'incremento di forza lavoro previsto per l'avvio del cantiere?"
                  rightSection={
                    <Tooltip
                      label="Indicare il potenziale numero di occupati generati durante la fase di cantiere."
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("turSurveyDto.forzaLavoro")}
                />
              </Grid.Col>
              <Grid.Col xs={0} sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps(
                    "turSurveyDto.noteNuoviPostiLavoroIntervento"
                  )}
                />
              </Grid.Col>
            </Grid>
            <Grid grow>
              <Grid.Col>
                <Title order={4}>Turismo - attività economica</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Spesa dei turisti</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Si prevede un incremento dei turisti attesi grazie alla
                    realizzazione del progetto?
                  </Text>

                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.prevIncrementoTuristi ===
                      "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.prevIncrementoTuristi &&
                        form.setFieldValue(
                          "turSurveyDto.incrementoTuristi",
                          undefined
                        );
                    }}
                    {...form.getInputProps(
                      "turSurveyDto.prevIncrementoTuristi"
                    )}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                {form.values.turSurveyDto?.prevIncrementoTuristi === "true" && (
                  <NumberInput
                    onKeyPress={(event) => {
                      if (!/^\d*[.,]?\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    decimalSeparator=","
                    precision={2}
                    hideControls
                    disabled={hasSubmitted || isAdmin}
                    label={
                      "Qual è l'incremento di turisti attesi grazie all'avvio del progetto?"
                    }
                    placeholder="0"
                    {...form.getInputProps("turSurveyDto.incrementoTuristi")}
                  />
                )}
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps("turSurveyDto.noteSpesaTuristi")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Turismo - occupazione</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Nuovi posti di lavoro</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label={
                    "Qual è l'incremento di forza lavoro previsto per l'avvio del progetto?"
                  }
                  placeholder="0"
                  rightSection={
                    <Tooltip
                      label="Indicare il potenziale numero di occupati generati durante la fase di esercizio."
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps(
                    "turSurveyDto.prevIncrementoForzaLavoro"
                  )}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  label="Note"
                  {...form.getInputProps(
                    "turSurveyDto.noteNuoviPostiLavoroTurismo"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Turismo - paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Tutela del paesaggio</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede investimento sul contesto paesaggistico?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.contestoPaesaggistico ===
                      "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.contestoPaesaggistico &&
                        form.setFieldValue(
                          "turSurveyDto.tipoContestoPaesaggistico",
                          ""
                        );
                    }}
                    {...form.getInputProps(
                      "turSurveyDto.contestoPaesaggistico"
                    )}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              <Grid.Col span={12}>
                {form.values.turSurveyDto?.contestoPaesaggistico === "true" && (
                  <TextInput
                    disabled={hasSubmitted || isAdmin}
                    label={"Che tipo di interventi sono previsti?"}
                    placeholder="Tipi di interventi"
                    {...form.getInputProps(
                      "turSurveyDto.tipoContestoPaesaggistico"
                    )}
                  />
                )}
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("turSurveyDto.noteTutelaPaesaggio")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Turismo - mobilità</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>
                  Promozione tempi di trasporto alternativi
                </Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede la promozione della mobilità
                    sostenibile?
                  </Text>

                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.turSurveyDto?.mobilitaSostenibile === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.turSurveyDto?.mobilitaSostenibile &&
                        form.setFieldValue(
                          "turSurveyDto.tipoMobilitaSostenibile",
                          []
                        );
                    }}
                    {...form.getInputProps("turSurveyDto.mobilitaSostenibile")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.turSurveyDto?.mobilitaSostenibile === "true" && (
                <>
                  <Grid.Col span={6}>
                    <MultiSelect
                      disabled={hasSubmitted || isAdmin}
                      clearable
                      label="In che modo?"
                      placeholder="Seleziona tipologia interevento"
                      data={[
                        {
                          value:
                            "Convenzioni con TPL società private di bici o monopattini",
                          label:
                            "Convenzioni con TPL società private di bici o monopattini",
                        },
                        {
                          value: "Dotazione di depositi bici",
                          label: "Dotazione di depositi bici",
                        },
                        {
                          value: "Nuove fermate nei pressi dell'iniziativa",
                          label: "Nuove fermate nei pressi dell'iniziativa",
                        },
                        {
                          value: "Colonnine di ricarica per veicoli elettrici",
                          label: "Colonnine di ricarica per veicoli elettrici",
                        },
                        {
                          value: "Interventi architettonici specifici",
                          label: "Interventi architettonici specifici",
                        },
                        {
                          value: "Tratti di piste ciclabili",
                          label: "Tratti di piste ciclabili",
                        },
                      ]}
                      {...form.getInputProps(
                        "turSurveyDto.tipoMobilitaSostenibile"
                      )}
                    />
                  </Grid.Col>
                  <Tooltip
                    label="É possibile selezionare più di una risposta multipla. "
                    position="bottom"
                    withArrow
                    width={220}
                    multiline
                    events={{ hover: true, focus: true, touch: true }}
                  >
                    <div>
                      <IconAlertCircle
                        size="1rem"
                        style={{
                          display: "block",
                          opacity: 0.5,
                          marginTop: 42,
                        }}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Se hai informazioni quantitative, riporta i valori numerici relativi agli interventi che promuoveranno una mobilità sostenibile?"
                  placeholder="0"
                  rightSection={
                    <Tooltip
                      label="Inserire i valori numerici per ogni risposta multipla selezionata."
                      position="top-end"
                      withArrow
                      width={220}
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps(
                    "turSurveyDto.numInterventiMobilitaSostenibile"
                  )}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "turSurveyDto.notePromozioneMezziTrasportoAlternativi"
                  )}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Allegati"
            sx={sxStepperStepStyles({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            icon={iconStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            color={colorStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
          >
            <SurveyAllegati
              orgid={isAdmin ? locationOrgId : localStorOrgId!}
              calltype="TUR"
              disabled={hasSubmitted || isAdmin}
              onclick={() => handleAllegati()}
              statusTUR={statusTUR}
            />
          </Stepper.Step>

          {isAdmin
            ? null
            : !hasSubmitted && (
              <Stepper.Step label="Invia Candidatura">
                <LoadingOverlay visible={visible} overlayBlur={2} />
              </Stepper.Step>
            )}
          {isAdmin
            ? null
            : !hasSubmitted && (
              <Stepper.Completed>
                <SurveyCompleted />
              </Stepper.Completed>
            )}
        </Stepper>
        <BottomNote show={active === 5} />
        <Group position="center" mt={32}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Indietro
            </Button>
          )}
          {active === 4 && isAdmin ? (
            <Button onClick={() => navigate("/admin-panel")}>
              Torna alla lista
            </Button>
          ) : active === 4 && hasSubmitted ? (
            <Button onClick={() => navigate("/calls")}>Torna ai fondi</Button>
          ) : (
            active !== 5 && <Button onClick={nextStep}>Avanti</Button>
          )}
          {active === 5 && (
            <Button
              disabled={
                hasSubmitted ||
                isAdmin ||
                progettoConditions ||
                costiConditions ||
                tempisticheConditions ||
                dateExceeded ||
                !checkAllegati
              }
              type="submit"
              className="btn-primary"
            >
              {t("components.forms.register-organization.submit", "Submit")}
            </Button>
          )}
        </Group>
      </form>
    </Paper>
  );
}

const BottomNote = ({ show }: { show: boolean }) => {
  if (!show) return null;
  return (
    <Group position="center" mt={22}>
      <Text
        sx={(theme) => ({
          fontSize: theme.fontSizes.md,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xs,
          },
        })}
      >
        Tutti i campi con <span style={{ color: "red" }}>*</span> devono essere
        compilati per poter inviare la candidatura
      </Text>
    </Group>
  );
};

export default SurveyTUR;
