import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { Route, Routes } from "react-router-dom";
import { preload } from "swr";

import { AuthProvider, RequireAuth } from "./auth/AuthContext";
import LoginCallback from "./components/LoginCallback";
import LogoutCallback from "./components/LogoutCallback";
import "./custom.css";
import CallList from "./pages/calls/callList";

import { I18nextProvider } from "react-i18next";
import {
  getApiCallGetCallList,
  getApiOrganizationGetOrganization,
  getApiOrganizationGetOrganizations,
  getGetApiCallGetCallListKey,
  getGetApiOrganizationGetOrganizationKey,
  getGetApiOrganizationGetOrganizationsKey,
} from "./api/endpoints";
import ConnectOrganization from "./components/ConnectedToYourOrganization/ConnectOrganization";
import InviteCollaborate from "./components/InvitesToCollaborate/InviteCollaborate";
import Layout from "./components/Layout";
import RegisterOrganization from "./components/forms/RegisterOrganization";
import PageNotFound from "./pages/PageNotFound";
import WelcomePage from "./pages/WelcomePage";
import { AccountPage } from "./pages/account/AccountPage";
import { AdminPanel } from "./pages/admin/AdminPanel";
import { Monitoraggio } from "./pages/monitoraggio/Monitoraggio";
import SurveyCompleted from "./pages/survey/SurveyCompleted";
import SurveyPUI from "./pages/survey/SurveyPUI";
import SurveyTUR from "./pages/survey/SurveyTUR";
import SurveyIstruttoriaPUI from "./pages/surveyIstruttoria/SurveyIstruttoriaPUI";
import SurveyIstruttoriaTUR from "./pages/surveyIstruttoria/SurveyIstruttoriaTUR";
import { YourOrganizationPage } from "./pages/yourOrganization/YourOrganizationPage";
import i18n from "./translations";

export default function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Segoe UI, Arial, sans-serif",
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ModalsProvider>
          <NotificationsProvider>
            <AuthProvider>
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/" element={<Layout />}>
                  <Route index element={<WelcomePage />} />
                  <Route
                    path="calls"
                    element={
                      <RequireAuth>
                        <CallList />
                      </RequireAuth>
                    }
                    loader={() => {
                      preload(
                        getGetApiCallGetCallListKey(),
                        getApiCallGetCallList
                      );
                      preload(
                        getGetApiOrganizationGetOrganizationsKey(),
                        getApiOrganizationGetOrganizations
                      );
                    }}
                  />
                  <Route path="login-callback" element={<LoginCallback />} />
                  <Route
                    path="flow-organization"
                    element={
                      <RequireAuth>
                        <ConnectOrganization />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="register-org"
                    element={
                      <RequireAuth>
                        <RegisterOrganization />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="invite-collaborate"
                    element={
                      <RequireAuth>
                        <InviteCollaborate />
                      </RequireAuth>
                    }
                  />
                  <Route path="logout-callback" element={<LogoutCallback />} />
                  <Route
                    path="account"
                    element={
                      <RequireAuth>
                        <AccountPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="your-organization/:organizationId"
                    element={
                      <RequireAuth>
                        <YourOrganizationPage />
                      </RequireAuth>
                    }
                    loader={() => {
                      preload(
                        getGetApiOrganizationGetOrganizationKey(),
                        getApiOrganizationGetOrganization
                      );
                    }}
                  />
                  <Route
                    path="candidaturaCompletata"
                    element={
                      <RequireAuth>
                        <SurveyCompleted />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="surveyIstruttoriaCompletata"
                    element={
                      <RequireAuth>
                        <SurveyCompleted isSurvey />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="monitoraggio"
                    element={
                      <RequireAuth>
                        <Monitoraggio />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-panel"
                    element={
                      <RequireAuth>
                        <AdminPanel />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-organization/:organizationId"
                    element={
                      <RequireAuth>
                        <YourOrganizationPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-organization/survey-tur/:organizationId"
                    element={
                      <RequireAuth>
                        <SurveyTUR />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-organization/PUI_istruttoria/:organizationId"
                    element={
                      <RequireAuth>
                        <SurveyIstruttoriaPUI />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-organization/TUR_istruttoria/:organizationId"
                    element={
                      <RequireAuth>
                        <SurveyIstruttoriaTUR />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admin-organization/survey-pui/:organizationId"
                    element={
                      <RequireAuth>
                        <SurveyPUI />
                      </RequireAuth>
                    }
                  />
                  <Route path="survey">
                    <Route
                      path="TUR_candidatura"
                      element={
                        <RequireAuth>
                          <SurveyTUR />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="TUR_istruttoria/:organizationId"
                      element={
                        <RequireAuth>
                          <SurveyIstruttoriaTUR />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="PUI_istruttoria/:organizationId"
                      element={
                        <RequireAuth>
                          <SurveyIstruttoriaPUI />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="PUI_candidatura"
                      element={
                        <RequireAuth>
                          <SurveyPUI />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Route>
              </Routes>
            </AuthProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </I18nextProvider>
    </MantineProvider>
  );
}
