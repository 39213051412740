import {
  Anchor,
  Avatar,
  Center,
  Divider,
  Drawer,
  Flex,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { OrganizationDto, UserDto } from "../api/model";
import { ReactComponent as LogoFinit } from "../assets/svg/logo_finint.svg";

interface DrawerProps {
  opened: boolean;
  onClose: () => void;
  user: UserDto | undefined;
  org: OrganizationDto | undefined;
  isAdmin: boolean | undefined;
  isAuthenticated: boolean;
  classes: string;
}

export const DrawerTopBar = ({
  opened,
  onClose,
  user,
  org,
  isAdmin,
  classes,
}: DrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      size="100%"
      padding="lg"
      className={classes}
      position="right"
      zIndex={1000000}
      styles={{
        closeButton: { "& svg": { width: 25, height: 25, color: "black" } },
      }}
      title={user?.firstName + " " + user?.lastName}
    >
      <Stack mt="lg" spacing="xl">
        <Group>
          <Avatar color="cyan" radius="xl" size={40}>
            {user?.firstName &&
              user?.lastName &&
              (user?.firstName![0] + user?.lastName![0]).toUpperCase()}
          </Avatar>
          <Flex direction={"column"}>
            <Text size={14} fw={400} c={"black"}>
              {user?.email}
            </Text>
            {isAdmin ? (
              <></>
            ) : (
              <Text size={14} fw={600} c={"black"}>
                {org?.name}
              </Text>
            )}
          </Flex>
        </Group>
        <Divider ml={-5} mr={-5} c={"#E0E0E0"} />
        <Anchor variant="text" href={"/account"}>
          <Text size={17} fw={400} mt={-2}>
            {t("components.account")}
          </Text>
        </Anchor>
        {isAdmin || !user?.organizations?.length ? (
          <></>
        ) : (
          <Anchor color={"dark"} href={`/your-organization/${org?.id}`}>
            <Text size={17} fw={400} mt={-2}>
              {t("components.your-organization")}
            </Text>
          </Anchor>
        )}
        {isAdmin && (
          <Anchor variant="text" href={"/admin-panel"}>
            <Text size={17} fw={400} mt={-2}>
              Pannello Admin
            </Text>
          </Anchor>
        )}
        <Anchor
          color="red"
          href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/logout?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}`}
        >
          <Text size={17} fw={400} mt={-2} c={"red"}>
            Esci
          </Text>
        </Anchor>
      </Stack>
      <Divider ml={-5} mr={-5} mt={15} c={"#E0E0E0"} />
      <Center mt={"2rem"}>
        <Flex direction={"column"}>
          <LogoFinit height={"100%"} width={175} style={{ margin: 10 }} />
          <Image
            src={"./finint-investments_logo.jpg"}
            alt="logo"
            maw={175}
            m={10}
            mt={"2rem"}
          />
          <Image
            src={"./LOGO SINLOC.jpg"}
            alt="logo"
            maw={175}
            m={10}
            mt={"2rem"}
          />
        </Flex>
      </Center>
    </Drawer>
  );
};
