import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useRefreshToken } from "../auth/AuthContext";
import { preload } from "swr";
import {
  getGetApiCallGetCallListKey,
  getGetApiOrganizationGetOrganizationKey,
  getGetApiOrganizationGetOrganizationsKey,
  getGetApiUserGetUserStatusKey,
} from "../api/endpoints";
import { useTranslation } from "react-i18next";
import { Center, Flex, Loader, Stack, Title } from "@mantine/core";

const LoginCallback = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  //get the authorization_code
  const code = searchParams.get("code");
  const redirectUri = `https://${process.env.REACT_APP_HOSTNAME}/login-callback`; // "https://starimpact-dev.openimpact.it/login-callback";//get this value from config!!!
  const [refreshToken, setRefreshToken] = useRefreshToken(); //use "refreshToken" because it is declared but not used, otherwise handle it differently
  const { setAccessToken, isAuthenticated, user, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("exchange token in progress");
    const exchangeToken = async () => {
      await fetch("/api/Jwt/token-exchange", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          code,
          redirectUri,
        }), // body data type must match "Content-Type" header
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("access_token res: ", res);
          setAccessToken(res.token);
          setRefreshToken(res.refresh_token, 30);
          const fetcher = (url: string) =>
            fetch(url, {
              headers: {
                Authorization: "Bearer " + res.token,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("preloaded", res);
                return res;
              })
              .catch((e) => {
                console.log("Exception fetcing ", url, e);
              });

          console.log("preloading");
          preload(getGetApiCallGetCallListKey(), fetcher);
          preload(getGetApiOrganizationGetOrganizationsKey(), fetcher);
          preload(getGetApiOrganizationGetOrganizationKey(), fetcher);
          preload(getGetApiUserGetUserStatusKey(), fetcher);

          return res;
        });
    };

    exchangeToken();
  }, []);

  if (isAuthenticated) {
    if (isAdmin) {
      navigate("/admin-panel");
    } else if (user?.organizations?.length) {
      navigate("/calls");
    } else {
      navigate("/flow-organization");
    }
  }

  return (
    <Center style={{ height: "100%" }}>
      <Flex direction={"column"}>
        <Stack style={{ alignItems: "center" }}>
          <Loader />
          <Title order={1}>{t("components-login-callback-loggedin")}</Title>
        </Stack>
      </Flex>
    </Center>
  );
};
export default LoginCallback;
