import { Grid, Group, Divider, Text, Button, Loader } from "@mantine/core";
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { IconPaperclip, IconX } from "@tabler/icons";
import { uniqueId } from "lodash";
import { useState } from "react";
import { PostApiFileUploadUploadFileBody } from "../../api/model";
import {
  getApiFileUploadRemoveAttachement,
  getGetApiFileUploadGetAttachmentTypesKey,
  getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey,
  postApiFileUploadUploadFile,
} from "../../api/endpoints";
import { mutate } from "swr";
import { showNotification } from "@mantine/notifications";

export const RowAllegatoDNSH = ({
  id,
  title,
  type,
  asterisk,
  download,
  orgid,
  calltype,
  downloadId,
  fileName,
  disabled = false,
  onclick,
}: {
  id?: string;
  title: string;
  type?: string;
  asterisk?: boolean;
  download?: string;
  orgid: string;
  calltype: string;
  fileName?: string;
  downloadId?: string;
  disabled?: boolean;
  onclick: () => void;
}) => {
  const pdfFormat = PDF_MIME_TYPE;
  const form = useForm({
    initialValues: {
      valueAttach: downloadId != null,
      downloadId: downloadId,
      fileName: fileName,
    },
    validate: {
      valueAttach: (value) => (!value ? "L'allegato è obbligatorio" : null),
    },
  });
  const [loader, setLoader] = useState(false);

  function downloadFile(url: string, filename: string) {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  }

  function uploadFile(file: FileWithPath, callId: string, callType: string) {
    setLoader(true);
    if (!disabled) {
      const fileUploadReq: PostApiFileUploadUploadFileBody = {
        Nome: file.name,
        Descrizione: file.name,
        TipoFileId: id ?? "",
        File: file,
        OrgId: orgid,
        CallType: callType,
      };
      postApiFileUploadUploadFile(fileUploadReq)
        .then((ret) => {
          form.setValues({
            valueAttach: true,
            downloadId: ret.id,
            fileName: ret.fileName,
          });
          mutate(
            getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey({
              orgId: orgid,
              callType: callType,
            })
          ).then(() => setLoader(false));
          onclick();
        })
        .catch((e) => {
          console.log("🚀 ~ file: SurveyAllegati.tsx:114 ~ uploadFile ~ e:", e);
          setLoader(false);
          showNotification({
            title: type === "ZIP" ? "Caricamento zip" : "Caricamento file",
            message:
              type === "ZIP"
                ? "C'è un problema nell'upload dello zip o le dimensioni sono superiori al limite consentito (10MB)"
                : "C'è un problema nell'upload del file",
            icon: <IconX size="1.1rem" />,
            color: "red",
          });
        });
    }
  }

  function removeFileUploaded(downloadId: string) {
    setLoader(true);
    if (!disabled) {
      getApiFileUploadRemoveAttachement({ downloadId: downloadId }).then(() => {
        mutate(
          getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey({
            orgId: orgid,
            callType: calltype,
          })
        ).then(() => setLoader(false));
        onclick();
        return form.setValues({ valueAttach: false });
      });
    }
  }

  return (
    <form>
      <Grid grow align={"center"} mt={5}>
        <Grid.Col xs={12} sm={7}>
          <Group>
            <Text
              w={"70%"}
              sx={{
                fontSize: 14,
                width: "70%",
                "@media (max-width: 51.27em)": {
                  width: "100%",
                },
              }}
            >
              {title}
              {asterisk && <span style={{ color: "red" }}>*</span>}
              <Text fw={400} size={14} color="dimmed">
                {type === "ZIP" ? `(${type}) - 10MB max` : "(PDF/A)"}
              </Text>
            </Text>
            {download && (
              <Button
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
                ml={-18}
                color="dark"
                variant="subtle"
                onClick={() => downloadFile(download, download)}
              >
                Scarica modulo
              </Button>
            )}
          </Group>
        </Grid.Col>
        {loader ? (
          <Grid.Col span={4} ml={54}>
            <Loader size={24} ml={-34} />
          </Grid.Col>
        ) : (
          <>
            <Grid.Col span={2}>
              {form.values.valueAttach ? (
                <Button
                  variant="outline"
                  w={94}
                  h={34}
                  c={"#1E9CFF"}
                  radius={40}
                  style={{ fontSize: 12, cursor: "default" }}
                  mt={12}
                  mb={11}
                >
                  CARICATO
                </Button>
              ) : disabled ? (
                <Button
                  variant="outline"
                  w={132}
                  h={34}
                  c={"#b1b1b1"}
                  radius={40}
                  style={{ fontSize: 12, cursor: "default" }}
                  mt={12}
                  mb={11}
                  disabled
                >
                  NON CARICATO
                </Button>
              ) : (
                <Dropzone
                  onDrop={(files) => uploadFile(files[0], orgid, calltype)}
                  onReject={(files) =>
                    console.log(
                      "🚀 ~ file: SurveyAllegati.tsx:204 ~ rejected files:",
                      files
                    )
                  }
                  sx={{ border: 0, width: 52 }}
                  accept={type === "ZIP" ? ["application/zip"] : pdfFormat}
                >
                  <IconPaperclip size={20} color={"#929292"} />
                </Dropzone>
              )}
            </Grid.Col>
            <Grid.Col span={3} mr={-30}>
              {form.values.valueAttach && (
                <>
                  <Button
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                    color="dark"
                    variant="subtle"
                    onClick={() =>
                      downloadFile(
                        `https://${process.env.REACT_APP_BACKEND_HOSTNAME}/api/FileUpload/GetFile?downloadId=${form.values.downloadId}`,
                        form.values.fileName!
                      )
                    }
                  >
                    Scarica
                  </Button>
                  {!disabled && (
                    <Button
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        textDecoration: "underline",
                      }}
                      color="dark"
                      variant="subtle"
                      onClick={() =>
                        removeFileUploaded(form.values.downloadId!)
                      }
                    >
                      Rimuovi
                    </Button>
                  )}
                </>
              )}
            </Grid.Col>
          </>
        )}
      </Grid>
      <Divider size="xs" mt={8} mb={8} />
    </form>
  );
};
