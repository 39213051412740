import { Paper, Title, Center, Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

function SurveyCompleted({ isSurvey }: { isSurvey?: boolean }) {
  const navigate = useNavigate();
  return (
    <Paper p={24} radius={12} bg={"#FAFAFA"}>
      <Center>
        <Flex direction={"column"} align={"center"} justify={"center"}>
          <Title fw={600} size={40}>
            {isSurvey ? "Survey" : "Candidatura"} inviata!
          </Title>
          <Button
            style={{ marginTop: 32 }}
            className="btn-primary"
            onClick={() => navigate("/calls")}
          >
            Torna ai fondi
          </Button>
        </Flex>
      </Center>
    </Paper>
  );
}

export default SurveyCompleted;
