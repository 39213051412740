import {
  Button,
  CSSObject,
  Group,
  LoadingOverlay,
  Paper,
  Stepper,
  Text,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { mutate } from "swr";
import {
  getApiFileUploadFilesUploadIstGresbPuiValidation,
  getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey,
  getGetApiSJSurveyGetAnswersBySubmissionIdPuiKey,
  postApiPuiUpdatePuiCompilazioneStatus,
  postApiSJSurveyCreateSurveyAnswers,
  useGetApiSJSurveyGetAnswersBySubmissionIdPui,
  useGetApiSJSurveyGetSurveySubmssionbySurveyId,
} from "../../api/endpoints";
import Loading from "../../components/Loading";
import { useGetSetSurvey } from "../../hooks/useGetSetSurvey";
import SurveyCompleted from "../survey/SurveyCompleted";
import AllegatiDNSH from "./AllegatiDNSH";

StylesManager.applyTheme("defaultV2");

function SurveyIstruttoriaPUI() {
  const location = useLocation();
  const adminOrgPuiCompilazioneId = location?.state?.puiCompilazione?.id;
  const monitoraggio = location?.state?.monitoraggio;
  const isAdmin = location?.state?.isAdmin;
  const { organizationId } = useParams();
  const [localStorOrgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [visible, setVisible] = useState(false);
  const [checkAllegati, setCheckAllegati] = useState<boolean>();
  const { t } = useTranslation();
  const [orgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );
  const isReadOnly = location?.state?.isReadOnly;

  const {
    istruttoriaJson,
    gresbJson,
    puiCompilazioneId,
    surveyIdGresb,
    surveyIdIstruttoria,
  } = useGetSetSurvey({ callType: "PUI" });

  const { data: istruttSub, isLoading: istruttSubLoad } =
    useGetApiSJSurveyGetSurveySubmssionbySurveyId({
      surveyId: surveyIdIstruttoria,
      monitoraggio: monitoraggio,
    });

  const { data: gresbSub, isLoading: gresbSubLoad } =
    useGetApiSJSurveyGetSurveySubmssionbySurveyId({
      surveyId: surveyIdGresb,
      monitoraggio: monitoraggio,
    });

  const { data: answersIstruttoria } =
    useGetApiSJSurveyGetAnswersBySubmissionIdPui(
      {
        submissionId: istruttSub?.id,
        compilazioneId: isAdmin ? adminOrgPuiCompilazioneId : puiCompilazioneId,
        monitoraggio: monitoraggio,
      },
      { swr: { shouldRetryOnError: false } }
    );

  const { data: answersGresb } = useGetApiSJSurveyGetAnswersBySubmissionIdPui(
    {
      submissionId: gresbSub?.id,
      compilazioneId: isAdmin ? adminOrgPuiCompilazioneId : puiCompilazioneId,
      monitoraggio: monitoraggio,
    },
    { swr: { shouldRetryOnError: false } }
  );

  const surveyIstrutt = useMemo(() => {
    return new Model(istruttoriaJson);
  }, [istruttoriaJson]);
  const surveyGresb = useMemo(() => {
    return new Model(gresbJson);
  }, [gresbJson]);

  surveyIstrutt.mode = isReadOnly || isAdmin ? "display" : "";
  surveyGresb.mode = isReadOnly || isAdmin ? "display" : "";

  const getAnswer = useCallback(() => {
    mutate(
      getGetApiSJSurveyGetAnswersBySubmissionIdPuiKey({
        submissionId: istruttSub?.id,
        compilazioneId: isAdmin ? adminOrgPuiCompilazioneId : puiCompilazioneId,
        monitoraggio: monitoraggio,
      })
    );

    mutate(
      getGetApiSJSurveyGetAnswersBySubmissionIdPuiKey({
        submissionId: gresbSub?.id,
        compilazioneId: isAdmin ? adminOrgPuiCompilazioneId : puiCompilazioneId,
        monitoraggio: monitoraggio,
      })
    );
  }, [
    adminOrgPuiCompilazioneId,
    gresbSub?.id,
    isAdmin,
    istruttSub?.id,
    puiCompilazioneId,
  ]);

  const saveSurveyDataGresb = useCallback((survey: any) => {
    console.log("🚀 ~ file: SurveyIstruttoriaTUR.tsx:145 ~ saveSurveyDataGresb ~ survey:", survey)
    postApiSJSurveyCreateSurveyAnswers({
      surveySubmissionID: gresbSub?.id,
      json: JSON.stringify(survey.data),
      candidaturaId: puiCompilazioneId,
      compilazioneType: 1,
      monitoraggio: monitoraggio,
    });
  }, [surveyGresb])

  const saveSurveyDataIst = useCallback((survey: any) => {
    console.log("🚀 ~ file: SurveyIstruttoriaTUR.tsx:156 ~ saveSurveyDataIst ~ survey:", survey)
    postApiSJSurveyCreateSurveyAnswers({
      surveySubmissionID: istruttSub?.id,
      json: JSON.stringify(survey.data),
      candidaturaId: puiCompilazioneId,
      compilazioneType: 1,
      monitoraggio: monitoraggio,
    });
  }, [surveyIstrutt])

  useEffect(() => {
    getApiFileUploadFilesUploadIstGresbPuiValidation({
      orgId: localStorOrgId,
      callType: "PUI",
    }).then((res) => {
      setCheckAllegati(res);
    });
  }, [checkAllegati]);

  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
    getAnswer();
    if (active === 0 && !isAdmin) {
      surveyIstrutt.data = answersIstruttoria;
    }
    if (active === 1 && !isAdmin) {
      surveyGresb.data = answersGresb;
    }
    if (active === 2 && !isAdmin) {
      handleAllegati();
    }
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
    getAnswer();
    if (active === 0 && !isAdmin) {
      // saveSurveyDataIst(surveyIstrutt);
      surveyIstrutt.data = answersIstruttoria;
    }
    if (active === 1 && !isAdmin) {
      // saveSurveyDataGresb(surveyGresb);
      surveyGresb.data = answersGresb;
    }
    if (active === 2 && !isAdmin) {
      handleAllegati();
    }
  };

  const completedIconStepperStep = ({
    activeNumber,
  }: {
    activeNumber: number;
  }) =>
    activeNumber === active ? (
      <Text color="#228BE6">{activeNumber + 1}</Text>
    ) : (
      <Text color="#F1F3F5">{activeNumber + 1}</Text>
    );

  function handleSubmit(): void {
    const status = monitoraggio
      ? "Monitoraggio Sottomesso"
      : "Istruttoria Sottomessa";
    postApiPuiUpdatePuiCompilazioneStatus({
      puiId: puiCompilazioneId,
      status: status,
    })
      .then((res) => {
        showNotification({
          title: !!res.error
            ? t("notifications.submission-error")
            : t("notifications.submission"),
          message: !res
            ? "Controlla che l'allegato obbligatorio sia stato caricato"
            : "Salvataggio avvenuto con successo",
          icon: !res ? <IconX size="1.1rem" /> : <IconCheck size="1.1rem" />,
          color: !res ? "red" : "teal",
        });
        navigate("/surveyIstruttoriaCompletata");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SurveyPUI.tsx:428 ~ handleSubmit ~ error:",
          error
        );
        showNotification({
          title: "Errore",
          message: "Controlla che l'allegato obbligatorio sia stato caricato",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      });
    setVisible(!visible);
  }

  const openModal = () =>
    openConfirmModal({
      title: "Conferma",
      children: (
        <Text size="sm">
          Sicuro di inviare la survey? Non sarà più possibile modificarla.
        </Text>
      ),
      labels: { confirm: "Invia", cancel: "Indietro" },
      onCancel: () => {
        console.log("Cancel");
      },
      onConfirm: () => {
        console.log("Confirmed");
        setVisible(!visible);
        handleSubmit();
      },
    });

  function handleAllegati() {
    getApiFileUploadFilesUploadIstGresbPuiValidation({
      orgId: localStorOrgId,
      callType: "PUI",
    }).then((res) => {
      setCheckAllegati(res);
    });
    mutate(
      getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey({
        orgId: localStorOrgId,
        callType: "PUI",
      })
    );
  }

  surveyIstrutt.onAfterRenderQuestion.add(
    (_: any, options: { htmlElement: { parentElement: HTMLDivElement } }) => {
      const parent: HTMLDivElement = options.htmlElement.parentElement;
      (parent.style as any)["min-width"] = "0px";
    }
  );

  surveyGresb.onAfterRenderQuestion.add(
    (_: any, options: { htmlElement: { parentElement: HTMLDivElement } }) => {
      const parent: HTMLDivElement = options.htmlElement.parentElement;
      (parent.style as any)["min-width"] = "0px";
    }
  );

  const sxStepperStepStyles = ({
    activeNumber,
  }: {
    activeNumber: number;
  }): CSSObject => ({
    "& .mantine-Stepper-stepIcon": {
      borderColor: "#228BE6",
      backgroundColor: activeNumber === active ? "" : "#228BE6",
      color: activeNumber === active ? "#228BE6" : "#F1F3F5",
    },
  });

  useEffect(() => {
    getApiFileUploadFilesUploadIstGresbPuiValidation({
      orgId: orgId,
      callType: "PUI",
    }).then((res) => {
      setCheckAllegati(res);
    });
  }, [checkAllegati]);

  useEffect(() => {
    if (active === 0) {
      surveyIstrutt.onValueChanged.add(saveSurveyDataIst);
    } else if (active === 1) {
      surveyGresb.onValueChanged.add(saveSurveyDataGresb);
    }
  }, [surveyIstrutt, surveyGresb, active, saveSurveyDataIst, saveSurveyDataGresb]);

  useEffect(() => {
    if (answersGresb || answersIstruttoria) {
      surveyIstrutt.focusFirstQuestionAutomatic = false;
      surveyGresb.focusFirstQuestionAutomatic = false;
      surveyIstrutt.data = answersIstruttoria;
      surveyGresb.data = answersGresb;
      handleAllegati();
    }
  }, [answersGresb, answersIstruttoria, surveyIstrutt, surveyGresb]);

  return (surveyIstrutt === undefined && surveyGresb === undefined) ||
    (istruttoriaJson === undefined && gresbJson === undefined) ||
    (istruttSubLoad && gresbSubLoad) ? (
    <Loading />
  ) : (
    <Paper shadow="xs" radius="md" p="sm">
      <Stepper
        active={active}
        onStepClick={(e) => {
          setActive(e);
          getAnswer();
        }}
        size={"xs"}
        radius={"xl"}
        breakpoint={"sm"}
        styles={{
          steps: {
            "@media (max-width: 51.27em)": {
              display: "flex",
              flexDirection: "row",
              overflowX: "hidden",
              overflow: "scroll",
              height: "50px",
            },
          },
        }}
      >
        <Stepper.Step
          label="Istruttoria"
          sx={sxStepperStepStyles({
            activeNumber: 0,
          })}
          completedIcon={completedIconStepperStep({
            activeNumber: 0,
          })}
        >
          <Survey model={surveyIstrutt} />
        </Stepper.Step>
        <Stepper.Step
          label="Gresb"
          sx={sxStepperStepStyles({
            activeNumber: 1,
          })}
          completedIcon={completedIconStepperStep({
            activeNumber: 1,
          })}
        >
          <Survey model={surveyGresb} />
        </Stepper.Step>
        {!monitoraggio && (
          <Stepper.Step
            label="DNSH"
            sx={sxStepperStepStyles({
              activeNumber: 2,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 2,
            })}
          >
            <AllegatiDNSH
              calltype="PUI"
              orgId={isAdmin ? organizationId! : localStorOrgId ?? ""}
              disabled={isReadOnly || isAdmin}
              onclick={() => handleAllegati()}
            />
          </Stepper.Step>
        )}
        {isReadOnly || isAdmin ? null : (
          <Stepper.Step
            label="Invia survey"
            sx={sxStepperStepStyles({
              activeNumber: !monitoraggio ? 3 : 2,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: !monitoraggio ? 3 : 2,
            })}
          >
            {!monitoraggio && (
              <Text align="center" mt={30} mb={30}>
                Controlla di aver caricato l'allegato di "Dichiarazione
                attestante il rispetto dei vincoli" per poter inviare la survey
              </Text>
            )}
            <LoadingOverlay visible={visible} overlayBlur={2} />
          </Stepper.Step>
        )}
        <Stepper.Completed>
          <SurveyCompleted isSurvey />
        </Stepper.Completed>
      </Stepper>
      <Group position="center" mt="xl">
        {active === 0 ? (
          <></>
        ) : (
          <Button variant="default" onClick={prevStep}>
            Indietro
          </Button>
        )}
        {active === 3 || (active === 2 && monitoraggio) ? (
          <Button
            disabled={!checkAllegati}
            type="submit"
            className="btn-primary"
            onClick={() => openModal()}
          >
            {t("components.forms.register-organization.submit", "Submit")}
          </Button>
        ) : active === 2 && isReadOnly ? (
          <Button onClick={() => navigate("/calls")}>Torna ai fondi</Button>
        ) : active === 2 && isAdmin ? (
          <Button onClick={() => navigate("/admin-panel")}>
            Torna alla lista
          </Button>
        ) : (
          <Button onClick={nextStep}>Avanti</Button>
        )}
      </Group>
    </Paper>
  );
}

export default SurveyIstruttoriaPUI;
