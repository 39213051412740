import { Paper, Text, Title } from "@mantine/core";
import { mutate } from "swr";
import {
  getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey,
  useGetApiFileUploadGetIstruttoriaGresbAttachmentTypes,
} from "../../api/endpoints";
import Loading from "../../components/Loading";
import { RowAllegatoDNSH } from "./RowAllegatiDNSH";

function AllegatiDNSH({
  orgId,
  calltype,
  disabled = false,
  onclick,
}: {
  orgId: string;
  calltype: string;
  disabled?: boolean;
  onclick: () => void;
}) {
  const {
    data: dataAttachments,
    isLoading: isLoadingAttachments,
    error: errorAttachments,
  } = useGetApiFileUploadGetIstruttoriaGresbAttachmentTypes({
    orgId: orgId,
    callType: calltype,
  });

  if (isLoadingAttachments) {
    return <Loading />;
  }

  if (!Array.isArray(dataAttachments) || errorAttachments) {
    mutate(
      getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey({
        orgId: orgId,
        callType: calltype,
      })
    );
    return <Loading />;
  }

  const dataAttachmentSort = dataAttachments.sort(function (a, b) {
    return a.attachedTypeName!.localeCompare(b.attachedTypeName!, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });

  return (
    <Paper p={24} radius={12} style={{ overflowX: "hidden" }}>
      <Title
        sx={(theme) => ({
          fontSize: 34,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xl,
          },
        })}
      >
        DNSH
      </Title>
      <Text fw={400} size={14} mt={10} mb={30} color="dimmed">
        Il Dispositivo per la ripresa e la resilienza (Reg. UE 2021-241)
        stabilisce che tutte le misure dei PNRR debbano soddisfare il principio
        di “non arrecare danno significativo agli obiettivi ambientali”,
        ovverosia il DNSH. Per risultare elegibili alle risorse del Fondo
        bisogna dunque operare in conformità ai requisiti DNSH, che verranno
        puntualmente controllati nelle fasi successive della procedura.
      </Text>
      <Text fw={600} size={14} mt={40}>
        Condizione obbligatoria per la compatibilità DNSH
      </Text>
      {dataAttachmentSort
        .filter((x) => x.mandatoryInput)
        .map((att) => (
          <RowAllegatoDNSH
            key={att.id}
            id={att.id}
            title={att.attachedTypeName!}
            orgid={orgId}
            downloadId={att.downloadId}
            onclick={onclick}
            calltype={calltype}
            asterisk={att.mandatoryInput}
            fileName={att.fileName}
            disabled={disabled}
          />
        ))}
      {dataAttachments
        .filter((x) => !x.mandatoryInput)
        .map((att) => (
          <RowAllegatoDNSH
            key={att.id}
            id={att.id}
            title={att.attachedTypeName!}
            orgid={orgId}
            downloadId={att.downloadId}
            onclick={onclick}
            calltype={calltype}
            asterisk={false}
            fileName={att.fileName}
            disabled={disabled}
            type={att.attachedType}
          />
        ))}
    </Paper>
  );
}

export default AllegatiDNSH;
