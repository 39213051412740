import {
  Center,
  Divider,
  Grid,
  Loader,
  NumberInput,
  Select,
  SelectItem,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
  getApiTypologyGetComunefromProv,
  getApiTypologyGetprovfromRegion,
  getGetApiOrganizationGetOrganizationByIdKey,
  getGetApiTypologyGetAllFormaGiuridicaKey,
  postApiOrganizationUpdateOrganization,
  useGetApiTypologyGetAllFormaGiuridica,
} from "../../api/endpoints";
import {
  CreateOrgDtoBody,
  OrganizationDto,
  TypologyResponseDto,
} from "../../api/model";
import { EditButtonInput } from "../../components/EditButtonInput";
import Loading from "../../components/Loading";
import { useMediaQuery } from "@mantine/hooks";
import toIsoString from "../../utils/FixDateIsoStringFormat";

export const TabRegistry = ({
  org,
  reg,
  isAdmin,
}: {
  org?: OrganizationDto;
  reg?: TypologyResponseDto[];
  isAdmin?: boolean;
}) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("only screen and (max-width: 35rem)");
  const [enableName, setEnableName] = useState(true);
  const [enableAddress, setEnableAddress] = useState(true);
  const [enableFormaGiuridica, setEnableFormaGiuridica] = useState(true);
  const [enableNaturaSoggettoProponente, setEnableNaturaSoggettoProponente] =
    useState(true);
  const [enableDataCostituzione, setEnableDataCostistuzione] = useState(true);
  const [enableCodiceAteco, setEnableCodiceAteco] = useState(true);
  const [enablePartecipazionePubblica, setEnablePartecipazionePubblica] =
    useState(true);
  const [
    enablePercentualePartecipazionePubblica,
    setEnablePercentualePartecipazionePubblica,
  ] = useState(true);
  const [enablePec, setEnablePec] = useState(true);
  const [enableWebsite, setEnableWebsite] = useState(true);
  const [enableDescrizioneImpresa, setEnableDescrizioneImpresa] =
    useState(true);
  const [enableContattoRiferimento, setEnableContattoRiferimento] =
    useState(true);
  const [enableLuogoNascita, setEnableLuogoNascita] = useState(true);
  const [enableDataNascita, setEnableDataNascita] = useState(true);
  const [enableCodiceFiscale, setEnableCodiceFiscale] = useState(true);
  const [enableRuolo, setEnableRuolo] = useState(true);
  const [enableEmail, setEnableEmail] = useState(true);
  const [enableTelefono, setEnableTelefono] = useState(true);
  const [selectProvince, setSelectProvince] = useState<
    SelectItem[] | undefined
  >();
  const [selectComune, setSelectComune] = useState<SelectItem[] | undefined>();
  const {
    data: formaGiuridica,
    isLoading: isLoadingFormaGiuridica,
    error: errorFormaGiuridica,
  } = useGetApiTypologyGetAllFormaGiuridica();

  const form = useForm<CreateOrgDtoBody>({
    initialValues: {
      name: org?.name,
      pIva: org?.pIva,
      id: org?.id,
      address: org?.address,
      comune: org?.comune,
      numCivico: org?.numCivico,
      province: org?.province,
      region: org?.region,
      altro: org?.altro,
      codiceAteco: org?.codiceAteco,
      codiceFiscale: org?.codiceFiscale,
      contactReference: org?.contactReference,
      data_di_costituzione: org?.data_di_costituzione,
      data_di_nascita: org?.data_di_nascita,
      description: org?.description,
      email: org?.email,
      formaGiuridica: org?.formaGiuridica,
      luogo_di_nascita: org?.luogo_di_nascita,
      natura_del_soggetto_proponente: org?.natura_del_soggetto_proponente,
      pec: org?.pec,
      percentParticipation: org?.percentParticipation,
      privacy: org?.privacy,
      publicParticipation: org?.publicParticipation,
      role: org?.role,
      telephone: org?.telephone,
      website: org?.website,
    },
    validate: {
      name: (value) => (!value ? "Campo obbligatorio" : null),
      formaGiuridica: (value) => (!value ? "Campo obbligatorio" : null),
      codiceAteco: (value) => (!value ? "Campo obbligatorio" : null),
      contactReference: (value) => (!value ? "Campo obbligatorio" : null),
      description: (value) => (!value ? "Campo obbligatorio" : null),
      email: (value) => (!value ? "Campo obbligatorio" : null),
      telephone: (value) => (!value ? "Campo obbligatorio" : null),
    },
    transformValues: (value) => ({
      ...value,
      comune: value?.comune?.toString(),
      province: value?.province?.toString(),
      address: value?.address?.toString(),
      numCivico: value?.numCivico?.toString(),
      region: value?.region?.toString(),
    }),
  });

  const updateOrg = (values: CreateOrgDtoBody) => {
    console.log(values);
    form.setValues({
      name: values.name ?? org?.name,
      address: values.address ?? org?.address,
      comune: values.comune ?? org?.comune,
      region: values.region ?? org?.region,
      numCivico: values.numCivico ?? org?.numCivico,
      province: values.province ?? org?.province,
      data_di_costituzione:
        values.data_di_costituzione ?? org?.data_di_costituzione,
      altro: values.altro ?? org?.altro,
      codiceAteco: values.codiceAteco ?? org?.codiceAteco,
      codiceFiscale: values.codiceFiscale ?? org?.codiceFiscale,
      contactReference: values.contactReference ?? org?.contactReference,
      data_di_nascita: values.data_di_nascita ?? org?.data_di_nascita,
      description: values.description ?? org?.description,
      email: values.email ?? org?.email,
      formaGiuridica: values.formaGiuridica ?? org?.formaGiuridica,
      luogo_di_nascita: values.luogo_di_nascita ?? org?.luogo_di_nascita,
      natura_del_soggetto_proponente:
        values.natura_del_soggetto_proponente ??
        org?.natura_del_soggetto_proponente,
      pec: values.pec ?? org?.pec,
      percentParticipation:
        values.percentParticipation ?? org?.percentParticipation,
      role: values.role ?? org?.role,
      telephone: values.telephone ?? org?.telephone,
      website: values.website ?? org?.website,
      publicParticipation:
        values.publicParticipation ?? org?.publicParticipation,
    });
    postApiOrganizationUpdateOrganization(form.values)
      .then((res) => {
        showNotification({
          title: t("components.pages.your-organization.success"),
          message: t("components.pages.your-organization.values-update"),
          icon: <IconCheck size="1.1rem" />,
          color: "teal",
        });
        mutate(getGetApiOrganizationGetOrganizationByIdKey({ orgId: org?.id }));
        form.resetDirty({
          name: values.name,
          codiceAteco: values.codiceAteco,
          address: values.address,
          codiceFiscale: values.codiceFiscale,
          altro: values.altro,
          comune: values.comune,
          contactReference: values.contactReference,
          data_di_costituzione: values.data_di_costituzione,
          data_di_nascita: values.data_di_nascita,
          description: values.description,
          email: values.email,
          formaGiuridica: values.formaGiuridica,
          luogo_di_nascita: values.luogo_di_nascita,
          natura_del_soggetto_proponente: values.natura_del_soggetto_proponente,
          numCivico: values.numCivico,
          pec: values.pec,
          percentParticipation: values.percentParticipation,
          province: values.province,
          publicParticipation: values.publicParticipation,
          region: values.region,
          role: values.role,
          telephone: values.telephone,
          website: values.website,
        });
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: RegisterOrganization.tsx:46 ~ postApiOrganizationCreateOrganization ~ error:",
          error
        );
        showNotification({
          title: t("components.pages.your-organization.error"),
          message: t("components.pages.your-organization.error-values-fields"),
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      });
    setEnableName(true);
    setEnableAddress(true);
    setEnableCodiceAteco(true);
    setEnableCodiceFiscale(true);
    setEnableContattoRiferimento(true);
    setEnableDataCostistuzione(true);
    setEnableDataNascita(true);
    setEnableDescrizioneImpresa(true);
    setEnableEmail(true);
    setEnableFormaGiuridica(true);
    setEnableLuogoNascita(true);
    setEnableNaturaSoggettoProponente(true);
    setEnablePartecipazionePubblica(true);
    setEnablePercentualePartecipazionePubblica(true);
    setEnablePec(true);
    setEnableRuolo(true);
    setEnableTelefono(true);
    setEnableWebsite(true);
  };

  const handleRegion = useCallback(
    (region: string, resetFields: boolean) => {
      if (resetFields) {
        form.setFieldValue("comune", "");
        form.setFieldValue("province", "");
      }
      getApiTypologyGetprovfromRegion({
        regId: region,
      }).then((result) => {
        // form.setValues({ province: "" });
        setSelectProvince(
          result?.map((provincia) => {
            return {
              value: provincia?.id!.toString(),
              label: provincia?.name!.toString(),
            };
          })!
        );
      });
      getApiTypologyGetComunefromProv({
        provId: form.values.province,
      }).then((result) => {
        // form.setValues({ comune: "" });
        setSelectComune(
          result?.map((comune) => {
            return {
              value: comune?.id!.toString(),
              label: comune?.name!.toString(),
            };
          })!
        );
      });
    },
    [form.values]
  );

  const handleProv = useCallback(
    (prov: string, resetFields: boolean) => {
      if (resetFields) {
        form.setFieldValue("comune", "");
      }
      getApiTypologyGetComunefromProv({
        provId: prov,
      }).then((result) => {
        // form.setValues({ comune: "" });
        setSelectComune(
          result?.map((comune) => {
            return {
              value: comune?.id!.toString(),
              label: comune?.name!.toString(),
            };
          })!
        );
      });
    },
    [form.values]
  );

  useEffect(() => {
    handleRegion(form.values.region!, false);
  }, [form.values.region]);

  useEffect(() => {
    handleProv(form.values.province!, false);
  }, [form.values.province]);

  if (isLoadingFormaGiuridica) {
    return <Loading />;
  }

  if (!Array.isArray(formaGiuridica) || errorFormaGiuridica) {
    mutate(getGetApiTypologyGetAllFormaGiuridicaKey());
    return <Loading />;
  }

  return !org ? (
    <Center style={{ height: "100%" }}>
      <Loader />
    </Center>
  ) : (
    <>
      <form onSubmit={form.onSubmit((values) => updateOrg(values))}>
        <Grid align="center" grow>
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              label="Denominazione/Ragione Sociale"
              variant={"unstyled"}
              disabled={enableName}
              minLength={3}
              defaultValue={org.name}
              {...form.getInputProps("name")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableName(!enableName)}
                enabled={enableName}
                disabled={!form.isDirty("name")}
                setCancel={() => {
                  form.setValues({ name: org.name });
                  setEnableName(!enableName);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={8}>
            <TextInput
              label={t("components.pages.your-organization.vat-number")}
              variant="unstyled"
              disabled
              defaultValue={org.pIva}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          <Grid.Col span={matches ? 8 : 10}>
            <Select
              data={
                formaGiuridica?.map((e) => {
                  return {
                    value: e.id?.toString()!,
                    label: e.name!,
                  };
                })!
              }
              disabled={enableFormaGiuridica}
              label="Forma Giuridica"
              placeholder="Forma Giuridica"
              variant="unstyled"
              defaultValue={org.formaGiuridica}
              {...form.getInputProps("formaGiuridica")}
              sx={{
                "@media (max-width: 51.27em)": {
                  width: !isAdmin ? "110%" : "100%",
                },
              }}
            />
            {form.values.formaGiuridica === "8" && (
              <TextInput
                disabled={enableFormaGiuridica}
                variant="unstyled"
                placeholder="Nome forma giuridica"
                defaultValue={org.altro}
                {...form.getInputProps("altro")}
              />
            )}
            <Divider
              mb="xl"
              size="xs"
              sx={{
                "@media (max-width: 51.27em)": {
                  width: !isAdmin ? "105%" : "100%",
                },
              }}
            />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() =>
                  setEnableFormaGiuridica(!enableFormaGiuridica)
                }
                enabled={enableFormaGiuridica}
                disabled={!form.isDirty("formaGiuridica")}
                setCancel={() => {
                  form.setValues({ formaGiuridica: org.formaGiuridica });
                  setEnableFormaGiuridica(!enableFormaGiuridica);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              disabled={enableCodiceAteco}
              variant="unstyled"
              label="Codice ATECO"
              placeholder="[es. 55.14]"
              defaultValue={org.codiceAteco}
              {...form.getInputProps("codiceAteco")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableCodiceAteco(!enableCodiceAteco)}
                enabled={enableCodiceAteco}
                disabled={!form.isDirty("codiceAteco")}
                setCancel={() => {
                  form.setValues({ codiceAteco: org.codiceAteco });
                  setEnableCodiceAteco(!enableCodiceAteco);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              disabled={enableNaturaSoggettoProponente}
              variant="unstyled"
              label="Natura del soggetto proponente"
              placeholder="Natura del soggetto proponente"
              defaultValue={org.natura_del_soggetto_proponente}
              {...form.getInputProps("natura_del_soggetto_proponente")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() =>
                  setEnableNaturaSoggettoProponente(
                    !enableNaturaSoggettoProponente
                  )
                }
                enabled={enableNaturaSoggettoProponente}
                disabled={!form.isDirty("natura_del_soggetto_proponente")}
                setCancel={() => {
                  form.setValues({
                    natura_del_soggetto_proponente:
                      org.natura_del_soggetto_proponente,
                  });
                  setEnableNaturaSoggettoProponente(
                    !enableNaturaSoggettoProponente
                  );
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <DatePicker
              disabled={enableDataCostituzione}
              variant="unstyled"
              locale="it"
              label="Data di costituzione"
              placeholder="Data di costituzione"
              value={
                form.values.data_di_costituzione
                  ? new Date(form.values.data_di_costituzione)
                  : undefined
              }
              onChange={(e) =>
                form.setFieldValue(
                  "data_di_costituzione",
                  e ? toIsoString(e) : undefined
                )
              }
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() =>
                  setEnableDataCostistuzione(!enableDataCostituzione)
                }
                enabled={enableDataCostituzione}
                disabled={!form.isDirty("data_di_costituzione")}
                setCancel={() => {
                  form.setValues({
                    data_di_costituzione: org.data_di_costituzione,
                  });
                  setEnableDataCostistuzione(!enableDataCostituzione);
                }}
              />
            </Grid.Col>
          )}

          <Grid.Col span={matches ? 8 : 10}>
            <Switch
              defaultChecked={form.values.publicParticipation}
              disabled={enablePartecipazionePubblica}
              fw={"600"}
              label="Partecipazione pubblica"
              labelPosition="left"
              size="md"
              onLabel="SI"
              offLabel="NO"
              mr={-20}
              mt={20}
              mb={30}
              {...form.getInputProps("publicParticipation")}
            />
          </Grid.Col>
          {form.values.publicParticipation && (
            <Grid.Col span={matches ? 8 : 10}>
              <NumberInput
                disabled={enablePercentualePartecipazionePubblica}
                variant="unstyled"
                label="% partecipazione"
                placeholder="%"
                defaultValue={org.percentParticipation}
                {...form.getInputProps("percentParticipation")}
              />
              <Divider mb="xl" size="xs" />
            </Grid.Col>
          )}
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => {
                  setEnablePartecipazionePubblica(
                    !enablePartecipazionePubblica
                  );
                  setEnablePercentualePartecipazionePubblica(
                    !enablePercentualePartecipazionePubblica
                  );
                }}
                enabled={
                  enablePartecipazionePubblica &&
                  enablePercentualePartecipazionePubblica
                }
                disabled={
                  !form.isDirty("publicParticipation") &&
                  !form.isDirty("percentParticipation")
                }
                setCancel={() => {
                  form.setValues({
                    publicParticipation: org.publicParticipation,
                    percentParticipation: org.percentParticipation,
                  });
                  setEnablePartecipazionePubblica(
                    !enablePartecipazionePubblica
                  );
                  setEnablePercentualePartecipazionePubblica(
                    !enablePercentualePartecipazionePubblica
                  );
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enablePec}
              label="PEC"
              placeholder="PEC"
              variant="unstyled"
              defaultValue={org.pec}
              {...form.getInputProps("pec")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnablePec(!enablePec)}
                enabled={enablePec}
                disabled={!form.isDirty("pec")}
                setCancel={() => {
                  form.setValues({ pec: org.pec });
                  setEnablePec(!enablePec);
                }}
              />
            </Grid.Col>
          )}

          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableWebsite}
              label="Website"
              placeholder="Website"
              variant="unstyled"
              defaultValue={org.website}
              {...form.getInputProps("website")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableWebsite(!enableWebsite)}
                enabled={enableWebsite}
                disabled={!form.isDirty("website")}
                setCancel={() => {
                  setEnableWebsite(!enableWebsite);
                  form.setValues({ website: org.website });
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <Textarea
              disabled={enableDescrizioneImpresa}
              variant="unstyled"
              maxLength={400}
              autosize
              label="Descrizione attività impresa"
              placeholder="Inserire una breve descrizione della società (anni di attività, campi di applicazione)"
              defaultValue={org.description}
              {...form.getInputProps("description")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() =>
                  setEnableDescrizioneImpresa(!enableDescrizioneImpresa)
                }
                enabled={enableDescrizioneImpresa}
                disabled={!form.isDirty("description")}
                setCancel={() => {
                  form.setValues({ description: org.description });
                  setEnableDescrizioneImpresa(!enableDescrizioneImpresa);
                }}
              />
            </Grid.Col>
          )}
          <Grid mb={10}>
            <Grid.Col span={isAdmin ? 12 : matches ? 8 : 10}>
              <Select
                data={
                  reg?.map((e) => {
                    return {
                      value: e.id!,
                      label: e.name!,
                    };
                  })!
                }
                ml={8}
                mr={8}
                label="Regione sede legale"
                placeholder="Regione sede legale"
                variant="unstyled"
                disabled={enableAddress}
                value={form.values.region}
                onChange={(value) => {
                  form.setFieldValue("region", value!);
                  handleRegion(value!, true);
                }}
              />
              <Divider mb="xl" size="xs" ml={8} mr={8} />
            </Grid.Col>
            {!isAdmin && (
              <Grid.Col span={matches ? 4 : 2} ml={-7} mt={20}>
                <EditButtonInput
                  setEnabled={() => {
                    setEnableAddress(!enableAddress);
                  }}
                  enabled={enableAddress}
                  disabled={
                    !form.isDirty("address") &&
                    !form.isDirty("comune") &&
                    !form.isDirty("region") &&
                    !form.isDirty("numCivico") &&
                    !form.isDirty("province")
                  }
                  setCancel={() => {
                    setEnableAddress(!enableAddress);
                    form.setValues({
                      region: org.region,
                      province: org.province,
                      comune: org.comune,
                      address: org.address,
                      numCivico: org.numCivico,
                    });
                  }}
                />
              </Grid.Col>
            )}
            <Grid.Col span={matches ? 12 : 6}>
              <Select
                data={selectProvince ?? []}
                ml={8}
                mr={8}
                label="Provincia sede legale"
                disabled={!selectProvince || enableAddress}
                variant="unstyled"
                placeholder="Provincia sede legale"
                value={form.values.province}
                onChange={(value) => {
                  form.setFieldValue("province", value!);
                  handleProv(value!, true);
                }}
              />
              <Divider mb="xl" size="xs" ml={8} mr={8} />
            </Grid.Col>
            <Grid.Col span={matches ? 12 : 6}>
              <Select
                ml={8}
                mr={8}
                data={selectComune ?? []}
                disabled={!selectComune || enableAddress}
                label="Comune sede legale"
                placeholder="Comune sede legale"
                variant="unstyled"
                defaultValue={org.comune}
                {...form.getInputProps("comune")}
              />
              <Divider mb="xl" size="xs" ml={8} mr={8} />
            </Grid.Col>
            <Grid.Col span={matches ? 12 : 10}>
              <TextInput
                disabled={enableAddress}
                ml={8}
                mr={8}
                label="Indirizzo sede legale"
                placeholder="Indirizzo sede legale"
                variant="unstyled"
                defaultValue={org.address}
                {...form.getInputProps("address")}
              />
              <Divider mb="xl" size="xs" ml={8} mr={8} />
            </Grid.Col>
            <Grid.Col span={matches ? 12 : 2}>
              <TextInput
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={enableAddress}
                ml={8}
                mr={8}
                label="Numero civico"
                placeholder="Numero civico"
                variant="unstyled"
                defaultValue={org.numCivico}
                {...form.getInputProps("numCivico")}
              />
              <Divider mb="xl" size="xs" ml={8} mr={8} />
            </Grid.Col>
          </Grid>
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableContattoRiferimento}
              label="Contatto di riferimento"
              variant="unstyled"
              placeholder="Nome e cognome"
              defaultValue={org.contactReference}
              {...form.getInputProps("contactReference")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() =>
                  setEnableContattoRiferimento(!enableContattoRiferimento)
                }
                enabled={enableContattoRiferimento}
                disabled={!form.isDirty("contactReference")}
                setCancel={() => {
                  form.setValues({ contactReference: org.contactReference });
                  setEnableContattoRiferimento(!enableContattoRiferimento);
                }}
              />
            </Grid.Col>
          )}

          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableLuogoNascita}
              label="Luogo di nascita"
              variant="unstyled"
              placeholder="Luogo di nascita"
              defaultValue={org.luogo_di_nascita}
              {...form.getInputProps("luogo_di_nascita")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableLuogoNascita(!enableLuogoNascita)}
                enabled={enableLuogoNascita}
                disabled={!form.isDirty("luogo_di_nascita")}
                setCancel={() => {
                  form.setValues({ luogo_di_nascita: org.luogo_di_nascita });
                  setEnableLuogoNascita(!enableLuogoNascita);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <DatePicker
              disabled={enableDataNascita}
              label="Data di nascita"
              locale="it"
              variant="unstyled"
              placeholder="Data di nascita"
              value={
                form.values.data_di_nascita
                  ? new Date(form.values.data_di_nascita)
                  : undefined
              }
              onChange={(e) =>
                form.setFieldValue(
                  "data_di_nascita",
                  e ? toIsoString(e) : undefined
                )
              }
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableDataNascita(!enableDataNascita)}
                enabled={enableDataNascita}
                disabled={!form.isDirty("data_di_nascita")}
                setCancel={() => {
                  form.setValues({ data_di_nascita: org.data_di_nascita });
                  setEnableDataNascita(!enableDataNascita);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              maxLength={16}
              disabled={enableCodiceFiscale}
              label="Codice fiscale"
              placeholder="Codice fiscale"
              variant="unstyled"
              defaultValue={org.codiceFiscale}
              {...form.getInputProps("codiceFiscale")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableCodiceFiscale(!enableCodiceFiscale)}
                enabled={enableCodiceFiscale}
                disabled={!form.isDirty("codiceFiscale")}
                setCancel={() => {
                  form.setValues({ codiceFiscale: org.codiceFiscale });
                  setEnableCodiceFiscale(!enableCodiceFiscale);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableRuolo}
              label="Ruolo"
              placeholder="Ruolo"
              variant="unstyled"
              defaultValue={org.role}
              {...form.getInputProps("role")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableRuolo(!enableRuolo)}
                enabled={enableRuolo}
                disabled={!form.isDirty("role")}
                setCancel={() => {
                  form.setValues({ role: org.role });
                  setEnableRuolo(!enableRuolo);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableEmail}
              type={"email"}
              label="Email"
              variant="unstyled"
              placeholder="inserisci un email valida"
              defaultValue={org.email}
              {...form.getInputProps("email")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableEmail(!enableEmail)}
                enabled={enableEmail}
                disabled={!form.isDirty("email")}
                setCancel={() => {
                  form.setValues({ email: org.email });
                  setEnableEmail(!enableEmail);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={matches ? 8 : 10}>
            <TextInput
              w={"80%"}
              disabled={enableTelefono}
              maxLength={10}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              variant="unstyled"
              label="Numero di telefono"
              placeholder="inserisci numero di telefono"
              defaultValue={org.telephone}
              {...form.getInputProps("telephone")}
            />
            <Divider mb="xl" size="xs" />
          </Grid.Col>
          {!isAdmin && (
            <Grid.Col span={matches ? 4 : 2}>
              <EditButtonInput
                setEnabled={() => setEnableTelefono(!enableTelefono)}
                enabled={enableTelefono}
                disabled={!form.isDirty("telephone")}
                setCancel={() => {
                  form.setValues({ telephone: org.telephone });
                  setEnableTelefono(!enableTelefono);
                }}
              />
            </Grid.Col>
          )}
        </Grid>
      </form>
    </>
  );
};
