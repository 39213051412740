/* eslint-disable react-hooks/rules-of-hooks */
import {
  Alert,
  Button,
  CSSObject,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Paper,
  Popover,
  SegmentedControl,
  Select,
  SelectItem,
  Stepper,
  Switch,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconAlertCircle,
  IconCheck,
  IconCurrencyEuro,
  IconX,
} from "@tabler/icons";
import "dayjs/locale/it";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { mutate } from "swr";
import {
  getApiDraftCompilazioneGetPuiDraft,
  getApiDraftCompilazioneHasSubmittedPui,
  getApiFileUploadFileUploadValidationPui,
  getApiPuiGetAllPuiFromCity,
  getApiPuiGetPuiObiettiviSpecifici,
  getApiTypologyGetComuneFromCity,
  getApiTypologyGetRegionFromCity,
  getGetApiOrganizationGetOrganizationKey,
  getGetApiPuiGetAllPuiFromCityKey,
  getGetApiTypologyGetAllCitiesKey,
  getGetApiTypologyGetAllTourismKey,
  getGetApiTypologyGetComuneFromCityKey,
  getGetApiTypologyGetRegionFromCityKey,
  postApiDraftCompilazioneCreateDraftCompilazione,
  postApiPuiCreatePui,
  useGetApiOrganizationGetOrganization,
  useGetApiTypologyGetAllCities,
  useGetApiTypologyGetAllTourism,
  useGetApiTypologyGetTipoprogettiFinanzPui,
} from "../../api/endpoints";
import { PuiCompilazioneDto } from "../../api/model";
import Loading from "../../components/Loading";
import deepEquals from "../../utils/deepEquals";
import SurveyAllegati from "./SurveyAllegati";
import SurveyCompleted from "./SurveyCompleted";

function SurveyPui() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, { close, open }] = useDisclosure(false);
  const [visible, { toggle }] = useDisclosure(false);

  const [checkAllegati, setCheckAllegati] = useState<boolean>();
  const [loaded, setLoaded] = useState(false);
  const [selectRegion, setSelectRegion] = useState<string | undefined>();
  const [selectComune, setSelectComune] = useState<SelectItem[] | undefined>();
  const [selectPuiRiferimento, setSelectPuiRiferimento] = useState<
    SelectItem[] | undefined
  >();
  const [percInterv, setPercInterv] = useState<number | undefined>(0);
  const [obiettiviSpecifici, setObiettiviSpecifici] = useState<
    SelectItem[] | undefined
  >();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isDescrizioneObiettiviActive] = useState(false);
  const [orgId] = useState(
    localStorage.getItem("selectedOrganizationId")?.replace(/"/g, "")
  );
  const [warning, setWarning] = useState<string[]>([]);
  const isAdmin = location?.state?.isAdmin;
  const numberStep = location?.state?.numberStep;
  const [active, setActive] = useState(numberStep ?? 0);

  const {
    data: allCities,
    error: errorCities,
    isLoading: isLoadingCities,
  } = useGetApiTypologyGetAllCities();

  const {
    data: allTourism,
    error: errorTourism,
    isLoading: isLoadingTourism,
  } = useGetApiTypologyGetAllTourism();

  const {
    data: org,
    error: errorOrg,
    isLoading: isLoadingrOrg,
  } = useGetApiOrganizationGetOrganization();

  const {
    data: tipoProgetti,
    error: errorTipoProgetti,
    isLoading: isLoadingTipoProgetti,
  } = useGetApiTypologyGetTipoprogettiFinanzPui();

  const form = useForm<PuiCompilazioneDto>({
    initialValues: {
      organizationId: org?.id,
      progNome: "",
      progCittaMetropolitana: "",
      progRegione: "",
      progComune: "",
      progInherentPui: false,
      progCodice: "",
      puiRiferimento: [],
      puiCorrispndeObbietivi: false,
      progPuiDistanza: undefined,
      finalitaGenerale: "",
      obbGenerale: undefined,
      obbSpecifico: [],
      descrizioneObbSpecifico: "",
      progDescrizione: "",
      costiTotale: 0,
      costiImmobili: 0,
      fontiCopertura: 0,
      costiCapex: 0,
      costiCircolante: 0,
      entitaInterventoFondo: 0,
      cofinanziamentoDebito: 0,
      cofinanziamentoEquity: 0,
      cofinanziamentoPubblico: 0,
      interventoFondoTotInvestment: undefined,
      formaTecnicaIntervento: "",
      tempiLivello: "",
      tempiStadioIter: "",
      tempiTipoAut: "",
      tempiPermessi: undefined,
      tempiInizioLavori: new Date().toISOString(),
      tempiFineLavoriPrev: "",
      tempiDurataLavori: 0,
      tempiDescrizione: "",
      allegatoId: "",
      modalitaEsecuzione: "",
      tipoIntervento: [],
      progettista: "",
      previsioneInizioLavori: false,
      impresaEsecutriceDeilavori: "",
      percTotInvest: undefined,
      nomeMisuraCofinanziamento: "",
      warningsPui: [],
      puiSurveyDto: {
        tipoAutorizzazione: "",
        ristrutturazione: "",
        mqSLP: undefined,
        tipoInterventoPrevisione: [],
        consumoMWh: undefined,
        classeEnergeticaPartenza: [],
        classeEnergeticaRaggiunta: [],
        energiaRinnovabile: undefined,
        potenzaFER: undefined,
        quantitaFER: undefined,
        superficieCoperta: "",
        superficieVerde: "",
        superficieVerdeAggiunta: undefined,
        piantumazioni: "",
        numeroPiantumazioni: undefined,
        forzaLavoro: undefined,
        housing: "",
        canoniLocazione: undefined,
        prezziCompravendita: undefined,
        postiLetto: "",
        numeroPostiLetto: undefined,
        spaziCommerciali: "",
        mobilitaSostenibile: "",
        tipoModalitaSostenibile: [],
        numeroInterventoSostenibile: undefined,
        smartSolution: "",
        tipoSmartSolution: "",
        numeroInterventiSmartSolution: undefined,
        superficieCopertaEsistente: undefined,
        superficieVenditaSpaziCommerciali: undefined,
        noteEmissioneFaseCostruzione: "",
        noteEmissioneFaseEsercizio: "",
        noteFontiEnergiaRinnovabile: "",
        noteConsumoSuolo: "",
        noteAreeVerdi: "",
        noteAlberi: "",
        noteNuoviPostiLavoroIntervento: "",
        noteHousingAffordability: "",
        noteCoperturaFuoriSede: "",
        noteEserciziCommerciali: "",
        notePromozioneMezziTrasportoAlternativi: "",
        noteEmissioniCO2: "",
        //noteSmartSolutions: "",
      },
    },
  });

  const calcoloData = (inizioData: any, mesi: number) => {
    const fineData = new Date(inizioData);
    fineData.setMonth(fineData.getMonth() + mesi);
    return (form.values.tempiFineLavoriPrev = fineData.toISOString());
  };

  const getPerc = (value: number) => {
    const percIntervFond = (value / form?.values?.costiTotale!) * 100;
    if (isNaN(percIntervFond) || percIntervFond === Infinity) {
      setPercInterv(0);
      form.setFieldValue("percTotInvest", 0);
    } else {
      setPercInterv(percIntervFond);
      form.setFieldValue("percTotInvest", percIntervFond);
    }
  };

  const nextStep = () =>
    setActive((current: any) => (current < 6 ? current + 1 : current));
  const prevStep = () =>
    setActive((current: any) => (current > 0 ? current - 1 : current));

  const dateExceeded =
    new Date(form?.values?.tempiFineLavoriPrev!) > new Date("2026-08-31");
  const valoreSupWarning = form.values.entitaInterventoFondo! > 30000000;
  const percTotInvestWarning = form.values?.percTotInvest! > 50;

  const valoreSupMessage =
    "Il valore dell'entità intervento fondo non può superare i 30 milioni di euro";
  const percTotInvestMessage = `La percentuale intervento fondo su totale investimento non può superare il 50%`;

  if (!warning.includes(valoreSupMessage) && valoreSupWarning) {
    setWarning([...warning, valoreSupMessage]);
  } else if (warning.includes(valoreSupMessage) && !valoreSupWarning) {
    const warningList = warning.filter(
      (message) => message !== valoreSupMessage
    );
    setWarning(warningList);
  }

  if (!warning.includes(percTotInvestMessage) && percTotInvestWarning) {
    setWarning([...warning, percTotInvestMessage]);
  } else if (warning.includes(percTotInvestMessage) && !percTotInvestWarning) {
    const warningList = warning.filter(
      (message) => message !== percTotInvestMessage
    );
    setWarning(warningList);
  }

  const sommaFontiCopertura =
    form.values.entitaInterventoFondo! +
    form.values.cofinanziamentoDebito! +
    form.values.cofinanziamentoEquity! +
    form.values.cofinanziamentoPubblico!;

  useEffect(() => {
    form.setFieldValue("fontiCopertura", sommaFontiCopertura);
    return;
  }, [
    form.values.entitaInterventoFondo,
    form.values.cofinanziamentoDebito,
    form.values.cofinanziamentoEquity,
    form.values.cofinanziamentoPubblico,
  ]);

  useEffect(() => {
    getPerc(form?.values?.entitaInterventoFondo!);
  }, [form.values.costiTotale, form.values.entitaInterventoFondo]);

  const getObGen = useCallback(
    (value: any, resetFields: boolean) => {
      const selectOb = Number(value);
      form.values.obbSpecifico = [];
      if (resetFields) form.setFieldValue("obbGenerale", selectOb);
      getApiPuiGetPuiObiettiviSpecifici({
        obbGeneraleId: selectOb,
        cityId: form.values.progCittaMetropolitana,
      }).then((res) => {
        setObiettiviSpecifici(
          res?.map((value) => {
            return {
              value: value.id!,
              label: value.nome!,
            };
          })
        );
      });
    },
    [form.values]
  );

  const handleChangeCittaMetropolitana = useCallback(
    (progCittaMetropolitana: string, resetFields: boolean) => {
      if (resetFields) {
        form.setFieldValue("progComune", "");
        form.setFieldValue("progRegione", "");
        form.setFieldValue("puiRiferimento", []);
        form.setFieldValue("obbGenerale", undefined);
        form.setFieldValue("obbSpecifico", []);
      }
      getApiTypologyGetComuneFromCity({
        cityId: progCittaMetropolitana,
      }).then((result) => {
        setSelectComune(
          result?.map((provincia) => {
            return {
              value: provincia?.id!,
              label: provincia?.name!,
            };
          })!
        );
      });
      getApiTypologyGetRegionFromCity({
        cityId: progCittaMetropolitana,
      }).then((result) => {
        setSelectRegion(result.name);
        getApiPuiGetAllPuiFromCity({
          cityId: progCittaMetropolitana,
        }).then((result) => {
          setSelectPuiRiferimento(
            result?.map((puiRif) => {
              return { value: puiRif?.id!, label: puiRif?.nome! };
            })
          );
        });
      });
    },
    [form.values]
  );

  useEffect(() => {
    handleChangeCittaMetropolitana(form.values.progCittaMetropolitana!, false);
  }, [form.values.progCittaMetropolitana]);

  useEffect(() => {
    const orgSurveyPui = location?.state?.orgSurveyPui;
    const draftSurveyPui = location?.state?.draftSurveyPui;
    getApiDraftCompilazioneHasSubmittedPui({
      orgId: orgSurveyPui
        ? orgSurveyPui?.organizationId
        : draftSurveyPui
        ? draftSurveyPui?.organizationId
        : orgId!,
    }).then((hasSub: boolean | ((prevState: boolean) => boolean)) =>
      setHasSubmitted(hasSub)
    );

    getApiDraftCompilazioneGetPuiDraft({
      orgId: orgSurveyPui
        ? orgSurveyPui?.organizationId
        : draftSurveyPui
        ? draftSurveyPui?.organizationId
        : orgId!,
    })
      .then((data) => {
        form.setValues(data);
        form.setFieldValue("obbSpecifico", data.obbSpecifico);

        getApiPuiGetPuiObiettiviSpecifici({
          obbGeneraleId: data.obbGenerale,
          cityId: data.progCittaMetropolitana,
        }).then((res) => {
          setObiettiviSpecifici(
            res?.map((value) => {
              return {
                value: value.id!,
                label: value.nome!,
              };
            })
          );
        });
      })
      .finally(() => {
        setLoaded(true);
        if (form.values.tempiInizioLavori !== undefined) {
          calcoloData(
            form.values.tempiInizioLavori,
            form.values.tempiDurataLavori!
          );
        }
      });
  }, []);

  useEffect(() => {
    if (!hasSubmitted && loaded) {
      // form.setFieldValue("status", "Aperta");
      form.setFieldValue("warningsPui", warning);
      form.values.status = "Aperta";

      getApiFileUploadFileUploadValidationPui({
        orgId: orgId,
        callType: "PUI",
      }).then((res) => {
        setCheckAllegati(res);
      });
      postApiDraftCompilazioneCreateDraftCompilazione({
        compilazioneType: 1,
        json: JSON.stringify(form.values),
        organizationId: orgId,
      });
    }
  }, [active]);

  if (
    isLoadingTourism ||
    isLoadingrOrg ||
    isLoadingCities ||
    isLoadingTipoProgetti
  ) {
    return <Loading />;
  }

  if (
    (!Array.isArray(allTourism) || errorTourism) &&
    (!Array.isArray(allTourism) || errorTipoProgetti) &&
    (!Array.isArray(allCities) || errorCities) &&
    (!org || errorOrg)
  ) {
    mutate(getGetApiTypologyGetAllTourismKey());
    mutate(getGetApiTypologyGetAllCitiesKey());
    mutate(getGetApiTypologyGetRegionFromCityKey());
    mutate(getGetApiTypologyGetComuneFromCityKey());
    mutate(getGetApiOrganizationGetOrganizationKey());
    mutate(getGetApiPuiGetAllPuiFromCityKey());
    return <Loading></Loading>;
  }

  function handleSubmit(values: PuiCompilazioneDto): void {
    console.log("-----------invio in corso-----------");
    values.allegatoId = "c56c2e42-1fa3-45e1-b262-5fe079d06006";
    values.organizationId = orgId;
    values.status = "Candidatura Sottomessa";
    getApiDraftCompilazioneGetPuiDraft({ orgId: orgId! }).then((data) => {
      console.log("draft and form values match: ", deepEquals(data, values));
    });

    if (hasSubmitted) return;
    postApiPuiCreatePui(values)
      .then((res) => {
        showNotification({
          title: !!res.error
            ? t("notifications.submission-error")
            : t("notifications.submission"),
          message: !res
            ? "Controlla che tutti i campi obbligatori siano compilati"
            : "Salvataggio avvenuto con successo",
          icon: !res ? <IconX size="1.1rem" /> : <IconCheck size="1.1rem" />,
          color: !res ? "red" : "teal",
        });
        navigate("/candidaturaCompletata");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SurveyPUI.tsx:428 ~ handleSubmit ~ error:",
          error
        );
        showNotification({
          title: "Errore",
          message: "Controlla che tutti i campi obbligatori siano compilati",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      });
    toggle();
  }
  const openModal = () =>
    openConfirmModal({
      title: "Conferma",
      children: (
        <Text size="sm">
          Sicuro di inviare la candidatura. Non sarà più possibile modificarla.
        </Text>
      ),
      labels: { confirm: "Invia", cancel: "Indietro" },
      onCancel: () => {
        console.log("Cancel");
      },
      onConfirm: () => {
        console.log("Confirmed");
        toggle();
        handleSubmit(form.values);
      },
    });

  function handleAllegati() {
    getApiFileUploadFileUploadValidationPui({
      orgId: orgId,
      callType: "PUI",
    }).then((res) => {
      setCheckAllegati(res);
    });
  }

  const progettoConditions =
    !form.values?.progNome ||
    !form.values?.progCittaMetropolitana ||
    !form.values?.progComune ||
    form.values?.puiRiferimento?.length === 0 ||
    form.values?.progPuiDistanza === undefined ||
    !form.values?.finalitaGenerale ||
    form.values?.obbGenerale === undefined ||
    form.values?.tipoIntervento?.length === 0 ||
    !form.values?.progDescrizione;

  const costiConditions =
    form.values?.costiTotale === undefined ||
    form.values?.costiImmobili === undefined ||
    form.values?.costiCapex === undefined ||
    form.values?.costiCircolante === undefined ||
    form.values?.entitaInterventoFondo === undefined ||
    form.values?.cofinanziamentoDebito === undefined ||
    form.values?.cofinanziamentoEquity === undefined ||
    form.values?.cofinanziamentoPubblico === undefined ||
    (form.values?.cofinanziamentoPubblico &&
      !form.values?.nomeMisuraCofinanziamento) ||
    !form.values?.formaTecnicaIntervento;

  const tempisticheConditions =
    !form.values?.tempiLivello ||
    !form.values?.tempiStadioIter ||
    !form.values?.tempiTipoAut ||
    form.values?.tempiPermessi === undefined ||
    !form.values?.tempiDescrizione ||
    form.values?.tempiDurataLavori === undefined ||
    !form.values?.tempiInizioLavori;

  const sxStepperStepStyles = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }): CSSObject => ({
    "& .mantine-Stepper-stepIcon": {
      borderColor:
        isAdmin || hasSubmitted
          ? "#228BE6"
          : active > activeNumber || active < activeNumber
          ? condition
            ? "#F1F3F5"
            : "#228BE6"
          : "",
      backgroundColor:
        isAdmin || hasSubmitted
          ? active === activeNumber
            ? "white"
            : "#228BE6"
          : active > activeNumber || active < activeNumber
          ? condition
            ? "#F1F3F5"
            : "#228BE6"
          : "#F1F3F5",
      color:
        isAdmin || hasSubmitted
          ? active === activeNumber
            ? "#228BE6"
            : "#F1F3F5"
          : active > activeNumber || active < activeNumber
          ? condition
            ? "#F1F3F5"
            : "white"
          : "#228BE6",
    },
  });

  const iconStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted ? null : active > activeNumber ||
      active < activeNumber ? (
      condition ? (
        <Text c={"#495057"}>{activeNumber + 1}</Text>
      ) : (
        <IconCheck />
      )
    ) : null;

  const colorStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted
      ? "#228BE6"
      : active > activeNumber || active < activeNumber
      ? condition
        ? "#F1F3F5"
        : "#228BE6"
      : "";

  const completedIconStepperStep = ({
    activeNumber,
    condition,
  }: {
    activeNumber: number;
    condition: boolean;
  }) =>
    isAdmin || hasSubmitted ? (
      <>{activeNumber + 1}</>
    ) : active > activeNumber || active < activeNumber ? (
      condition ? (
        <Text c={"#495057"}>{activeNumber + 1}</Text>
      ) : (
        <IconCheck />
      )
    ) : (
      <></>
    );

  const organizationId = location?.state?.organizationId;
  const statusPUI = location?.state?.puiCompilazione?.status;

  return (
    <Paper p={24} radius={12} withBorder>
      <Title
        sx={(theme) => ({
          fontSize: 34,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xl,
          },
        })}
        mb="xl"
      >
        Candidatura Fondo Tematico Piani Urbani Integrati
      </Title>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        <Stepper
          size={"xs"}
          radius={"xl"}
          active={active}
          onStepClick={setActive}
          breakpoint="sm"
          styles={{
            steps: {
              "@media (max-width: 51.27em)": {
                display: "flex",
                flexDirection: "row",
                overflowX: "hidden",
                overflow: "scroll",
                height: "50px",
              },
            },
          }}
        >
          <Stepper.Step
            orientation="horizontal"
            label="Progetto"
            sx={sxStepperStepStyles({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            icon={iconStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            color={colorStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 0,
              condition: progettoConditions,
            })}
          >
            <Grid grow>
              <Title order={4} ml={8} mt={10}>
                Progetto
              </Title>
              <Grid.Col span={12} mb={10} mt={10}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Nome"
                  placeholder="Nome"
                  {...form.getInputProps("progNome")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={3} mb={10} mt={10}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={
                    allCities?.map((o) => {
                      return {
                        value: o.id!,
                        label: o.name!,
                      };
                    })!
                  }
                  withAsterisk
                  label="Città metropolitana"
                  placeholder="Città metropolitana"
                  value={form.values.progCittaMetropolitana}
                  onChange={(value) => {
                    form.setFieldValue("progCittaMetropolitana", value!);
                    handleChangeCittaMetropolitana(value!, true);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={selectComune ?? []}
                  withAsterisk
                  label="Comune"
                  placeholder="Comune"
                  value={form.values.progComune}
                  onChange={(value) => {
                    form.setFieldValue("progComune", value!);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={3} mb={10} mt={10}>
                <TextInput
                  label="Regione"
                  placeholder="Regione"
                  value={selectRegion}
                  disabled
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={8} mb={20} mt={30} ml={-30}>
                <Switch
                  disabled={hasSubmitted || isAdmin}
                  checked={form.values?.progInherentPui}
                  ml={33}
                  fw={"600"}
                  label="Il progetto è direttamente inerente o rafforza un PUI della Città Metropolitana?"
                  labelPosition="left"
                  size="md"
                  onLabel="SI"
                  offLabel="NO"
                  {...form.getInputProps("progInherentPui")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={4} mt={-2}>
                <TextInput
                  disabled={
                    !form.values.progInherentPui || hasSubmitted || isAdmin
                  }
                  max={100}
                  maxLength={3}
                  label="Codice del progetto"
                  placeholder={"Codice del progetto"}
                  rightSection={
                    <Tooltip
                      multiline
                      label="Inserire codice del progetto"
                      position="top-end"
                      withArrow
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("progCodice")}
                />
              </Grid.Col>
              <Grid.Col span={10} mb={10} mt={10}>
                <MultiSelect
                  data={selectPuiRiferimento ?? []}
                  withAsterisk
                  label="PUI di riferimento"
                  placeholder="Seleziona PUI"
                  disabled={
                    !form.values.progCittaMetropolitana ||
                    hasSubmitted ||
                    isAdmin
                  }
                  {...form.getInputProps("puiRiferimento")}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "16rem",
                    },
                  }}
                />
              </Grid.Col>
              <Tooltip
                multiline
                label="Scegliere uno o più titoli di Piani Urbani Integrati presentati dalla Città in cui si inserisce l'iniziativa a cui si collega il progetto (per prossimità od obiettivi)"
                position="top-end"
                withArrow
                events={{ hover: true, focus: true, touch: true }}
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{
                      display: "block",
                      opacity: 0.5,
                      marginTop: 52,
                      marginRight: 8,
                    }}
                  />
                </div>
              </Tooltip>
              <Grid.Col span={12} mb={10} mt={10} ml={-30}>
                <Switch
                  checked={form.values?.puiCorrispndeObbietivi}
                  ml={33}
                  fw={"600"}
                  label="Gli obiettivi del progetto corrispondono agli obiettivi del PUI?"
                  labelPosition="left"
                  size="md"
                  onLabel="SI"
                  offLabel="NO"
                  disabled={hasSubmitted || isAdmin}
                  {...form.getInputProps("puiCorrispndeObbietivi")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  withAsterisk
                  label="Distanza da intervento PUI più vicino"
                  placeholder="Distanza da intervento PUI più vicino"
                  icon={"mt"}
                  rightSection={
                    <Tooltip
                      multiline
                      label="Inserire la distanza in metri dal progetto più vicino previsto nel/nei PUI di cui sopra"
                      position="top-end"
                      withArrow
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("progPuiDistanza")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  data={[
                    {
                      value:
                        "manutenzione per il riutilizzo e la rifunzionalizzazione delle aree pubbliche",
                      label:
                        "manutenzione per il riutilizzo e la rifunzionalizzazione delle aree pubbliche",
                    },
                    {
                      value:
                        "miglioramento della qualità del decoro urbano e del tessuto sociale e ambientale",
                      label:
                        "miglioramento della qualità del decoro urbano e del tessuto sociale e ambientale",
                    },
                    {
                      value:
                        "miglioramento della qualità ambientale e del profilo digitale delle aree urbane",
                      label:
                        "miglioramento della qualità ambientale e del profilo digitale delle aree urbane",
                    },
                  ]}
                  withAsterisk={form.values.puiRiferimento ? true : false}
                  disabled={
                    !form.values.puiRiferimento || hasSubmitted || isAdmin
                  }
                  label="Finalità generali"
                  placeholder="Finalità generali"
                  {...form.getInputProps("finalitaGenerale")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  disabled={
                    hasSubmitted ||
                    isAdmin ||
                    form.values.puiRiferimento?.length === 0
                  }
                  withAsterisk
                  label="Obiettivo generale di riferimento"
                  placeholder="Obiettivo generale di riferimento"
                  clearable
                  data={[
                    { value: "0", label: "Ambiente" },
                    {
                      value: "1",
                      label: "Inclusione sociale/culturale/sportiva",
                    },
                    { value: "2", label: "Infrastrutture" },
                    { value: "3", label: "Rigenerazione urbana" },
                  ]}
                  value={form.values.obbGenerale?.toString()}
                  onChange={(value) => getObGen(value, true)}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <MultiSelect
                  // withAsterisk={!obiettiviSpecifici?.length}
                  disabled={
                    !obiettiviSpecifici?.length ||
                    !form.values.puiRiferimento?.length ||
                    hasSubmitted ||
                    isAdmin
                  }
                  label="Obiettivo/i specifico/i di riferimento"
                  placeholder="Obiettivo/i specifici di riferimento"
                  clearable
                  data={obiettiviSpecifici ?? []}
                  {...form.getInputProps("obbSpecifico")}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "85%",
                    },
                  }}
                />
              </Grid.Col>
              {isDescrizioneObiettiviActive && (
                <Grid.Col span={12} mb={10}>
                  <Textarea
                    disabled={hasSubmitted || isAdmin}
                    withAsterisk
                    maxLength={1000}
                    minLength={500}
                    label="Descrizione obiettivo specifico"
                    placeholder="Descrizione obiettivo specifico"
                    {...form.getInputProps("descrizioneObbSpecifico")}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={5}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  clearable
                  withAsterisk
                  label="Tipologia intervento"
                  placeholder="Seleziona tipologia interevento"
                  creatable
                  data={
                    tipoProgetti?.map((e) => {
                      return {
                        value: e?.id!,
                        label: e?.name!,
                      };
                    })!
                  }
                  {...form.getInputProps("tipoIntervento")}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "90%",
                    },
                  }}
                />
              </Grid.Col>
              <Tooltip
                multiline
                label="Scegliere una o più tipologie di intervento riconducibili alla natura del progetto"
                position="bottom"
                withArrow
                events={{ hover: true, focus: true, touch: true }}
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{
                      display: "block",
                      opacity: 0.5,
                      marginTop: 42,
                      marginRight: 8,
                      marginBottom: 5,
                    }}
                  />
                </div>
              </Tooltip>
              <Grid.Col span={12} mb={10} mt={10}>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  maxLength={1000}
                  minLength={500}
                  label="Descrizione"
                  placeholder="Sintetica descrizione progetto, con focus su interventi energetici e digitali"
                  {...form.getInputProps("progDescrizione")}
                  rightSection={
                    <Tooltip
                      label="Sintetica descrizione progetto, con focus su interventi energetici e digitali"
                      position="top-end"
                      withArrow
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  label="Progettista"
                  placeholder="Progettista"
                  {...form.getInputProps("progettista")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  label="Impresa esecutrice dei lavori"
                  placeholder="Impresa esecutrice dei lavori"
                  {...form.getInputProps("impresaEsecutriceDeilavori")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Fonti e Impieghi"
            sx={sxStepperStepStyles({
              activeNumber: 1,
              condition: costiConditions,
            })}
            icon={iconStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
            color={colorStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 1,
              condition: costiConditions,
            })}
          >
            <Grid>
              <Grid.Col>
                <Title order={4} mt={10}>
                  Fonti e impieghi
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  hideControls
                  decimalSeparator=","
                  precision={2}
                  withAsterisk
                  label="Totale Investimento"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiTotale")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  withAsterisk
                  hideControls
                  label="di cui acquisto immobili/terreni"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiImmobili")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  withAsterisk
                  label="di cui capex"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  rightSection={
                    <Tooltip
                      label="CAPEX = spese per la realizzazione di investimenti in attività immobilizzate di natura operativa"
                      position="top-end"
                      withArrow
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("costiCapex")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  withAsterisk
                  label="di cui circolante"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("costiCircolante")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4} mt="md">
                  Fonti di copertura
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  disabled
                  label="Fonti di copertura"
                  value={isNaN(sommaFontiCopertura) ? 0 : sommaFontiCopertura}
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  withAsterisk
                  label="Entità intervento fondo"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  rightSection={
                    <Tooltip
                      label="L'entità dell'intervento del fondo può coprire al più il 50% dell'investimento totale"
                      position="top-end"
                      withArrow
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  value={form.values.entitaInterventoFondo}
                  onChange={(value) => {
                    form.setFieldValue("entitaInterventoFondo", value);
                  }}
                />
              </Grid.Col>
              {isAdmin && valoreSupWarning ? (
                <>
                  <Grid.Col xs={12} sm={6} mt={40}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="yellow"
                    >
                      {valoreSupMessage}
                    </Alert>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col sm={6}></Grid.Col>
              )}
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  withAsterisk
                  label="Cofinanziamento con debito bancario"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("cofinanziamentoDebito")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  withAsterisk
                  label="Cofinanziamento con equity"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("cofinanziamentoEquity")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  disabled={hasSubmitted || isAdmin}
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  hideControls
                  withAsterisk
                  label="Cofinanziamento risorse pubbliche"
                  placeholder="0"
                  icon={
                    <IconCurrencyEuro
                      size={"1rem"}
                      strokeWidth={1.5}
                      color={"gray"}
                    />
                  }
                  {...form.getInputProps("cofinanziamentoPubblico")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>

              <Grid.Col xs={12} sm={6} mt={-10}>
                <TextInput
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Nome della misura di cofinanziamento nel caso di risorse pubbliche"
                  placeholder="Nome della misura di cofinanziamento nel caso di risorse pubbliche"
                  {...form.getInputProps("nomeMisuraCofinanziamento")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>

              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  maxLength={3}
                  label="% intervento fondo su totale investimento"
                  icon={"%"}
                  disabled
                  value={isNaN(percInterv!) ? 0 : percInterv}
                />
              </Grid.Col>
              {isAdmin && percTotInvestWarning ? (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Attenzione!"
                      color="yellow"
                    >
                      {percTotInvestMessage}
                    </Alert>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col sm={6}></Grid.Col>
              )}
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    {
                      value: "Debito",
                      label: "Debito",
                    },
                    {
                      value: "Equity",
                      label: "Equity",
                    },
                    {
                      value: "Quasi equity",
                      label: "Quasi equity",
                    },
                  ]}
                  withAsterisk
                  label="Forma tecnica di intervento del Fondo"
                  placeholder="Forma tecnica di intervento del Fondo"
                  {...form.getInputProps("formaTecnicaIntervento")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Tempistiche"
            sx={sxStepperStepStyles({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            icon={iconStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            color={colorStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 2,
              condition: tempisticheConditions || dateExceeded,
            })}
          >
            <Grid grow>
              <Grid.Col>
                <Title order={4} mt={10}>
                  Tempistiche
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    {
                      value: "Studio di fattibilità",
                      label: "Studio di fattibilità",
                    },
                    {
                      value: "PFTE",
                      label: "PFTE",
                    },
                    {
                      value: "PD",
                      label: "PD",
                    },
                    {
                      value: "PE",
                      label: "PE",
                    },
                  ]}
                  withAsterisk
                  label="Livello di progettazione"
                  placeholder="Livello di progettazione"
                  {...form.getInputProps("tempiLivello")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Select
                  disabled={hasSubmitted || isAdmin}
                  data={[
                    {
                      value: "Chiuso",
                      label: "Chiuso",
                    },
                    {
                      value: "In corso",
                      label: "In corso",
                    },
                    {
                      value: "Da avviare",
                      label: "Da avviare",
                    },
                  ]}
                  withAsterisk
                  label="Stadio iter urbanistico - procedurale"
                  placeholder="Stadio iter urbanistico - procedurale"
                  {...form.getInputProps("tempiStadioIter")}
                />
              </Grid.Col>
              <Grid.Col span={12} mb={10} mt={10}>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  withAsterisk
                  label="Tipologia di autorizzazione necessaria"
                  placeholder="Tipologia di autorizzazione necessaria"
                  {...form.getInputProps("tempiTipoAut")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                  withAsterisk
                  label="Tempistiche attese per ottenimento permessi (giorni)"
                  placeholder="Tempistiche attese per ottenimento permessi (giorni)"
                  icon={"gg"}
                  disabled={hasSubmitted || isAdmin}
                  rightSection={
                    <Tooltip
                      label="Inserire i tempi attesi per arrivare ad autorizzazione urbanistica (0 se titoli abilitativi già ritirati)"
                      position="top-end"
                      withArrow
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("tempiPermessi")}
                />
              </Grid.Col>
              <Grid.Col span={4} mb={10} mt={10}>
                <DatePicker
                  disabled={hasSubmitted || isAdmin}
                  locale="it"
                  withAsterisk
                  label="Data inizio lavori prevista"
                  placeholder="Seleziona data inizio"
                  clearable={false}
                  value={
                    form.values.tempiInizioLavori
                      ? new Date(form.values.tempiInizioLavori)
                      : new Date()
                  }
                  onChange={(e) => {
                    form.setFieldValue("tempiInizioLavori", e?.toISOString());
                    calcoloData(e, form?.values?.tempiDurataLavori!);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={2} mb={10} mt={43}>
                <Popover
                  width={200}
                  position="bottom"
                  withArrow
                  shadow="md"
                  opened={opened}
                >
                  <Popover.Target>
                    <Checkbox
                      checked={form.values.previsioneInizioLavori}
                      disabled={hasSubmitted || isAdmin}
                      onMouseEnter={open}
                      onMouseLeave={close}
                      label="Previsionale"
                      {...form.getInputProps("previsioneInizioLavori")}
                    />
                  </Popover.Target>
                  <Popover.Dropdown sx={{ pointerEvents: "none" }}>
                    <Text size="sm">
                      Selezionare questa casella nel caso in cui non si ha una
                      data certa dell'inizio dei lavori. Bisogna inserire
                      comunque una data
                    </Text>
                  </Popover.Dropdown>
                </Popover>
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <NumberInput
                  withAsterisk
                  disabled={
                    hasSubmitted || isAdmin || !form.values.tempiInizioLavori
                  }
                  label="Durata lavori prevista (mesi)"
                  placeholder="Durata lavori prevista (mesi)"
                  min={0}
                  hideControls
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={form.values.tempiDurataLavori ?? 0}
                  onChange={(e) => {
                    form.setFieldValue("tempiDurataLavori", e);
                    calcoloData(form.values.tempiInizioLavori, e ?? 0);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6} mb={10} mt={10}>
                <Text
                  sx={{
                    marginTop: 24,
                    "@media (max-width: 51.27em)": {
                      marginTop: 0,
                    },
                  }}
                  align={"center"}
                  {...form.getInputProps("tempiFineLavoriPrev")}
                >
                  Mese fine:{" "}
                  {form.values.tempiDurataLavori === undefined ||
                  form.values.tempiDurataLavori === 0
                    ? "Inserisci data inizio e durata lavori"
                    : form.values.tempiFineLavoriPrev
                    ? new Date(
                        form.values.tempiFineLavoriPrev!
                      ).toLocaleDateString("it-IT", {
                        month: "long",
                        year: "numeric",
                      })
                    : "Inserisci data inizio e durata lavori"}
                </Text>
                {dateExceeded && (
                  <>
                    <Grid.Col xs={12} sm={6} offset={2}>
                      <Alert
                        icon={<IconAlertCircle size="1rem" />}
                        title="Attenzione!"
                        color="red"
                      >
                        La data non puo' superare il 31/08/2026
                      </Alert>
                    </Grid.Col>
                  </>
                )}
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  withAsterisk
                  maxLength={500}
                  minLength={200}
                  label="Descrizione iter procedurale"
                  placeholder="Descrizione iter procedurale"
                  disabled={hasSubmitted || isAdmin}
                  {...form.getInputProps("tempiDescrizione")}
                />
              </Grid.Col>
              <Grid.Col span={12} mb={10} mt={10}>
                <Select
                  data={[
                    {
                      value: "a corpo",
                      label: "a corpo",
                    },
                    {
                      value: "a successivi lotti funzionali",
                      label: "a successivi lotti funzionali",
                    },
                  ]}
                  label="Modalità di esecuzione dell'opera"
                  placeholder="Modalità di esecuzione dell'opera"
                  disabled={hasSubmitted || isAdmin}
                  {...form.getInputProps("modalitaEsecuzione")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Valutazione d'Impatto"
            sx={
              hasSubmitted
                ? sxStepperStepStyles({
                    activeNumber: 3,
                    condition: true,
                  })
                : undefined
            }
            icon={
              hasSubmitted
                ? iconStepperStep({
                    activeNumber: 3,
                    condition: true,
                  })
                : undefined
            }
            color={
              hasSubmitted
                ? colorStepperStep({
                    activeNumber: 3,
                    condition: true,
                  })
                : undefined
            }
            completedIcon={
              hasSubmitted
                ? completedIconStepperStep({
                    activeNumber: 3,
                    condition: true,
                  })
                : undefined
            }
          >
            <Grid grow>
              <Grid.Col>
                <Title order={4}>
                  Intervento architettonico - Qualità dell'aria
                </Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Emissioni CO2 - fase di costruzione</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede la ristrutturazione (ad eccezione di
                    demolizione e ricostruzione)?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.ristrutturazione === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() =>
                      form.setFieldValue("puiSurveyDto.mqSLP", undefined)
                    }
                    {...form.getInputProps("puiSurveyDto.ristrutturazione")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.ristrutturazione === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      label="Quanti mq (SLP) sono interessati da ristrutturazione?"
                      placeholder="0"
                      icon="mq"
                      {...form.getInputProps("puiSurveyDto.mqSLP")}
                      rightSection={
                        <Tooltip
                          label="Si intende superficie lorda di pavimento (orizzontale) anche in caso di interventi su facciata e involucro esterno"
                          position="top-end"
                          withArrow
                          multiline
                          events={{ hover: true, focus: true, touch: true }}
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                    />
                  </Grid.Col>
                  <Grid.Col sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col span={11}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  clearable
                  label="Che tipologia di intervento si sta prevedendo? "
                  placeholder="Seleziona tipologia interevento"
                  data={[
                    { value: "1", label: " Ristrutturazione interna" },
                    { value: "2", label: " Interventi sull'involucro esterno" },
                    { value: "3", label: " Sostituzione impianti" },
                    { value: "4", label: " Restauro" },
                    {
                      value: "5",
                      label: "  Altro (specificare in campo note)",
                    },
                  ]}
                  {...form.getInputProps(
                    "puiSurveyDto.tipoInterventoPrevisione"
                  )}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "90%",
                    },
                  }}
                />
              </Grid.Col>
              <Tooltip
                label="É possibile selezionare più di una risposta multipla. "
                position="bottom"
                withArrow
                multiline
                events={{ hover: true, focus: true, touch: true }}
              >
                <div>
                  <IconAlertCircle
                    size="1rem"
                    style={{
                      display: "block",
                      opacity: 0.5,
                      marginTop: 42,
                    }}
                  />
                </div>
              </Tooltip>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteEmissioneFaseCostruzione"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Emissioni CO2 - fase di esercizio</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Qual è il consumo complessivo in MWh/anno atteso per l'edificio?"
                  placeholder="0"
                  rightSection={
                    <Tooltip
                      label="Se il dato non è disponibile, compilare solo la domanda successiva"
                      position="top-end"
                      withArrow
                      multiline
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("puiSurveyDto.consumoMWh")}
                />
              </Grid.Col>
              <Grid.Col sm={6}></Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  label="Qual è la classe energetica di partenza (allo stato precedente i lavori)?"
                  placeholder="Classe energetica di partenza"
                  data={[
                    { value: "A+", label: "- A+ (<15 kWh/mq annui)" },
                    { value: "A", label: "- A (15÷30 kWh/mq annui)" },
                    { value: "B", label: "- B (31÷50 kWh/mq annui)" },
                    { value: "C", label: "- C (51÷70 kWh/mq annui)" },
                    { value: "D", label: "- D (71÷90 kWh/mq annui)" },
                    { value: "E", label: "- E (91÷120 kWh/mq annui)" },
                    { value: "F", label: "- F (121÷160 kWh/mq annui)" },
                    { value: "G", label: "- G (> 160 kWh/mq annui)" },
                    { value: "NA", label: "- n.a. perchè nuova costruzione" },
                  ]}
                  {...form.getInputProps(
                    "puiSurveyDto.classeEnergeticaPartenza"
                  )}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "90%",
                    },
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                <MultiSelect
                  disabled={hasSubmitted || isAdmin}
                  label="Quale classe energetica raggiunge il progetto?"
                  placeholder="Classe energetica da raggiungere"
                  data={[
                    { value: "A+", label: "- A+ (<15 kWh/mq annui)" },
                    { value: "A", label: "- A (15÷30 kWh/mq annui)" },
                    { value: "B", label: "- B (31÷50 kWh/mq annui)" },
                    { value: "C", label: "- C (51÷70 kWh/mq annui)" },
                    { value: "D", label: "- D (71÷90 kWh/mq annui)" },
                    { value: "E", label: "- E (91÷120 kWh/mq annui)" },
                    { value: "F", label: "- F (121÷160 kWh/mq annui)" },
                    { value: "G", label: "- G (> 160 kWh/mq annui)" },
                    { value: "NA", label: "- n.a. perchè nuova costruzione" },
                  ]}
                  sx={{
                    maxWidth: 840,
                    "@media (max-width: 51.27em)": {
                      maxWidth: "90%",
                    },
                  }}
                  {...form.getInputProps(
                    "puiSurveyDto.classeEnergeticaRaggiunta"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteEmissioneFaseEsercizio"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>
                  Intervento architettonico - Energia pulita
                </Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Fonti di energia rinnovabile</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  hideControls
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Quanti MWh/anno di energia verranno prodotti da fonti rinnovabili?"
                  placeholder="0"
                  {...form.getInputProps("puiSurveyDto.energiaRinnovabile")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Se il dato non è disponibile, inserire la potenza degli impianti FER previsti nel progetto con indicazione della tipologia "
                  placeholder="Potenza impianti FER"
                  {...form.getInputProps("puiSurveyDto.potenzaFER")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>

              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  hideControls
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  label="Se il dato non è disponibile, inserire la quantità degli impianti FER previsti nel progetto con indicazione della tipologia "
                  placeholder="Quantità impianti FER"
                  {...form.getInputProps("puiSurveyDto.quantitaFER")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteFontiEnergiaRinnovabile"
                  )}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Consumo di suolo</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede di recuperare la superficie coperta
                    esistente?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.superficieCoperta === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.superficieCoperta &&
                        form.setFieldValue(
                          "puiSurveyDto.superficieCopertaEsistente",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.superficieCoperta")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values?.puiSurveyDto?.superficieCoperta === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Qual è la superficie coperta esistente che viene recuperata?"
                      rightSection={
                        <Tooltip
                          label="Valida in questo caso anche demolizione e ricostruzione se si mantiene la stessa superficie coperta (Sc)"
                          position="top-end"
                          events={{ hover: true, focus: true, touch: true }}
                          withArrow
                          multiline
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps(
                        "puiSurveyDto.superficieCopertaEsistente"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("puiSurveyDto.noteConsumoSuolo")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Aree verdi</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede di aggiungere superficie verde rispetto
                    allo stato di fatto?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.superficieVerde === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.superficieVerde &&
                        form.setFieldValue(
                          "puiSurveyDto.superficieVerdeAggiunta",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.superficieVerde")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.superficieVerde === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quanta superficie verde viene aggiunta rispetto allo stato di fatto?"
                      rightSection={
                        <Tooltip
                          label="Valida in questo caso anche demolizione e ricostruzione se si mantiene la stessa superficie coperta (Sc)"
                          position="top-end"
                          events={{ hover: true, focus: true, touch: true }}
                          withArrow
                          multiline
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps(
                        "puiSurveyDto.superficieVerdeAggiunta"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("puiSurveyDto.noteAreeVerdi")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Paesaggio</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Alberi</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede nuove piantumazioni?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.piantumazioni === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.piantumazioni &&
                        form.setFieldValue(
                          "puiSurveyDto.numeroPiantumazioni",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.piantumazioni")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.piantumazioni === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      hideControls
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quante nuove piante vengono piantumate?"
                      {...form.getInputProps(
                        "puiSurveyDto.numeroPiantumazioni"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("puiSurveyDto.noteAlberi")}
                />
              </Grid.Col>
              <Grid.Col>
                <Title order={4}>Intervento architettonico - Occupazione</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Nuovi posti di lavoro</Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  placeholder="0"
                  label="Qual è l'incremento di forza lavoro previsto per l'avvio del cantiere?"
                  rightSection={
                    <Tooltip
                      label="Indicare il potenziale numero di occupati generati durante la fase di cantiere."
                      position="top-end"
                      events={{ hover: true, focus: true, touch: true }}
                      withArrow
                      multiline
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps("puiSurveyDto.forzaLavoro")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteNuoviPostiLavoroIntervento"
                  )}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4}>Residenze sociali - Abitare</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Housing affordability</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede interventi di housing?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.housing === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.housing &&
                        form.setFieldValue(
                          "puiSurveyDto.canoniLocazione",
                          undefined
                        );
                      form.values.puiSurveyDto?.housing &&
                        form.setFieldValue(
                          "puiSurveyDto.prezziCompravendita",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.housing")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.housing === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quali sono i canoni di locazione stabiliti per le unità abitative?"
                      rightSection={
                        <Tooltip
                          label="Se non disponibile scrivere in nota un'indicazione preliminare (in linea con canoni di mercato stato ottimo/normale, tariffe calmierate rispetto al mercato, ecc.)"
                          position="top-end"
                          withArrow
                          multiline
                          events={{ hover: true, focus: true, touch: true }}
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps("puiSurveyDto.canoniLocazione")}
                    />
                  </Grid.Col>
                  <Grid.Col sm={6}></Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quali sono i prezzi di compravendita stabili per le unità abitative?"
                      rightSection={
                        <Tooltip
                          label="Se non disponibile scrivere in nota un'indicazione preliminare (in linea con canoni di mercato stato ottimo/normale, tariffe calmierate rispetto al mercato, ecc.)"
                          position="top-end"
                          withArrow
                          multiline
                          events={{ hover: true, focus: true, touch: true }}
                        >
                          <div>
                            <IconAlertCircle
                              size="1rem"
                              style={{ display: "block", opacity: 0.5 }}
                            />
                          </div>
                        </Tooltip>
                      }
                      {...form.getInputProps(
                        "puiSurveyDto.prezziCompravendita"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteHousingAffordability"
                  )}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4}>Student - Abitare</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Copertura fuori sede</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede posti letto riservati a studenti?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.postiLetto === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.postiLetto &&
                        form.setFieldValue(
                          "puiSurveyDto.numeroPostiLetto",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.postiLetto")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.postiLetto === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      decimalSeparator=","
                      precision={2}
                      hideControls
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quanti posti letto sono riservati a studenti?"
                      {...form.getInputProps("puiSurveyDto.numeroPostiLetto")}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("puiSurveyDto.noteCoperturaFuoriSede")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4}>Servizi - Occupazione</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Nuovi esercizi commerciali</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede di dedicare parte della superficie a
                    spazi commerciali (negozi, servizi)?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.spaziCommerciali === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.spaziCommerciali &&
                        form.setFieldValue(
                          "puiSurveyDto.superficieVenditaSpaziCommerciali",
                          undefined
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.spaziCommerciali")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values?.puiSurveyDto?.spaziCommerciali === "true" && (
                <>
                  <Grid.Col xs={12} sm={6}>
                    <NumberInput
                      onKeyPress={(event) => {
                        if (!/^\d*[.,]?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      hideControls
                      decimalSeparator=","
                      precision={2}
                      disabled={hasSubmitted || isAdmin}
                      placeholder="0"
                      label="Quanta superficie di vendita è dedicata a spazi commerciali (negozi, servizi) nel progetto?"
                      {...form.getInputProps(
                        "puiSurveyDto.superficieVenditaSpaziCommerciali"
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}></Grid.Col>
                </>
              )}
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.noteEserciziCommerciali"
                  )}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4}>Mobilità - Attività economica</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>
                  Promozione mezzi di trasporto alternativi
                </Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede la promozione della mobilità
                    sostenibile?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.mobilitaSostenibile === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    onClick={() => {
                      form.values.puiSurveyDto?.mobilitaSostenibile &&
                        form.setFieldValue(
                          "puiSurveyDto.tipoModalitaSostenibile",
                          []
                        );
                    }}
                    {...form.getInputProps("puiSurveyDto.mobilitaSostenibile")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.mobilitaSostenibile === "true" && (
                <>
                  <Grid.Col span={11}>
                    <MultiSelect
                      disabled={hasSubmitted || isAdmin}
                      clearable
                      label="In che modo?"
                      placeholder="Seleziona tipologia interevento"
                      data={[
                        {
                          value: "1",
                          label:
                            "Convenzioni con TPL, società private di bici o monopattini",
                        },
                        { value: "2", label: "Dotazione di depositi bici" },
                        {
                          value: "3",
                          label: "Nuove fermate nei pressi dell'iniziativa",
                        },
                        {
                          value: "4",
                          label: "Colonnine di ricarica per veicoli elettrici",
                        },
                        {
                          value: "5",
                          label: "Interventi architettonici specifici",
                        },
                        { value: "6", label: "Tratti di piste ciclabili" },
                      ]}
                      {...form.getInputProps(
                        "puiSurveyDto.tipoModalitaSostenibile"
                      )}
                      sx={{
                        maxWidth: 840,
                        "@media (max-width: 51.27em)": {
                          maxWidth: "90%",
                        },
                      }}
                    />
                  </Grid.Col>
                  <Tooltip
                    label="É possibile selezionare più di una risposta multipla. "
                    position="bottom"
                    withArrow
                    multiline
                    events={{ hover: true, focus: true, touch: true }}
                  >
                    <div>
                      <IconAlertCircle
                        size="1rem"
                        style={{
                          display: "block",
                          opacity: 0.5,
                          marginTop: 42,
                        }}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  decimalSeparator=","
                  precision={2}
                  disabled={hasSubmitted || isAdmin}
                  placeholder="0"
                  label="Se hai informazioni quantitative, riporta i valori numerici relativi agli interventi che promuoveranno una mobilità sostenibile"
                  rightSection={
                    <Tooltip
                      multiline
                      label="Inserire i valori numerici per ogni risposta multipla selezionata. "
                      position="top-end"
                      withArrow
                      events={{ hover: true, focus: true, touch: true }}
                    >
                      <div>
                        <IconAlertCircle
                          size="1rem"
                          style={{ display: "block", opacity: 0.5 }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {...form.getInputProps(
                    "puiSurveyDto.numeroInterventoSostenibile"
                  )}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps(
                    "puiSurveyDto.notePromozioneMezziTrasportoAlternativi"
                  )}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <Title order={4}>Digitalizzazione - Qualità dell'aria</Title>
              </Grid.Col>
              <Grid.Col>
                <Title order={5}>Emissioni di CO2</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Text fw={600} size={16}>
                    Il progetto prevede smart solutions per il risparmio
                    energetico degli alloggi?
                  </Text>
                  <SegmentedControl
                    radius={"lg"}
                    disabled={hasSubmitted || isAdmin}
                    defaultChecked={
                      form.values?.puiSurveyDto?.smartSolution === "true"
                    }
                    defaultValue=""
                    data={[
                      { label: "NO", value: "false" },
                      { label: "-", value: "" },
                      { label: "SI", value: "true" },
                    ]}
                    {...form.getInputProps("puiSurveyDto.smartSolution")}
                  ></SegmentedControl>
                </Group>
              </Grid.Col>
              {form.values.puiSurveyDto?.smartSolution === "true" && (
                <Grid.Col xs={12} sm={6}>
                  <TextInput
                    disabled={hasSubmitted || isAdmin}
                    label="Quali tipi di smart solutions per il risparmio energetico degli alloggi sono previsti?"
                    placeholder="Tipi di smart solutions"
                    {...form.getInputProps("puiSurveyDto.tipoSmartSolution")}
                  />
                </Grid.Col>
              )}
              <Grid.Col xs={12} sm={6}>
                <NumberInput
                  onKeyPress={(event) => {
                    if (!/^\d*[.,]?\d*$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  hideControls
                  decimalSeparator=","
                  disabled={hasSubmitted || isAdmin}
                  precision={2}
                  placeholder="0"
                  label={`Se hai informazioni quantitative, riporta i valori numerici relativi agli interventi che promuoveranno smart solutions`}
                  {...form.getInputProps(
                    "puiSurveyDto.numeroInterventiSmartSolution"
                  )}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}></Grid.Col>
              <Grid.Col>
                <Textarea
                  disabled={hasSubmitted || isAdmin}
                  label="Note"
                  placeholder="Se i dati non sono definitivi, inserire un resoconto che descriva l'obiettivo che si intende raggiungere su questo indicatore."
                  {...form.getInputProps("puiSurveyDto.noteSmartSolutions")}
                />
              </Grid.Col>
            </Grid>
          </Stepper.Step>
          <Stepper.Step
            label="Allegati"
            sx={sxStepperStepStyles({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            icon={iconStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            color={colorStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
            completedIcon={completedIconStepperStep({
              activeNumber: 4,
              condition: !checkAllegati,
            })}
          >
            <SurveyAllegati
              orgid={isAdmin ? organizationId : orgId}
              calltype="PUI"
              disabled={hasSubmitted || isAdmin}
              onclick={() => handleAllegati()}
              statusPUI={statusPUI}
            />
          </Stepper.Step>
          {isAdmin
            ? null
            : !hasSubmitted && (
                <Stepper.Step label="Invia Candidatura">
                  <LoadingOverlay visible={visible} overlayBlur={2} />
                </Stepper.Step>
              )}
          <Stepper.Completed>
            <SurveyCompleted />
          </Stepper.Completed>
        </Stepper>
        <BottomNote show={active === 5} />
        <Group position="center" mt={32}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Indietro
            </Button>
          )}
          {active === 4 && isAdmin ? (
            <Button onClick={() => navigate("/admin-panel")}>
              Torna alla lista
            </Button>
          ) : active === 4 && hasSubmitted ? (
            <Button onClick={() => navigate("/calls")}>Torna ai fondi</Button>
          ) : (
            active !== 5 && <Button onClick={nextStep}>Avanti</Button>
          )}
          {active === 5 && (
            <Button
              disabled={
                hasSubmitted ||
                isAdmin ||
                progettoConditions ||
                costiConditions ||
                tempisticheConditions ||
                dateExceeded ||
                !checkAllegati
              }
              type="submit"
              className="btn-primary"
            >
              {t("components.forms.register-organization.submit", "Submit")}
            </Button>
          )}
        </Group>
      </form>
    </Paper>
  );
}

const BottomNote = ({ show }: { show: boolean }) => {
  if (!show) return null;
  return (
    <Group position="center" mt={22}>
      <Text
        sx={(theme) => ({
          fontSize: theme.fontSizes.md,
          "@media (max-width: 51.27em)": {
            fontSize: theme.fontSizes.xs,
          },
        })}
      >
        Tutti i campi con <span style={{ color: "red" }}>*</span> devono essere
        compilati per poter inviare la candidatura
      </Text>
    </Group>
  );
};

export default SurveyPui;
