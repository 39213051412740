/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Bei-tool
 * OpenAPI spec version: v1
 */
import useSwr from 'swr'
import type {
  SWRConfiguration,
  Key
} from 'swr'
import type {
  CBAnagrafica,
  CBPartecipazioni,
  CBFinanziamenti,
  CallDto,
  GetApiCallGetCallByIdParams,
  CallResponse,
  CallType,
  CreateCallDto,
  CallDtoBody,
  PostApiCallDeleteCallParams,
  SubmissionResponse,
  CreateSubmissionDtoBody,
  StepResult,
  CallStepResultDto,
  GetApiCallGetSubmissionByIDParams,
  StepResultResponse,
  GetApiCallGetStepResultParams,
  CloseCallSubmissionsDTO,
  PostApiCallCloseCallSubmissionParams,
  CreateCallTypeDtoBody,
  CreateCallTypeDto,
  GetApiCallGetCallTypeParams,
  CallSubmission,
  BodyBody,
  PostApiCallSubmissionReOpenSubmissionParams,
  PostApiCallSubmissionDeleteSubmissionParams,
  CompilazioneDraftDto,
  PuiCompilazioneDto,
  GetApiDraftCompilazioneGetPuiDraftParams,
  TurCompilazioneDto,
  GetApiDraftCompilazioneGetTurDraftParams,
  GetApiDraftCompilazioneHasSubmittedTurParams,
  GetApiDraftCompilazioneHasSubmittedPuiParams,
  GetApiDraftCompilazioneGetSurveyfromJsonParams,
  GetExcelCompilazioniRequest,
  UploadFileResultDTO,
  PostApiFileUploadUploadFileBody,
  GetApiFileUploadGetFileParams,
  Attachment,
  AttachmentTypeResultDTO,
  GetApiFileUploadGetAttachmentTypesParams,
  AttachmentResultDTO,
  GetApiFileUploadGetAttachmentIntegrationsParams,
  GetApiFileUploadGetIstruttoriaGresbAttachmentTypesParams,
  GetApiFileUploadGetFileUrlParams,
  GetApiFileUploadRemoveAttachementParams,
  GetApiFileUploadFileUploadValidationTurParams,
  GetApiFileUploadFileUploadValidationPuiParams,
  GetApiFileUploadFilesUploadIstGresbPuiValidationParams,
  GetApiFileUploadFilesUploadIstGresbTurValidationParams,
  GetApiFileUploadGetZippedFilesParams,
  SaveStatusDTO,
  MiniDyiReqDTO,
  MiniDyiRespDTO,
  GetApiImpactFrameworkMiniDYIParams,
  SdgDTO,
  OutcomeTypeRequestBody,
  OutcomeRequest,
  StakeholderType,
  IndicatorTypeDTO,
  PostApiJwtTokenExchangeBodyOne,
  PostApiJwtTokenExchangeBodyTwo,
  PostApiJwtTokenExchangeBodyThree,
  MapsResponseDto,
  PostApiMapsGetmapResultParams,
  GetApiMonitoraggioShowMonitoraggioTurParams,
  GetApiMonitoraggioShowMonitoraggioPuiParams,
  CreateOrgDtoBody,
  OrganizationDto,
  GetApiOrganizationGetOrganizationByIdParams,
  PostApiOrganizationDeleteOrganizationParams,
  PostApiOrganizationGetPIvaParams,
  UserDto,
  GetApiOrganizationGetOrganizationUsersParams,
  OrgCompilazioneListDTO,
  OutcomeTypeDTO,
  EmbedParams,
  GetApiPBITokensParams,
  PowerBI,
  GetApiPowerBiAvailableParams,
  PuiResponseDto,
  GetApiPuiGetAllPuiFromCityParams,
  PuiObbietiviRespDto,
  GetApiPuiGetPuiObiettiviParams,
  GetApiPuiGetPuiObiettiviSpecificiParams,
  GetApiPuiHasSubmittedPuiParams,
  ObiettiviGenerali,
  GetApiPuiGetPuiObiettiviGeneraliParams,
  PostApiPuiUpdatePuiCompilazioneStatusParams,
  GetApiPuiGetPuiCompilazioneParams,
  LogEmailDto,
  SJSurveyType,
  SJSurveyTypeDTO,
  SJSurvey,
  CreateSurveyRequestDto,
  SJSurveyJson,
  CreateSurveyJsonRequestDto,
  SJSurveySubmission,
  SurveySubmissionRequestDtoBody,
  SJAnswers,
  CreateAnswersDto,
  PostApiSJSurveyUpdateSurveyJsonParams,
  SJQuestionsExtended,
  PostApiSJSurveyConsolidateQuestionsParams,
  PostApiSJSurveyCloseSurveySubmissionParams,
  PostApiSJSurveyOpenSurveySubmissionParams,
  PostApiSJSurveyUpdateSurveyJsonStructParams,
  GetSurveyDto,
  GetApiSJSurveyGetLastSurveyJSONParams,
  GetApiSJSurveyGetSurveyJsonByIdParams,
  GetApiSJSurveyGetSurveyAnswerJsonByIdParams,
  Test,
  PostApiSJSurveyUpdateSurveyJsonByIdParams,
  JSONContent,
  GetApiSJSurveyGetSurveyJsonBySubmissionParams,
  GetApiSJSurveyGetSubmissionParams,
  SJSurveyInstance,
  GetApiSJSurveyGetSurveyBySubmissionIDParams,
  GetApiSJSurveyGetSurveyByTypeParams,
  GetApiSJSurveyGetSurveySubmssionbySurveyIdParams,
  GetApiSJSurveyGetSurveyJsonBySurveyIDParams,
  GetApiSJSurveyGetSurveyJsonStructBySurveyIDParams,
  GetApiSJSurveyGetAnswersBySubmissionIdPuiParams,
  GetApiSJSurveyGetAnswersBySubmissionIdTurParams,
  Step,
  GetApiStepGetStepByIdParams,
  GetApiStepGetStepsParams,
  StepType,
  CreateStepDtoBody,
  GetApiStepUpdateSJQuestionsExtendedParams,
  CreateStepTypeDto,
  TurCompilazioneDtoBody,
  PostApiTurCreateUpdateTurAdminParams,
  PostApiTurUpdateTurCompilazioneStatusParams,
  GetApiTurGetTurCompilazioneParams,
  TypologyResponseDto,
  GetApiTypologyGetComuneFromCityParams,
  GetApiTypologyGetRegionFromCityParams,
  GetApiTypologyGetComunefromProvParams,
  GetApiTypologyGetprovfromRegionParams
} from '../model'
import { customInstance } from '../axios-instance'
import type { ErrorType } from '../axios-instance'


  
  export const getApiCabelGetCBANA = (
    
 ) => {
      return customInstance<CBAnagrafica>(
      {url: `/api/Cabel/GetCB_ANA`, method: 'get'
    },
      );
    }
  

export const getGetApiCabelGetCBANAKey = () => [`/api/Cabel/GetCB_ANA`];

    
export type GetApiCabelGetCBANAQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCabelGetCBANA>>>
export type GetApiCabelGetCBANAQueryError = ErrorType<unknown>

export const useGetApiCabelGetCBANA = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCabelGetCBANA>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCabelGetCBANAKey() : null);
  const swrFn = () => getApiCabelGetCBANA();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCabelGetCBPAR = (
    
 ) => {
      return customInstance<CBPartecipazioni>(
      {url: `/api/Cabel/GetCB_PAR`, method: 'get'
    },
      );
    }
  

export const getGetApiCabelGetCBPARKey = () => [`/api/Cabel/GetCB_PAR`];

    
export type GetApiCabelGetCBPARQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCabelGetCBPAR>>>
export type GetApiCabelGetCBPARQueryError = ErrorType<unknown>

export const useGetApiCabelGetCBPAR = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCabelGetCBPAR>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCabelGetCBPARKey() : null);
  const swrFn = () => getApiCabelGetCBPAR();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCabelGetCBFIN = (
    
 ) => {
      return customInstance<CBFinanziamenti>(
      {url: `/api/Cabel/GetCB_FIN`, method: 'get'
    },
      );
    }
  

export const getGetApiCabelGetCBFINKey = () => [`/api/Cabel/GetCB_FIN`];

    
export type GetApiCabelGetCBFINQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCabelGetCBFIN>>>
export type GetApiCabelGetCBFINQueryError = ErrorType<unknown>

export const useGetApiCabelGetCBFIN = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCabelGetCBFIN>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCabelGetCBFINKey() : null);
  const swrFn = () => getApiCabelGetCBFIN();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCabelDownloadExcelCabel = (
    
 ) => {
      return customInstance<void>(
      {url: `/api/Cabel/DownloadExcelCabel`, method: 'get'
    },
      );
    }
  

export const getGetApiCabelDownloadExcelCabelKey = () => [`/api/Cabel/DownloadExcelCabel`];

    
export type GetApiCabelDownloadExcelCabelQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCabelDownloadExcelCabel>>>
export type GetApiCabelDownloadExcelCabelQueryError = ErrorType<unknown>

export const useGetApiCabelDownloadExcelCabel = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCabelDownloadExcelCabel>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCabelDownloadExcelCabelKey() : null);
  const swrFn = () => getApiCabelDownloadExcelCabel();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallById = (
    params?: GetApiCallGetCallByIdParams,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/GetCallById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetCallByIdKey = (params?: GetApiCallGetCallByIdParams,) => [`/api/Call/GetCallById`, ...(params ? [params]: [])];

    
export type GetApiCallGetCallByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallById>>>
export type GetApiCallGetCallByIdQueryError = ErrorType<unknown>

export const useGetApiCallGetCallById = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetCallByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallByIdKey(params) : null);
  const swrFn = () => getApiCallGetCallById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallListUser = (
    
 ) => {
      return customInstance<CallResponse[]>(
      {url: `/api/Call/GetCallListUser`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallListUserKey = () => [`/api/Call/GetCallListUser`];

    
export type GetApiCallGetCallListUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallListUser>>>
export type GetApiCallGetCallListUserQueryError = ErrorType<unknown>

export const useGetApiCallGetCallListUser = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallListUser>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallListUserKey() : null);
  const swrFn = () => getApiCallGetCallListUser();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallList = (
    
 ) => {
      return customInstance<CallDto[]>(
      {url: `/api/Call/GetCallList`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallListKey = () => [`/api/Call/GetCallList`];

    
export type GetApiCallGetCallListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallList>>>
export type GetApiCallGetCallListQueryError = ErrorType<unknown>

export const useGetApiCallGetCallList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallListKey() : null);
  const swrFn = () => getApiCallGetCallList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallTypes = (
    
 ) => {
      return customInstance<CallType[]>(
      {url: `/api/Call/GetCallTypes`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallTypesKey = () => [`/api/Call/GetCallTypes`];

    
export type GetApiCallGetCallTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallTypes>>>
export type GetApiCallGetCallTypesQueryError = ErrorType<unknown>

export const useGetApiCallGetCallTypes = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallTypesKey() : null);
  const swrFn = () => getApiCallGetCallTypes();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallCreateCall = (
    createCallDto: CreateCallDto,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/CreateCall`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCallDto
    },
      );
    }
  


export const putApiCallUpdateCall = (
    callDtoBody: CallDtoBody,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/UpdateCall`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: callDtoBody
    },
      );
    }
  


export const putApiCallUpsertCall = (
    callDtoBody: CallDtoBody,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/UpsertCall`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: callDtoBody
    },
      );
    }
  


export const postApiCallDeleteCall = (
    params?: PostApiCallDeleteCallParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Call/DeleteCall`, method: 'post',
        params
    },
      );
    }
  


export const postApiCallUpsertCallSubmission = (
    createSubmissionDtoBody: CreateSubmissionDtoBody,
 ) => {
      return customInstance<SubmissionResponse>(
      {url: `/api/Call/UpsertCallSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubmissionDtoBody
    },
      );
    }
  


export const postApiCallUpsertCallStepResult = (
    callStepResultDto: CallStepResultDto,
 ) => {
      return customInstance<StepResult>(
      {url: `/api/Call/UpsertCallStepResult`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: callStepResultDto
    },
      );
    }
  


export const getApiCallGetSubmissionByID = (
    params?: GetApiCallGetSubmissionByIDParams,
 ) => {
      return customInstance<SubmissionResponse>(
      {url: `/api/Call/GetSubmissionByID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetSubmissionByIDKey = (params?: GetApiCallGetSubmissionByIDParams,) => [`/api/Call/GetSubmissionByID`, ...(params ? [params]: [])];

    
export type GetApiCallGetSubmissionByIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetSubmissionByID>>>
export type GetApiCallGetSubmissionByIDQueryError = ErrorType<unknown>

export const useGetApiCallGetSubmissionByID = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetSubmissionByIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetSubmissionByID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetSubmissionByIDKey(params) : null);
  const swrFn = () => getApiCallGetSubmissionByID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetStepResult = (
    params?: GetApiCallGetStepResultParams,
 ) => {
      return customInstance<StepResultResponse>(
      {url: `/api/Call/GetStepResult`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetStepResultKey = (params?: GetApiCallGetStepResultParams,) => [`/api/Call/GetStepResult`, ...(params ? [params]: [])];

    
export type GetApiCallGetStepResultQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetStepResult>>>
export type GetApiCallGetStepResultQueryError = ErrorType<unknown>

export const useGetApiCallGetStepResult = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetStepResultParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetStepResult>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetStepResultKey(params) : null);
  const swrFn = () => getApiCallGetStepResult(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetSubmissions = (
    
 ) => {
      return customInstance<SubmissionResponse[]>(
      {url: `/api/Call/GetSubmissions`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetSubmissionsKey = () => [`/api/Call/GetSubmissions`];

    
export type GetApiCallGetSubmissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetSubmissions>>>
export type GetApiCallGetSubmissionsQueryError = ErrorType<unknown>

export const useGetApiCallGetSubmissions = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetSubmissions>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetSubmissionsKey() : null);
  const swrFn = () => getApiCallGetSubmissions();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallCloseCallSubmission = (
    params?: PostApiCallCloseCallSubmissionParams,
 ) => {
      return customInstance<CloseCallSubmissionsDTO[]>(
      {url: `/api/Call/CloseCallSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiCallCreateCallType = (
    createCallTypeDtoBody: CreateCallTypeDtoBody,
 ) => {
      return customInstance<CallType>(
      {url: `/api/Call/CreateCallType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCallTypeDtoBody
    },
      );
    }
  


export const putApiCallUpsertCallType = (
    createCallTypeDtoBody: CreateCallTypeDtoBody,
 ) => {
      return customInstance<CallType>(
      {url: `/api/Call/UpsertCallType`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: createCallTypeDtoBody
    },
      );
    }
  


export const getApiCallGetCallType = (
    params?: GetApiCallGetCallTypeParams,
 ) => {
      return customInstance<CreateCallTypeDto>(
      {url: `/api/Call/GetCallType`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetCallTypeKey = (params?: GetApiCallGetCallTypeParams,) => [`/api/Call/GetCallType`, ...(params ? [params]: [])];

    
export type GetApiCallGetCallTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallType>>>
export type GetApiCallGetCallTypeQueryError = ErrorType<unknown>

export const useGetApiCallGetCallType = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetCallTypeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallTypeKey(params) : null);
  const swrFn = () => getApiCallGetCallType(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallSubmissionCreateSubmission = (
    createSubmissionDtoBody: CreateSubmissionDtoBody,
 ) => {
      return customInstance<CallSubmission>(
      {url: `/api/CallSubmission/CreateSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubmissionDtoBody
    },
      );
    }
  


export const postApiCallSubmissionExportExcelSubmission = (
    bodyBody: BodyBody,
 ) => {
      return customInstance<void>(
      {url: `/api/CallSubmission/ExportExcelSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bodyBody
    },
      );
    }
  


export const postApiCallSubmissionReOpenSubmission = (
    params?: PostApiCallSubmissionReOpenSubmissionParams,
 ) => {
      return customInstance<void>(
      {url: `/api/CallSubmission/ReOpenSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiCallSubmissionDeleteSubmission = (
    params?: PostApiCallSubmissionDeleteSubmissionParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/CallSubmission/DeleteSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiDraftCompilazioneCreateDraftCompilazione = (
    compilazioneDraftDto: CompilazioneDraftDto,
 ) => {
      return customInstance<CompilazioneDraftDto>(
      {url: `/api/DraftCompilazione/CreateDraftCompilazione`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: compilazioneDraftDto
    },
      );
    }
  


export const getApiDraftCompilazioneGetPuiDraft = (
    params?: GetApiDraftCompilazioneGetPuiDraftParams,
 ) => {
      return customInstance<PuiCompilazioneDto>(
      {url: `/api/DraftCompilazione/GetPuiDraft`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiDraftCompilazioneGetPuiDraftKey = (params?: GetApiDraftCompilazioneGetPuiDraftParams,) => [`/api/DraftCompilazione/GetPuiDraft`, ...(params ? [params]: [])];

    
export type GetApiDraftCompilazioneGetPuiDraftQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDraftCompilazioneGetPuiDraft>>>
export type GetApiDraftCompilazioneGetPuiDraftQueryError = ErrorType<unknown>

export const useGetApiDraftCompilazioneGetPuiDraft = <TError = ErrorType<unknown>>(
 params?: GetApiDraftCompilazioneGetPuiDraftParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiDraftCompilazioneGetPuiDraft>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiDraftCompilazioneGetPuiDraftKey(params) : null);
  const swrFn = () => getApiDraftCompilazioneGetPuiDraft(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiDraftCompilazioneGetTurDraft = (
    params?: GetApiDraftCompilazioneGetTurDraftParams,
 ) => {
      return customInstance<TurCompilazioneDto>(
      {url: `/api/DraftCompilazione/GetTurDraft`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiDraftCompilazioneGetTurDraftKey = (params?: GetApiDraftCompilazioneGetTurDraftParams,) => [`/api/DraftCompilazione/GetTurDraft`, ...(params ? [params]: [])];

    
export type GetApiDraftCompilazioneGetTurDraftQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDraftCompilazioneGetTurDraft>>>
export type GetApiDraftCompilazioneGetTurDraftQueryError = ErrorType<unknown>

export const useGetApiDraftCompilazioneGetTurDraft = <TError = ErrorType<unknown>>(
 params?: GetApiDraftCompilazioneGetTurDraftParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiDraftCompilazioneGetTurDraft>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiDraftCompilazioneGetTurDraftKey(params) : null);
  const swrFn = () => getApiDraftCompilazioneGetTurDraft(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiDraftCompilazioneHasSubmittedTur = (
    params?: GetApiDraftCompilazioneHasSubmittedTurParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/DraftCompilazione/HasSubmittedTur`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiDraftCompilazioneHasSubmittedTurKey = (params?: GetApiDraftCompilazioneHasSubmittedTurParams,) => [`/api/DraftCompilazione/HasSubmittedTur`, ...(params ? [params]: [])];

    
export type GetApiDraftCompilazioneHasSubmittedTurQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDraftCompilazioneHasSubmittedTur>>>
export type GetApiDraftCompilazioneHasSubmittedTurQueryError = ErrorType<unknown>

export const useGetApiDraftCompilazioneHasSubmittedTur = <TError = ErrorType<unknown>>(
 params?: GetApiDraftCompilazioneHasSubmittedTurParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiDraftCompilazioneHasSubmittedTur>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiDraftCompilazioneHasSubmittedTurKey(params) : null);
  const swrFn = () => getApiDraftCompilazioneHasSubmittedTur(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiDraftCompilazioneHasSubmittedPui = (
    params?: GetApiDraftCompilazioneHasSubmittedPuiParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/DraftCompilazione/HasSubmittedPui`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiDraftCompilazioneHasSubmittedPuiKey = (params?: GetApiDraftCompilazioneHasSubmittedPuiParams,) => [`/api/DraftCompilazione/HasSubmittedPui`, ...(params ? [params]: [])];

    
export type GetApiDraftCompilazioneHasSubmittedPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDraftCompilazioneHasSubmittedPui>>>
export type GetApiDraftCompilazioneHasSubmittedPuiQueryError = ErrorType<unknown>

export const useGetApiDraftCompilazioneHasSubmittedPui = <TError = ErrorType<unknown>>(
 params?: GetApiDraftCompilazioneHasSubmittedPuiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiDraftCompilazioneHasSubmittedPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiDraftCompilazioneHasSubmittedPuiKey(params) : null);
  const swrFn = () => getApiDraftCompilazioneHasSubmittedPui(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiDraftCompilazioneGetSurveyfromJson = (
    params?: GetApiDraftCompilazioneGetSurveyfromJsonParams,
 ) => {
      return customInstance<string>(
      {url: `/api/DraftCompilazione/GetSurveyfromJson`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiDraftCompilazioneGetSurveyfromJsonKey = (params?: GetApiDraftCompilazioneGetSurveyfromJsonParams,) => [`/api/DraftCompilazione/GetSurveyfromJson`, ...(params ? [params]: [])];

    
export type GetApiDraftCompilazioneGetSurveyfromJsonQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDraftCompilazioneGetSurveyfromJson>>>
export type GetApiDraftCompilazioneGetSurveyfromJsonQueryError = ErrorType<unknown>

export const useGetApiDraftCompilazioneGetSurveyfromJson = <TError = ErrorType<unknown>>(
 params?: GetApiDraftCompilazioneGetSurveyfromJsonParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiDraftCompilazioneGetSurveyfromJson>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiDraftCompilazioneGetSurveyfromJsonKey(params) : null);
  const swrFn = () => getApiDraftCompilazioneGetSurveyfromJson(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiDraftCompilazioneDownloadExcelCompilazioni = (
    getExcelCompilazioniRequest: GetExcelCompilazioniRequest,
 ) => {
      return customInstance<void>(
      {url: `/api/DraftCompilazione/DownloadExcelCompilazioni`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getExcelCompilazioniRequest
    },
      );
    }
  


export const postApiFileUploadUploadFile = (
    postApiFileUploadUploadFileBody: PostApiFileUploadUploadFileBody,
 ) => {const formData = new FormData();
formData.append('File', postApiFileUploadUploadFileBody.File)
formData.append('Nome', postApiFileUploadUploadFileBody.Nome)
formData.append('Descrizione', postApiFileUploadUploadFileBody.Descrizione)
formData.append('TipoFileId', postApiFileUploadUploadFileBody.TipoFileId)
formData.append('OrgId', postApiFileUploadUploadFileBody.OrgId)
formData.append('CallType', postApiFileUploadUploadFileBody.CallType)

      return customInstance<UploadFileResultDTO>(
      {url: `/api/FileUpload/UploadFile`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getApiFileUploadGetFile = (
    params?: GetApiFileUploadGetFileParams,
 ) => {
      return customInstance<void>(
      {url: `/api/FileUpload/GetFile`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetFileKey = (params?: GetApiFileUploadGetFileParams,) => [`/api/FileUpload/GetFile`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetFileQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetFile>>>
export type GetApiFileUploadGetFileQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetFile = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetFileParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetFile>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetFileKey(params) : null);
  const swrFn = () => getApiFileUploadGetFile(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetAttachments = (
    
 ) => {
      return customInstance<Attachment[]>(
      {url: `/api/FileUpload/GetAttachments`, method: 'get'
    },
      );
    }
  

export const getGetApiFileUploadGetAttachmentsKey = () => [`/api/FileUpload/GetAttachments`];

    
export type GetApiFileUploadGetAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetAttachments>>>
export type GetApiFileUploadGetAttachmentsQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetAttachments = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetAttachments>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetAttachmentsKey() : null);
  const swrFn = () => getApiFileUploadGetAttachments();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetAttachmentTypes = (
    params?: GetApiFileUploadGetAttachmentTypesParams,
 ) => {
      return customInstance<AttachmentTypeResultDTO[]>(
      {url: `/api/FileUpload/GetAttachmentTypes`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetAttachmentTypesKey = (params?: GetApiFileUploadGetAttachmentTypesParams,) => [`/api/FileUpload/GetAttachmentTypes`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetAttachmentTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentTypes>>>
export type GetApiFileUploadGetAttachmentTypesQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetAttachmentTypes = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetAttachmentTypesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetAttachmentTypesKey(params) : null);
  const swrFn = () => getApiFileUploadGetAttachmentTypes(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetAttachmentIntegrations = (
    params?: GetApiFileUploadGetAttachmentIntegrationsParams,
 ) => {
      return customInstance<AttachmentResultDTO[]>(
      {url: `/api/FileUpload/GetAttachmentIntegrations`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetAttachmentIntegrationsKey = (params?: GetApiFileUploadGetAttachmentIntegrationsParams,) => [`/api/FileUpload/GetAttachmentIntegrations`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetAttachmentIntegrationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentIntegrations>>>
export type GetApiFileUploadGetAttachmentIntegrationsQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetAttachmentIntegrations = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetAttachmentIntegrationsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentIntegrations>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetAttachmentIntegrationsKey(params) : null);
  const swrFn = () => getApiFileUploadGetAttachmentIntegrations(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetIstruttoriaGresbAttachmentTypes = (
    params?: GetApiFileUploadGetIstruttoriaGresbAttachmentTypesParams,
 ) => {
      return customInstance<AttachmentTypeResultDTO[]>(
      {url: `/api/FileUpload/GetIstruttoriaGresbAttachmentTypes`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey = (params?: GetApiFileUploadGetIstruttoriaGresbAttachmentTypesParams,) => [`/api/FileUpload/GetIstruttoriaGresbAttachmentTypes`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetIstruttoriaGresbAttachmentTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetIstruttoriaGresbAttachmentTypes>>>
export type GetApiFileUploadGetIstruttoriaGresbAttachmentTypesQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetIstruttoriaGresbAttachmentTypes = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetIstruttoriaGresbAttachmentTypesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetIstruttoriaGresbAttachmentTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetIstruttoriaGresbAttachmentTypesKey(params) : null);
  const swrFn = () => getApiFileUploadGetIstruttoriaGresbAttachmentTypes(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetFileUrl = (
    params?: GetApiFileUploadGetFileUrlParams,
 ) => {
      return customInstance<string>(
      {url: `/api/FileUpload/GetFileUrl`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetFileUrlKey = (params?: GetApiFileUploadGetFileUrlParams,) => [`/api/FileUpload/GetFileUrl`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetFileUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetFileUrl>>>
export type GetApiFileUploadGetFileUrlQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetFileUrl = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetFileUrlParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetFileUrl>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetFileUrlKey(params) : null);
  const swrFn = () => getApiFileUploadGetFileUrl(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadRemoveAttachement = (
    params?: GetApiFileUploadRemoveAttachementParams,
 ) => {
      return customInstance<void>(
      {url: `/api/FileUpload/RemoveAttachement`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadRemoveAttachementKey = (params?: GetApiFileUploadRemoveAttachementParams,) => [`/api/FileUpload/RemoveAttachement`, ...(params ? [params]: [])];

    
export type GetApiFileUploadRemoveAttachementQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadRemoveAttachement>>>
export type GetApiFileUploadRemoveAttachementQueryError = ErrorType<unknown>

export const useGetApiFileUploadRemoveAttachement = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadRemoveAttachementParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadRemoveAttachement>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadRemoveAttachementKey(params) : null);
  const swrFn = () => getApiFileUploadRemoveAttachement(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadFileUploadValidationTur = (
    params?: GetApiFileUploadFileUploadValidationTurParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/FileUpload/FileUploadValidationTur`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadFileUploadValidationTurKey = (params?: GetApiFileUploadFileUploadValidationTurParams,) => [`/api/FileUpload/FileUploadValidationTur`, ...(params ? [params]: [])];

    
export type GetApiFileUploadFileUploadValidationTurQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadFileUploadValidationTur>>>
export type GetApiFileUploadFileUploadValidationTurQueryError = ErrorType<unknown>

export const useGetApiFileUploadFileUploadValidationTur = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadFileUploadValidationTurParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadFileUploadValidationTur>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadFileUploadValidationTurKey(params) : null);
  const swrFn = () => getApiFileUploadFileUploadValidationTur(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadFileUploadValidationPui = (
    params?: GetApiFileUploadFileUploadValidationPuiParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/FileUpload/FileUploadValidationPui`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadFileUploadValidationPuiKey = (params?: GetApiFileUploadFileUploadValidationPuiParams,) => [`/api/FileUpload/FileUploadValidationPui`, ...(params ? [params]: [])];

    
export type GetApiFileUploadFileUploadValidationPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadFileUploadValidationPui>>>
export type GetApiFileUploadFileUploadValidationPuiQueryError = ErrorType<unknown>

export const useGetApiFileUploadFileUploadValidationPui = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadFileUploadValidationPuiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadFileUploadValidationPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadFileUploadValidationPuiKey(params) : null);
  const swrFn = () => getApiFileUploadFileUploadValidationPui(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadFilesUploadIstGresbPuiValidation = (
    params?: GetApiFileUploadFilesUploadIstGresbPuiValidationParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/FileUpload/FilesUploadIstGresbPuiValidation`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadFilesUploadIstGresbPuiValidationKey = (params?: GetApiFileUploadFilesUploadIstGresbPuiValidationParams,) => [`/api/FileUpload/FilesUploadIstGresbPuiValidation`, ...(params ? [params]: [])];

    
export type GetApiFileUploadFilesUploadIstGresbPuiValidationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadFilesUploadIstGresbPuiValidation>>>
export type GetApiFileUploadFilesUploadIstGresbPuiValidationQueryError = ErrorType<unknown>

export const useGetApiFileUploadFilesUploadIstGresbPuiValidation = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadFilesUploadIstGresbPuiValidationParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadFilesUploadIstGresbPuiValidation>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadFilesUploadIstGresbPuiValidationKey(params) : null);
  const swrFn = () => getApiFileUploadFilesUploadIstGresbPuiValidation(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadFilesUploadIstGresbTurValidation = (
    params?: GetApiFileUploadFilesUploadIstGresbTurValidationParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/FileUpload/FilesUploadIstGresbTurValidation`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadFilesUploadIstGresbTurValidationKey = (params?: GetApiFileUploadFilesUploadIstGresbTurValidationParams,) => [`/api/FileUpload/FilesUploadIstGresbTurValidation`, ...(params ? [params]: [])];

    
export type GetApiFileUploadFilesUploadIstGresbTurValidationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadFilesUploadIstGresbTurValidation>>>
export type GetApiFileUploadFilesUploadIstGresbTurValidationQueryError = ErrorType<unknown>

export const useGetApiFileUploadFilesUploadIstGresbTurValidation = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadFilesUploadIstGresbTurValidationParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadFilesUploadIstGresbTurValidation>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadFilesUploadIstGresbTurValidationKey(params) : null);
  const swrFn = () => getApiFileUploadFilesUploadIstGresbTurValidation(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetZippedFiles = (
    params?: GetApiFileUploadGetZippedFilesParams,
 ) => {
      return customInstance<void>(
      {url: `/api/FileUpload/GetZippedFiles`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetZippedFilesKey = (params?: GetApiFileUploadGetZippedFilesParams,) => [`/api/FileUpload/GetZippedFiles`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetZippedFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetZippedFiles>>>
export type GetApiFileUploadGetZippedFilesQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetZippedFiles = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetZippedFilesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetZippedFiles>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetZippedFilesKey(params) : null);
  const swrFn = () => getApiFileUploadGetZippedFiles(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const putApiImpactFrameworkPutMiniDYI = (
    miniDyiReqDTO: MiniDyiReqDTO,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/ImpactFramework/PutMiniDYI`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: miniDyiReqDTO
    },
      );
    }
  


export const getApiImpactFrameworkMiniDYI = (
    params?: GetApiImpactFrameworkMiniDYIParams,
 ) => {
      return customInstance<MiniDyiRespDTO>(
      {url: `/api/ImpactFramework/MiniDYI`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiImpactFrameworkMiniDYIKey = (params?: GetApiImpactFrameworkMiniDYIParams,) => [`/api/ImpactFramework/MiniDYI`, ...(params ? [params]: [])];

    
export type GetApiImpactFrameworkMiniDYIQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkMiniDYI>>>
export type GetApiImpactFrameworkMiniDYIQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkMiniDYI = <TError = ErrorType<unknown>>(
 params?: GetApiImpactFrameworkMiniDYIParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkMiniDYI>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkMiniDYIKey(params) : null);
  const swrFn = () => getApiImpactFrameworkMiniDYI(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiImpactFrameworkGetSdg = (
    
 ) => {
      return customInstance<SdgDTO[]>(
      {url: `/api/ImpactFramework/GetSdg`, method: 'get'
    },
      );
    }
  

export const getGetApiImpactFrameworkGetSdgKey = () => [`/api/ImpactFramework/GetSdg`];

    
export type GetApiImpactFrameworkGetSdgQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetSdg>>>
export type GetApiImpactFrameworkGetSdgQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetSdg = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetSdg>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetSdgKey() : null);
  const swrFn = () => getApiImpactFrameworkGetSdg();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiImpactFrameworkAddOutcomeType = (
    outcomeTypeRequestBody: OutcomeTypeRequestBody,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/AddOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeTypeRequestBody
    },
      );
    }
  


export const postApiImpactFrameworkEditOutcomeType = (
    outcomeTypeRequestBody: OutcomeTypeRequestBody,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/EditOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeTypeRequestBody
    },
      );
    }
  


export const postApiImpactFrameworkEditOutcome = (
    outcomeRequest: OutcomeRequest,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/EditOutcome`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeRequest
    },
      );
    }
  


export const getApiImpactFrameworkGetStakeholderType = (
    
 ) => {
      return customInstance<StakeholderType[]>(
      {url: `/api/ImpactFramework/GetStakeholderType`, method: 'get'
    },
      );
    }
  

export const getGetApiImpactFrameworkGetStakeholderTypeKey = () => [`/api/ImpactFramework/GetStakeholderType`];

    
export type GetApiImpactFrameworkGetStakeholderTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetStakeholderType>>>
export type GetApiImpactFrameworkGetStakeholderTypeQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetStakeholderType = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetStakeholderType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetStakeholderTypeKey() : null);
  const swrFn = () => getApiImpactFrameworkGetStakeholderType();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiIndicatorGetIndicatorType = (
    bodyBody: BodyBody,
 ) => {
      return customInstance<IndicatorTypeDTO[][]>(
      {url: `/api/Indicator/GetIndicatorType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bodyBody
    },
      );
    }
  


export const postApiJwtTokenExchange = (
    postApiJwtTokenExchangeBody: PostApiJwtTokenExchangeBodyOne | PostApiJwtTokenExchangeBodyTwo | PostApiJwtTokenExchangeBodyThree,
 ) => {
      return customInstance<string>(
      {url: `/api/Jwt/token-exchange`, method: 'post',
      data: postApiJwtTokenExchangeBody
    },
      );
    }
  


export const postApiMapsGetmapResult = (
    params?: PostApiMapsGetmapResultParams,
 ) => {
      return customInstance<MapsResponseDto[]>(
      {url: `/api/Maps/GetmapResult`, method: 'post',
        params
    },
      );
    }
  


export const getApiMonitoraggioShowMonitoraggioTur = (
    params?: GetApiMonitoraggioShowMonitoraggioTurParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Monitoraggio/ShowMonitoraggioTur`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiMonitoraggioShowMonitoraggioTurKey = (params?: GetApiMonitoraggioShowMonitoraggioTurParams,) => [`/api/Monitoraggio/ShowMonitoraggioTur`, ...(params ? [params]: [])];

    
export type GetApiMonitoraggioShowMonitoraggioTurQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMonitoraggioShowMonitoraggioTur>>>
export type GetApiMonitoraggioShowMonitoraggioTurQueryError = ErrorType<unknown>

export const useGetApiMonitoraggioShowMonitoraggioTur = <TError = ErrorType<unknown>>(
 params?: GetApiMonitoraggioShowMonitoraggioTurParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiMonitoraggioShowMonitoraggioTur>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiMonitoraggioShowMonitoraggioTurKey(params) : null);
  const swrFn = () => getApiMonitoraggioShowMonitoraggioTur(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiMonitoraggioShowMonitoraggioPui = (
    params?: GetApiMonitoraggioShowMonitoraggioPuiParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Monitoraggio/ShowMonitoraggioPui`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiMonitoraggioShowMonitoraggioPuiKey = (params?: GetApiMonitoraggioShowMonitoraggioPuiParams,) => [`/api/Monitoraggio/ShowMonitoraggioPui`, ...(params ? [params]: [])];

    
export type GetApiMonitoraggioShowMonitoraggioPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiMonitoraggioShowMonitoraggioPui>>>
export type GetApiMonitoraggioShowMonitoraggioPuiQueryError = ErrorType<unknown>

export const useGetApiMonitoraggioShowMonitoraggioPui = <TError = ErrorType<unknown>>(
 params?: GetApiMonitoraggioShowMonitoraggioPuiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiMonitoraggioShowMonitoraggioPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiMonitoraggioShowMonitoraggioPuiKey(params) : null);
  const swrFn = () => getApiMonitoraggioShowMonitoraggioPui(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiOrganizationCreateOrganization = (
    createOrgDtoBody: CreateOrgDtoBody,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Organization/CreateOrganization`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createOrgDtoBody
    },
      );
    }
  


export const getApiOrganizationGetOrganizations = (
    
 ) => {
      return customInstance<OrganizationDto[]>(
      {url: `/api/Organization/GetOrganizations`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationsKey = () => [`/api/Organization/GetOrganizations`];

    
export type GetApiOrganizationGetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganizations>>>
export type GetApiOrganizationGetOrganizationsQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganizations = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganizations>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationsKey() : null);
  const swrFn = () => getApiOrganizationGetOrganizations();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiOrganizationGetOrganizationById = (
    params?: GetApiOrganizationGetOrganizationByIdParams,
 ) => {
      return customInstance<OrganizationDto>(
      {url: `/api/Organization/GetOrganizationById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationByIdKey = (params?: GetApiOrganizationGetOrganizationByIdParams,) => [`/api/Organization/GetOrganizationById`, ...(params ? [params]: [])];

    
export type GetApiOrganizationGetOrganizationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationById>>>
export type GetApiOrganizationGetOrganizationByIdQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganizationById = <TError = ErrorType<unknown>>(
 params?: GetApiOrganizationGetOrganizationByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationByIdKey(params) : null);
  const swrFn = () => getApiOrganizationGetOrganizationById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiOrganizationUpdateOrganization = (
    createOrgDtoBody: CreateOrgDtoBody,
 ) => {
      return customInstance<OrganizationDto>(
      {url: `/api/Organization/UpdateOrganization`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createOrgDtoBody
    },
      );
    }
  


export const postApiOrganizationDeleteOrganization = (
    params?: PostApiOrganizationDeleteOrganizationParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Organization/DeleteOrganization`, method: 'post',
        params
    },
      );
    }
  


export const postApiOrganizationGetPIva = (
    params?: PostApiOrganizationGetPIvaParams,
 ) => {
      return customInstance<OrganizationDto>(
      {url: `/api/Organization/GetPIva`, method: 'post',
        params
    },
      );
    }
  


export const getApiOrganizationGetOrganization = (
    
 ) => {
      return customInstance<OrganizationDto>(
      {url: `/api/Organization/GetOrganization`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationKey = () => [`/api/Organization/GetOrganization`];

    
export type GetApiOrganizationGetOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganization>>>
export type GetApiOrganizationGetOrganizationQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganization = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganization>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationKey() : null);
  const swrFn = () => getApiOrganizationGetOrganization();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiOrganizationGetOrganizationUsers = (
    params?: GetApiOrganizationGetOrganizationUsersParams,
 ) => {
      return customInstance<UserDto[]>(
      {url: `/api/Organization/GetOrganizationUsers`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationUsersKey = (params?: GetApiOrganizationGetOrganizationUsersParams,) => [`/api/Organization/GetOrganizationUsers`, ...(params ? [params]: [])];

    
export type GetApiOrganizationGetOrganizationUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationUsers>>>
export type GetApiOrganizationGetOrganizationUsersQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganizationUsers = <TError = ErrorType<unknown>>(
 params?: GetApiOrganizationGetOrganizationUsersParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationUsers>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationUsersKey(params) : null);
  const swrFn = () => getApiOrganizationGetOrganizationUsers(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiOrganizationGetAllOrgsCompilazioni = (
    
 ) => {
      return customInstance<OrgCompilazioneListDTO[]>(
      {url: `/api/Organization/GetAllOrgsCompilazioni`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationGetAllOrgsCompilazioniKey = () => [`/api/Organization/GetAllOrgsCompilazioni`];

    
export type GetApiOrganizationGetAllOrgsCompilazioniQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetAllOrgsCompilazioni>>>
export type GetApiOrganizationGetAllOrgsCompilazioniQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetAllOrgsCompilazioni = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetAllOrgsCompilazioni>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetAllOrgsCompilazioniKey() : null);
  const swrFn = () => getApiOrganizationGetAllOrgsCompilazioni();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiOutcomeGetOutcomeType = (
    postApiOutcomeGetOutcomeTypeBody: number[],
 ) => {
      return customInstance<OutcomeTypeDTO[][]>(
      {url: `/api/Outcome/GetOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postApiOutcomeGetOutcomeTypeBody
    },
      );
    }
  


export const getApiPBITokens = (
    params?: GetApiPBITokensParams,
 ) => {
      return customInstance<EmbedParams>(
      {url: `/api/PBITokens`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPBITokensKey = (params?: GetApiPBITokensParams,) => [`/api/PBITokens`, ...(params ? [params]: [])];

    
export type GetApiPBITokensQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPBITokens>>>
export type GetApiPBITokensQueryError = ErrorType<unknown>

export const useGetApiPBITokens = <TError = ErrorType<unknown>>(
 params?: GetApiPBITokensParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPBITokens>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPBITokensKey(params) : null);
  const swrFn = () => getApiPBITokens(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPowerBiAvailable = (
    params?: GetApiPowerBiAvailableParams,
 ) => {
      return customInstance<PowerBI[]>(
      {url: `/api/PowerBi/Available`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPowerBiAvailableKey = (params?: GetApiPowerBiAvailableParams,) => [`/api/PowerBi/Available`, ...(params ? [params]: [])];

    
export type GetApiPowerBiAvailableQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPowerBiAvailable>>>
export type GetApiPowerBiAvailableQueryError = ErrorType<unknown>

export const useGetApiPowerBiAvailable = <TError = ErrorType<unknown>>(
 params?: GetApiPowerBiAvailableParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPowerBiAvailable>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPowerBiAvailableKey(params) : null);
  const swrFn = () => getApiPowerBiAvailable(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiPuiCreatePui = (
    puiCompilazioneDto: PuiCompilazioneDto,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Pui/CreatePui`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: puiCompilazioneDto
    },
      );
    }
  


export const getApiPuiGetAllPuiFromCity = (
    params?: GetApiPuiGetAllPuiFromCityParams,
 ) => {
      return customInstance<PuiResponseDto[]>(
      {url: `/api/Pui/GetAllPuiFromCity`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiGetAllPuiFromCityKey = (params?: GetApiPuiGetAllPuiFromCityParams,) => [`/api/Pui/GetAllPuiFromCity`, ...(params ? [params]: [])];

    
export type GetApiPuiGetAllPuiFromCityQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiGetAllPuiFromCity>>>
export type GetApiPuiGetAllPuiFromCityQueryError = ErrorType<unknown>

export const useGetApiPuiGetAllPuiFromCity = <TError = ErrorType<unknown>>(
 params?: GetApiPuiGetAllPuiFromCityParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiGetAllPuiFromCity>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiGetAllPuiFromCityKey(params) : null);
  const swrFn = () => getApiPuiGetAllPuiFromCity(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPuiGetPuiObiettivi = (
    params?: GetApiPuiGetPuiObiettiviParams,
 ) => {
      return customInstance<PuiObbietiviRespDto[]>(
      {url: `/api/Pui/GetPuiObiettivi`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiGetPuiObiettiviKey = (params?: GetApiPuiGetPuiObiettiviParams,) => [`/api/Pui/GetPuiObiettivi`, ...(params ? [params]: [])];

    
export type GetApiPuiGetPuiObiettiviQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiGetPuiObiettivi>>>
export type GetApiPuiGetPuiObiettiviQueryError = ErrorType<unknown>

export const useGetApiPuiGetPuiObiettivi = <TError = ErrorType<unknown>>(
 params?: GetApiPuiGetPuiObiettiviParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiGetPuiObiettivi>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiGetPuiObiettiviKey(params) : null);
  const swrFn = () => getApiPuiGetPuiObiettivi(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPuiGetPuiObiettiviSpecifici = (
    params?: GetApiPuiGetPuiObiettiviSpecificiParams,
 ) => {
      return customInstance<PuiObbietiviRespDto[]>(
      {url: `/api/Pui/GetPuiObiettiviSpecifici`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiGetPuiObiettiviSpecificiKey = (params?: GetApiPuiGetPuiObiettiviSpecificiParams,) => [`/api/Pui/GetPuiObiettiviSpecifici`, ...(params ? [params]: [])];

    
export type GetApiPuiGetPuiObiettiviSpecificiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiGetPuiObiettiviSpecifici>>>
export type GetApiPuiGetPuiObiettiviSpecificiQueryError = ErrorType<unknown>

export const useGetApiPuiGetPuiObiettiviSpecifici = <TError = ErrorType<unknown>>(
 params?: GetApiPuiGetPuiObiettiviSpecificiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiGetPuiObiettiviSpecifici>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiGetPuiObiettiviSpecificiKey(params) : null);
  const swrFn = () => getApiPuiGetPuiObiettiviSpecifici(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPuiHasSubmittedPui = (
    params?: GetApiPuiHasSubmittedPuiParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Pui/HasSubmittedPui`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiHasSubmittedPuiKey = (params?: GetApiPuiHasSubmittedPuiParams,) => [`/api/Pui/HasSubmittedPui`, ...(params ? [params]: [])];

    
export type GetApiPuiHasSubmittedPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiHasSubmittedPui>>>
export type GetApiPuiHasSubmittedPuiQueryError = ErrorType<unknown>

export const useGetApiPuiHasSubmittedPui = <TError = ErrorType<unknown>>(
 params?: GetApiPuiHasSubmittedPuiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiHasSubmittedPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiHasSubmittedPuiKey(params) : null);
  const swrFn = () => getApiPuiHasSubmittedPui(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPuiGetPuiObiettiviGenerali = (
    params?: GetApiPuiGetPuiObiettiviGeneraliParams,
 ) => {
      return customInstance<ObiettiviGenerali[]>(
      {url: `/api/Pui/GetPuiObiettiviGenerali`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiGetPuiObiettiviGeneraliKey = (params?: GetApiPuiGetPuiObiettiviGeneraliParams,) => [`/api/Pui/GetPuiObiettiviGenerali`, ...(params ? [params]: [])];

    
export type GetApiPuiGetPuiObiettiviGeneraliQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiGetPuiObiettiviGenerali>>>
export type GetApiPuiGetPuiObiettiviGeneraliQueryError = ErrorType<unknown>

export const useGetApiPuiGetPuiObiettiviGenerali = <TError = ErrorType<unknown>>(
 params?: GetApiPuiGetPuiObiettiviGeneraliParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiGetPuiObiettiviGenerali>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiGetPuiObiettiviGeneraliKey(params) : null);
  const swrFn = () => getApiPuiGetPuiObiettiviGenerali(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiPuiUpdatePuiCompilazioneStatus = (
    params?: PostApiPuiUpdatePuiCompilazioneStatusParams,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Pui/UpdatePuiCompilazioneStatus`, method: 'post',
        params
    },
      );
    }
  


export const getApiPuiGetPuiCompilazione = (
    params?: GetApiPuiGetPuiCompilazioneParams,
 ) => {
      return customInstance<PuiCompilazioneDto>(
      {url: `/api/Pui/GetPuiCompilazione`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPuiGetPuiCompilazioneKey = (params?: GetApiPuiGetPuiCompilazioneParams,) => [`/api/Pui/GetPuiCompilazione`, ...(params ? [params]: [])];

    
export type GetApiPuiGetPuiCompilazioneQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPuiGetPuiCompilazione>>>
export type GetApiPuiGetPuiCompilazioneQueryError = ErrorType<unknown>

export const useGetApiPuiGetPuiCompilazione = <TError = ErrorType<unknown>>(
 params?: GetApiPuiGetPuiCompilazioneParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPuiGetPuiCompilazione>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPuiGetPuiCompilazioneKey(params) : null);
  const swrFn = () => getApiPuiGetPuiCompilazione(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiSendEmailSendEmail = (
    logEmailDto: LogEmailDto,
 ) => {
      return customInstance<boolean>(
      {url: `/api/SendEmail/SendEmail`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: logEmailDto
    },
      );
    }
  


export const postApiSJSurveyCreateSurveyType = (
    sJSurveyTypeDTO: SJSurveyTypeDTO,
 ) => {
      return customInstance<SJSurveyType>(
      {url: `/api/SJSurvey/CreateSurveyType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurveyTypeDTO
    },
      );
    }
  


export const postApiSJSurveyCreateSurvey = (
    createSurveyRequestDto: CreateSurveyRequestDto,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/CreateSurvey`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSurveyRequestDto
    },
      );
    }
  


export const postApiSJSurveyCreateSurveyJson = (
    createSurveyJsonRequestDto: CreateSurveyJsonRequestDto,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/CreateSurveyJson`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSurveyJsonRequestDto
    },
      );
    }
  


export const postApiSJSurveyCreateSurveySubmission = (
    surveySubmissionRequestDtoBody: SurveySubmissionRequestDtoBody,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/CreateSurveySubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: surveySubmissionRequestDtoBody
    },
      );
    }
  


export const postApiSJSurveyCreateSurveyAnswers = (
    createAnswersDto: CreateAnswersDto,
 ) => {
      return customInstance<SJAnswers>(
      {url: `/api/SJSurvey/CreateSurveyAnswers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAnswersDto
    },
      );
    }
  


export const postApiSJSurveyUpdateSurveyJson = (
    sJSurveyJson: SJSurveyJson,
    params?: PostApiSJSurveyUpdateSurveyJsonParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/UpdateSurveyJson`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurveyJson,
        params
    },
      );
    }
  


export const postApiSJSurveySaveOrUpdateSJSurvey = (
    sJSurvey: SJSurvey,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/SaveOrUpdateSJSurvey`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurvey
    },
      );
    }
  


export const postApiSJSurveyConsolidateQuestions = (
    sJQuestionsExtended: SJQuestionsExtended[],
    params?: PostApiSJSurveyConsolidateQuestionsParams,
 ) => {
      return customInstance<void>(
      {url: `/api/SJSurvey/ConsolidateQuestions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJQuestionsExtended,
        params
    },
      );
    }
  


export const postApiSJSurveyCloseSurveySubmission = (
    params?: PostApiSJSurveyCloseSurveySubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/CloseSurveySubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiSJSurveyOpenSurveySubmission = (
    params?: PostApiSJSurveyOpenSurveySubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/OpenSurveySubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiSJSurveyUpdateSurveyJsonStruct = (
    postApiSJSurveyUpdateSurveyJsonStructBody: string,
    params?: PostApiSJSurveyUpdateSurveyJsonStructParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/UpdateSurveyJsonStruct`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postApiSJSurveyUpdateSurveyJsonStructBody,
        params
    },
      );
    }
  


export const getApiSJSurveyGetSurveys = (
    getSurveyDto: GetSurveyDto,
 ) => {
      return customInstance<SJSurvey[]>(
      {url: `/api/SJSurvey/GetSurveys`, method: 'get',
      headers: {'Content-Type': 'application/json', }
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveysKey = (getSurveyDto: GetSurveyDto,) => [`/api/SJSurvey/GetSurveys`, getSurveyDto];

    
export type GetApiSJSurveyGetSurveysQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveys>>>
export type GetApiSJSurveyGetSurveysQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveys = <TError = ErrorType<unknown>>(
 getSurveyDto: GetSurveyDto, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveys>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveysKey(getSurveyDto) : null);
  const swrFn = () => getApiSJSurveyGetSurveys(getSurveyDto, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetLastSurveyJSON = (
    params?: GetApiSJSurveyGetLastSurveyJSONParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetLastSurveyJSON`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetLastSurveyJSONKey = (params?: GetApiSJSurveyGetLastSurveyJSONParams,) => [`/api/SJSurvey/GetLastSurveyJSON`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetLastSurveyJSONQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetLastSurveyJSON>>>
export type GetApiSJSurveyGetLastSurveyJSONQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetLastSurveyJSON = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetLastSurveyJSONParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetLastSurveyJSON>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetLastSurveyJSONKey(params) : null);
  const swrFn = () => getApiSJSurveyGetLastSurveyJSON(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonById = (
    params?: GetApiSJSurveyGetSurveyJsonByIdParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetSurveyJsonById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonByIdKey = (params?: GetApiSJSurveyGetSurveyJsonByIdParams,) => [`/api/SJSurvey/GetSurveyJsonById`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonById>>>
export type GetApiSJSurveyGetSurveyJsonByIdQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonById = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonByIdKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyAnswerJsonById = (
    params?: GetApiSJSurveyGetSurveyAnswerJsonByIdParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetSurveyAnswerJsonById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyAnswerJsonByIdKey = (params?: GetApiSJSurveyGetSurveyAnswerJsonByIdParams,) => [`/api/SJSurvey/GetSurveyAnswerJsonById`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyAnswerJsonByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyAnswerJsonById>>>
export type GetApiSJSurveyGetSurveyAnswerJsonByIdQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyAnswerJsonById = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyAnswerJsonByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyAnswerJsonById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyAnswerJsonByIdKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyAnswerJsonById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiSJSurveyUpdateSurveyJsonById = (
    test: Test,
    params?: PostApiSJSurveyUpdateSurveyJsonByIdParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/SJSurvey/UpdateSurveyJsonById`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: test,
        params
    },
      );
    }
  


export const getApiSJSurveyGetSurveyJsonBySubmission = (
    params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams,
 ) => {
      return customInstance<JSONContent>(
      {url: `/api/SJSurvey/GetSurveyJsonBySubmission`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonBySubmissionKey = (params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams,) => [`/api/SJSurvey/GetSurveyJsonBySubmission`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonBySubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySubmission>>>
export type GetApiSJSurveyGetSurveyJsonBySubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonBySubmission = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonBySubmissionKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonBySubmission(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSubmission = (
    params?: GetApiSJSurveyGetSubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/GetSubmission`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSubmissionKey = (params?: GetApiSJSurveyGetSubmissionParams,) => [`/api/SJSurvey/GetSubmission`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSubmission>>>
export type GetApiSJSurveyGetSubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSubmission = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSubmissionParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSubmissionKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSubmission(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyBySubmissionID = (
    params?: GetApiSJSurveyGetSurveyBySubmissionIDParams,
 ) => {
      return customInstance<SJSurveyInstance>(
      {url: `/api/SJSurvey/GetSurveyBySubmissionID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyBySubmissionIDKey = (params?: GetApiSJSurveyGetSurveyBySubmissionIDParams,) => [`/api/SJSurvey/GetSurveyBySubmissionID`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyBySubmissionIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyBySubmissionID>>>
export type GetApiSJSurveyGetSurveyBySubmissionIDQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyBySubmissionID = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyBySubmissionIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyBySubmissionID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyBySubmissionIDKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyBySubmissionID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyTypeList = (
    
 ) => {
      return customInstance<SJSurveyType[]>(
      {url: `/api/SJSurvey/GetSurveyTypeList`, method: 'get'
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyTypeListKey = () => [`/api/SJSurvey/GetSurveyTypeList`];

    
export type GetApiSJSurveyGetSurveyTypeListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyTypeList>>>
export type GetApiSJSurveyGetSurveyTypeListQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyTypeList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyTypeList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyTypeListKey() : null);
  const swrFn = () => getApiSJSurveyGetSurveyTypeList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyByType = (
    params?: GetApiSJSurveyGetSurveyByTypeParams,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/GetSurveyByType`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyByTypeKey = (params?: GetApiSJSurveyGetSurveyByTypeParams,) => [`/api/SJSurvey/GetSurveyByType`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyByType>>>
export type GetApiSJSurveyGetSurveyByTypeQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyByType = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyByTypeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyByType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyByTypeKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyByType(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveySubmission = (
    surveySubmissionRequestDtoBody: SurveySubmissionRequestDtoBody,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/GetSurveySubmission`, method: 'get',
      headers: {'Content-Type': 'application/json', }
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveySubmissionKey = (surveySubmissionRequestDtoBody: SurveySubmissionRequestDtoBody,) => [`/api/SJSurvey/GetSurveySubmission`, surveySubmissionRequestDtoBody];

    
export type GetApiSJSurveyGetSurveySubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmission>>>
export type GetApiSJSurveyGetSurveySubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveySubmission = <TError = ErrorType<unknown>>(
 surveySubmissionRequestDtoBody: SurveySubmissionRequestDtoBody, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveySubmissionKey(surveySubmissionRequestDtoBody) : null);
  const swrFn = () => getApiSJSurveyGetSurveySubmission(surveySubmissionRequestDtoBody, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveySubmssionbySurveyId = (
    params?: GetApiSJSurveyGetSurveySubmssionbySurveyIdParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/GetSurveySubmssionbySurveyId`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveySubmssionbySurveyIdKey = (params?: GetApiSJSurveyGetSurveySubmssionbySurveyIdParams,) => [`/api/SJSurvey/GetSurveySubmssionbySurveyId`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveySubmssionbySurveyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmssionbySurveyId>>>
export type GetApiSJSurveyGetSurveySubmssionbySurveyIdQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveySubmssionbySurveyId = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveySubmssionbySurveyIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmssionbySurveyId>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveySubmssionbySurveyIdKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveySubmssionbySurveyId(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonBySurveyID = (
    params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/GetSurveyJsonBySurveyID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonBySurveyIDKey = (params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams,) => [`/api/SJSurvey/GetSurveyJsonBySurveyID`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonBySurveyIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySurveyID>>>
export type GetApiSJSurveyGetSurveyJsonBySurveyIDQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonBySurveyID = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySurveyID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonBySurveyIDKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonBySurveyID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonStructBySurveyID = (
    params?: GetApiSJSurveyGetSurveyJsonStructBySurveyIDParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetSurveyJsonStructBySurveyID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonStructBySurveyIDKey = (params?: GetApiSJSurveyGetSurveyJsonStructBySurveyIDParams,) => [`/api/SJSurvey/GetSurveyJsonStructBySurveyID`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonStructBySurveyIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonStructBySurveyID>>>
export type GetApiSJSurveyGetSurveyJsonStructBySurveyIDQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonStructBySurveyID = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonStructBySurveyIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonStructBySurveyID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonStructBySurveyIDKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonStructBySurveyID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetAnswersBySubmissionIdPui = (
    params?: GetApiSJSurveyGetAnswersBySubmissionIdPuiParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetAnswersBySubmissionIdPui`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetAnswersBySubmissionIdPuiKey = (params?: GetApiSJSurveyGetAnswersBySubmissionIdPuiParams,) => [`/api/SJSurvey/GetAnswersBySubmissionIdPui`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetAnswersBySubmissionIdPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetAnswersBySubmissionIdPui>>>
export type GetApiSJSurveyGetAnswersBySubmissionIdPuiQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetAnswersBySubmissionIdPui = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetAnswersBySubmissionIdPuiParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetAnswersBySubmissionIdPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetAnswersBySubmissionIdPuiKey(params) : null);
  const swrFn = () => getApiSJSurveyGetAnswersBySubmissionIdPui(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetAnswersBySubmissionIdTur = (
    params?: GetApiSJSurveyGetAnswersBySubmissionIdTurParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetAnswersBySubmissionIdTur`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetAnswersBySubmissionIdTurKey = (params?: GetApiSJSurveyGetAnswersBySubmissionIdTurParams,) => [`/api/SJSurvey/GetAnswersBySubmissionIdTur`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetAnswersBySubmissionIdTurQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetAnswersBySubmissionIdTur>>>
export type GetApiSJSurveyGetAnswersBySubmissionIdTurQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetAnswersBySubmissionIdTur = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetAnswersBySubmissionIdTurParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetAnswersBySubmissionIdTur>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetAnswersBySubmissionIdTurKey(params) : null);
  const swrFn = () => getApiSJSurveyGetAnswersBySubmissionIdTur(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyUpdateSJQuestionsAll = (
    
 ) => {
      return customInstance<void>(
      {url: `/api/SJSurvey/UpdateSJQuestionsAll`, method: 'get'
    },
      );
    }
  

export const getGetApiSJSurveyUpdateSJQuestionsAllKey = () => [`/api/SJSurvey/UpdateSJQuestionsAll`];

    
export type GetApiSJSurveyUpdateSJQuestionsAllQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyUpdateSJQuestionsAll>>>
export type GetApiSJSurveyUpdateSJQuestionsAllQueryError = ErrorType<unknown>

export const useGetApiSJSurveyUpdateSJQuestionsAll = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyUpdateSJQuestionsAll>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyUpdateSJQuestionsAllKey() : null);
  const swrFn = () => getApiSJSurveyUpdateSJQuestionsAll();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetStepById = (
    params?: GetApiStepGetStepByIdParams,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/GetStepById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepGetStepByIdKey = (params?: GetApiStepGetStepByIdParams,) => [`/api/Step/GetStepById`, ...(params ? [params]: [])];

    
export type GetApiStepGetStepByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetStepById>>>
export type GetApiStepGetStepByIdQueryError = ErrorType<unknown>

export const useGetApiStepGetStepById = <TError = ErrorType<unknown>>(
 params?: GetApiStepGetStepByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetStepById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepByIdKey(params) : null);
  const swrFn = () => getApiStepGetStepById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetSteps = (
    params?: GetApiStepGetStepsParams,
 ) => {
      return customInstance<Step[]>(
      {url: `/api/Step/GetSteps`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepGetStepsKey = (params?: GetApiStepGetStepsParams,) => [`/api/Step/GetSteps`, ...(params ? [params]: [])];

    
export type GetApiStepGetStepsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetSteps>>>
export type GetApiStepGetStepsQueryError = ErrorType<unknown>

export const useGetApiStepGetSteps = <TError = ErrorType<unknown>>(
 params?: GetApiStepGetStepsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetSteps>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepsKey(params) : null);
  const swrFn = () => getApiStepGetSteps(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetStepTypes = (
    
 ) => {
      return customInstance<StepType[]>(
      {url: `/api/Step/GetStepTypes`, method: 'get'
    },
      );
    }
  

export const getGetApiStepGetStepTypesKey = () => [`/api/Step/GetStepTypes`];

    
export type GetApiStepGetStepTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetStepTypes>>>
export type GetApiStepGetStepTypesQueryError = ErrorType<unknown>

export const useGetApiStepGetStepTypes = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetStepTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepTypesKey() : null);
  const swrFn = () => getApiStepGetStepTypes();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiStepCreateStep = (
    createStepDtoBody: CreateStepDtoBody,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/CreateStep`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStepDtoBody
    },
      );
    }
  


export const putApiStepUpsertStep = (
    createStepDtoBody: CreateStepDtoBody,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/UpsertStep`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: createStepDtoBody
    },
      );
    }
  


export const getApiStepUpdateSJQuestionsExtended = (
    params?: GetApiStepUpdateSJQuestionsExtendedParams,
 ) => {
      return customInstance<void>(
      {url: `/api/Step/UpdateSJQuestionsExtended`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepUpdateSJQuestionsExtendedKey = (params?: GetApiStepUpdateSJQuestionsExtendedParams,) => [`/api/Step/UpdateSJQuestionsExtended`, ...(params ? [params]: [])];

    
export type GetApiStepUpdateSJQuestionsExtendedQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtended>>>
export type GetApiStepUpdateSJQuestionsExtendedQueryError = ErrorType<unknown>

export const useGetApiStepUpdateSJQuestionsExtended = <TError = ErrorType<unknown>>(
 params?: GetApiStepUpdateSJQuestionsExtendedParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtended>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepUpdateSJQuestionsExtendedKey(params) : null);
  const swrFn = () => getApiStepUpdateSJQuestionsExtended(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiStepCreateStepType = (
    createStepTypeDto: CreateStepTypeDto,
 ) => {
      return customInstance<StepType>(
      {url: `/api/Step/CreateStepType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStepTypeDto
    },
      );
    }
  


export const postApiTurCreateUpdateTur = (
    turCompilazioneDtoBody: TurCompilazioneDtoBody,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Tur/CreateUpdateTur`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: turCompilazioneDtoBody
    },
      );
    }
  


export const postApiTurCreateUpdateTurAdmin = (
    turCompilazioneDtoBody: TurCompilazioneDtoBody,
    params?: PostApiTurCreateUpdateTurAdminParams,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Tur/CreateUpdateTurAdmin`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: turCompilazioneDtoBody,
        params
    },
      );
    }
  


export const postApiTurUpdateTurCompilazioneStatus = (
    params?: PostApiTurUpdateTurCompilazioneStatusParams,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/Tur/UpdateTurCompilazioneStatus`, method: 'post',
        params
    },
      );
    }
  


export const getApiTurGetTurCompilazione = (
    params?: GetApiTurGetTurCompilazioneParams,
 ) => {
      return customInstance<TurCompilazioneDto>(
      {url: `/api/Tur/GetTurCompilazione`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiTurGetTurCompilazioneKey = (params?: GetApiTurGetTurCompilazioneParams,) => [`/api/Tur/GetTurCompilazione`, ...(params ? [params]: [])];

    
export type GetApiTurGetTurCompilazioneQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTurGetTurCompilazione>>>
export type GetApiTurGetTurCompilazioneQueryError = ErrorType<unknown>

export const useGetApiTurGetTurCompilazione = <TError = ErrorType<unknown>>(
 params?: GetApiTurGetTurCompilazioneParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTurGetTurCompilazione>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTurGetTurCompilazioneKey(params) : null);
  const swrFn = () => getApiTurGetTurCompilazione(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllTourism = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllTourism`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllTourismKey = () => [`/api/Typology/GetAllTourism`];

    
export type GetApiTypologyGetAllTourismQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllTourism>>>
export type GetApiTypologyGetAllTourismQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllTourism = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllTourism>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllTourismKey() : null);
  const swrFn = () => getApiTypologyGetAllTourism();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllDesignlevel = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllDesignlevel`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllDesignlevelKey = () => [`/api/Typology/GetAllDesignlevel`];

    
export type GetApiTypologyGetAllDesignlevelQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllDesignlevel>>>
export type GetApiTypologyGetAllDesignlevelQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllDesignlevel = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllDesignlevel>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllDesignlevelKey() : null);
  const swrFn = () => getApiTypologyGetAllDesignlevel();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllFormaGiuridica = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllFormaGiuridica`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllFormaGiuridicaKey = () => [`/api/Typology/GetAllFormaGiuridica`];

    
export type GetApiTypologyGetAllFormaGiuridicaQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllFormaGiuridica>>>
export type GetApiTypologyGetAllFormaGiuridicaQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllFormaGiuridica = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllFormaGiuridica>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllFormaGiuridicaKey() : null);
  const swrFn = () => getApiTypologyGetAllFormaGiuridica();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllRegione = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllRegione`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllRegioneKey = () => [`/api/Typology/GetAllRegione`];

    
export type GetApiTypologyGetAllRegioneQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllRegione>>>
export type GetApiTypologyGetAllRegioneQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllRegione = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllRegione>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllRegioneKey() : null);
  const swrFn = () => getApiTypologyGetAllRegione();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllComune = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllComune`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllComuneKey = () => [`/api/Typology/GetAllComune`];

    
export type GetApiTypologyGetAllComuneQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllComune>>>
export type GetApiTypologyGetAllComuneQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllComune = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllComune>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllComuneKey() : null);
  const swrFn = () => getApiTypologyGetAllComune();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetAllCities = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetAllCities`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetAllCitiesKey = () => [`/api/Typology/GetAllCities`];

    
export type GetApiTypologyGetAllCitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetAllCities>>>
export type GetApiTypologyGetAllCitiesQueryError = ErrorType<unknown>

export const useGetApiTypologyGetAllCities = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetAllCities>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetAllCitiesKey() : null);
  const swrFn = () => getApiTypologyGetAllCities();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetTipoprogettiFinanzTur = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetTipoprogettiFinanzTur`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetTipoprogettiFinanzTurKey = () => [`/api/Typology/GetTipoprogettiFinanzTur`];

    
export type GetApiTypologyGetTipoprogettiFinanzTurQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetTipoprogettiFinanzTur>>>
export type GetApiTypologyGetTipoprogettiFinanzTurQueryError = ErrorType<unknown>

export const useGetApiTypologyGetTipoprogettiFinanzTur = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetTipoprogettiFinanzTur>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetTipoprogettiFinanzTurKey() : null);
  const swrFn = () => getApiTypologyGetTipoprogettiFinanzTur();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetTipoprogettiFinanzPui = (
    
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetTipoprogettiFinanzPui`, method: 'get'
    },
      );
    }
  

export const getGetApiTypologyGetTipoprogettiFinanzPuiKey = () => [`/api/Typology/GetTipoprogettiFinanzPui`];

    
export type GetApiTypologyGetTipoprogettiFinanzPuiQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetTipoprogettiFinanzPui>>>
export type GetApiTypologyGetTipoprogettiFinanzPuiQueryError = ErrorType<unknown>

export const useGetApiTypologyGetTipoprogettiFinanzPui = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetTipoprogettiFinanzPui>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetTipoprogettiFinanzPuiKey() : null);
  const swrFn = () => getApiTypologyGetTipoprogettiFinanzPui();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetComuneFromCity = (
    params?: GetApiTypologyGetComuneFromCityParams,
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetComuneFromCity`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiTypologyGetComuneFromCityKey = (params?: GetApiTypologyGetComuneFromCityParams,) => [`/api/Typology/GetComuneFromCity`, ...(params ? [params]: [])];

    
export type GetApiTypologyGetComuneFromCityQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetComuneFromCity>>>
export type GetApiTypologyGetComuneFromCityQueryError = ErrorType<unknown>

export const useGetApiTypologyGetComuneFromCity = <TError = ErrorType<unknown>>(
 params?: GetApiTypologyGetComuneFromCityParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetComuneFromCity>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetComuneFromCityKey(params) : null);
  const swrFn = () => getApiTypologyGetComuneFromCity(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetRegionFromCity = (
    params?: GetApiTypologyGetRegionFromCityParams,
 ) => {
      return customInstance<TypologyResponseDto>(
      {url: `/api/Typology/GetRegionFromCity`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiTypologyGetRegionFromCityKey = (params?: GetApiTypologyGetRegionFromCityParams,) => [`/api/Typology/GetRegionFromCity`, ...(params ? [params]: [])];

    
export type GetApiTypologyGetRegionFromCityQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetRegionFromCity>>>
export type GetApiTypologyGetRegionFromCityQueryError = ErrorType<unknown>

export const useGetApiTypologyGetRegionFromCity = <TError = ErrorType<unknown>>(
 params?: GetApiTypologyGetRegionFromCityParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetRegionFromCity>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetRegionFromCityKey(params) : null);
  const swrFn = () => getApiTypologyGetRegionFromCity(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetComunefromProv = (
    params?: GetApiTypologyGetComunefromProvParams,
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetComunefromProv`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiTypologyGetComunefromProvKey = (params?: GetApiTypologyGetComunefromProvParams,) => [`/api/Typology/GetComunefromProv`, ...(params ? [params]: [])];

    
export type GetApiTypologyGetComunefromProvQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetComunefromProv>>>
export type GetApiTypologyGetComunefromProvQueryError = ErrorType<unknown>

export const useGetApiTypologyGetComunefromProv = <TError = ErrorType<unknown>>(
 params?: GetApiTypologyGetComunefromProvParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetComunefromProv>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetComunefromProvKey(params) : null);
  const swrFn = () => getApiTypologyGetComunefromProv(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiTypologyGetprovfromRegion = (
    params?: GetApiTypologyGetprovfromRegionParams,
 ) => {
      return customInstance<TypologyResponseDto[]>(
      {url: `/api/Typology/GetprovfromRegion`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiTypologyGetprovfromRegionKey = (params?: GetApiTypologyGetprovfromRegionParams,) => [`/api/Typology/GetprovfromRegion`, ...(params ? [params]: [])];

    
export type GetApiTypologyGetprovfromRegionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTypologyGetprovfromRegion>>>
export type GetApiTypologyGetprovfromRegionQueryError = ErrorType<unknown>

export const useGetApiTypologyGetprovfromRegion = <TError = ErrorType<unknown>>(
 params?: GetApiTypologyGetprovfromRegionParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiTypologyGetprovfromRegion>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiTypologyGetprovfromRegionKey(params) : null);
  const swrFn = () => getApiTypologyGetprovfromRegion(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiUser = (
    
 ) => {
      return customInstance<UserDto>(
      {url: `/api/User`, method: 'get'
    },
      );
    }
  

export const getGetApiUserKey = () => [`/api/User`];

    
export type GetApiUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUser>>>
export type GetApiUserQueryError = ErrorType<unknown>

export const useGetApiUser = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiUser>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiUserKey() : null);
  const swrFn = () => getApiUser();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiUserUpdateUser = (
    userDto: UserDto,
 ) => {
      return customInstance<string>(
      {url: `/api/User/UpdateUser`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userDto
    },
      );
    }
  


export const getApiUserGetUserStatus = (
    
 ) => {
      return customInstance<string>(
      {url: `/api/User/GetUserStatus`, method: 'get'
    },
      );
    }
  

export const getGetApiUserGetUserStatusKey = () => [`/api/User/GetUserStatus`];

    
export type GetApiUserGetUserStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserGetUserStatus>>>
export type GetApiUserGetUserStatusQueryError = ErrorType<unknown>

export const useGetApiUserGetUserStatus = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiUserGetUserStatus>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiUserGetUserStatusKey() : null);
  const swrFn = () => getApiUserGetUserStatus();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiUserIsUserAdmin = (
    
 ) => {
      return customInstance<boolean>(
      {url: `/api/User/IsUserAdmin`, method: 'get'
    },
      );
    }
  

export const getGetApiUserIsUserAdminKey = () => [`/api/User/IsUserAdmin`];

    
export type GetApiUserIsUserAdminQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserIsUserAdmin>>>
export type GetApiUserIsUserAdminQueryError = ErrorType<unknown>

export const useGetApiUserIsUserAdmin = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiUserIsUserAdmin>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiUserIsUserAdminKey() : null);
  const swrFn = () => getApiUserIsUserAdmin();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


