import { Anchor, Button, Divider, Flex, Text } from '@mantine/core';

interface MonitoringRowProps {
  title: string;
  compilata: boolean;
  link: string;
}

export const MonitoringRow = ({ title, compilata, link }: MonitoringRowProps) => {

  return (
    <>
      <Flex justify={'space-between'}>
        {link ?
          <Anchor href={link} pb="xl" fw={600}>
            {title}
          </Anchor> :
          <Text pb="xl" fw={600}>
            {title}
          </Text>
        }

        {compilata
          ? <Button
            variant="subtle"
            size="xs"
            td="underline"
            c="dark"
            component="a"
            href={link}
          >
            Visualizza
          </Button> :
          <Button
            variant="subtle"
            size="xs"
            td="underline"
            c="dark"
          >
            Compila
          </Button>
        }
      </Flex>
      <Divider mb="xl" size="xs" />
    </>
  )
}
