import {
  Anchor,
  Button,
  Center,
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Menu,
  Radio,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconFilter,
  IconX,
} from "@tabler/icons";
import "dayjs/locale/it";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import customInstance from "../../api/axios-instance";
import {
  getGetApiOrganizationGetAllOrgsCompilazioniKey,
  useGetApiOrganizationGetAllOrgsCompilazioni,
} from "../../api/endpoints";
import {
  GetExcelCompilazioniRequest,
  OrgCompilazioneListDTO,
} from "../../api/model";
import Loading from "../../components/Loading";
import { ViewButtonOrg } from "./ViewButtonOrg";

export const ViewOrganization = () => {
  const navigate = useNavigate();
  const [visibleSort, setVisibleSort] = useState<boolean>(false);
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const { data, isLoading, error } =
    useGetApiOrganizationGetAllOrgsCompilazioni();
  const [radio, setRadio] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [listFilters, setListFilters] = useState<string[]>([]);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [fondiFilter, setFondiFilter] = useState<string[]>([]);
  const [excelPuiLoader, setExcelPuiLoader] = useState(false);
  const [excelTurLoader, setExcelTurLoader] = useState(false);
  const [dataFiltered, setDataFiltered] = useState<
    OrgCompilazioneListDTO[] | undefined
  >([]);
  const matches = useMediaQuery("only screen and (max-width: 35rem)");

  useEffect(() => {
    if (!Array.isArray(data) || error) {
      mutate(getGetApiOrganizationGetAllOrgsCompilazioniKey());
    }
    if (Array.isArray(data)) {
      setDataFiltered(data);
    }
  }, [data, error]);

  useEffect(() => {
    setDataFiltered(
      data?.filter(
        (x) => applyFilter(x) && applyFilterDates(x) && applyFilterFondi(x)
      )
    );
  }, [listFilters, fondiFilter, minDate, maxDate]);

  if (!Array.isArray(data) || error || isLoading) {
    return <Loading />;
  }

  const CheckboxIcon: CheckboxProps["icon"] = () => <></>;

  function sortData(value: string) {
    setRadio(value === "asc" ? "NomeAsc" : "NomeDesc");
    setVisibleSort(false);
    return dataFiltered?.sort((a: any, b: any) =>
      value === "asc"
        ? a.organization.name.localeCompare(b.organization.name)
        : b.organization.name.localeCompare(a.organization.name)
    );
  }

  function sortDates(value: string, type: number) {
    if (type === 0) setRadio(value === "rec" ? "DataRecTur" : "DataVeccTur");
    else setRadio(value === "rec" ? "DataRecPui" : "DataVeccPui");
    setVisibleSort(false);
    return dataFiltered?.sort((a, b) =>
      a.compilazioneDrafts?.filter((w: any) => w?.compilazioneType === type)
        .length === 0
        ? 1
        : b.compilazioneDrafts?.filter((w: any) => w?.compilazioneType === type)
            .length === 0
        ? -1
        : a.compilazioneDrafts?.filter(
            (w: any) => w?.compilazioneType === type
          )[0]?.createdAt! >
          b.compilazioneDrafts?.filter(
            (w: any) => w?.compilazioneType === type
          )[0]?.createdAt!
        ? value === "rec"
          ? -1
          : 1
        : value === "rec"
        ? 1
        : -1
    );
  }

  function resetSortData() {
    setRadio("");
    setVisibleSort(false);
    return dataFiltered?.sort((a: any, b: any) =>
      b.organization?.id.localeCompare(a.organization?.id)
    );
  }

  function resetFilterData() {
    setVisibleFilter(false);
    setListFilters([]);
    setFondiFilter([]);
    setMinDate(null);
    setMaxDate(null);
    return;
  }

  function applyFilter(value: OrgCompilazioneListDTO) {
    let ret = false;
    let fondo: any;
    if (listFilters.length === 0) {
      return true;
    }
    listFilters.forEach((x) => {
      if (fondiFilter.includes("TUR") && !fondiFilter.includes("PUI")) {
        fondo = value.compilazioneDrafts?.filter(
          (e) => e.compilazioneType === 0
        ).length;
      }
      if (fondiFilter.includes("PUI") && !fondiFilter.includes("TUR")) {
        fondo = value.compilazioneDrafts?.filter(
          (e) => e.compilazioneType === 1
        ).length;
      }
      if (
        x === "Non avviata" &&
        (fondo
          ? fondo === 0
          : value.compilazioneDrafts?.filter((e) => e.compilazioneType === 0)
              .length === 0 ||
            value.compilazioneDrafts?.filter((e) => e.compilazioneType === 1)
              .length === 0)
      ) {
        ret = true;
      } else if (
        x === "Aperta" &&
        value.compilazioneDrafts?.length !== 0 &&
        fondo !== 0 &&
        (value.puiCompilazione === null || value.turCompilazione === null)
      ) {
        ret = true;
      } else if (
        fondiFilter.includes("TUR") && !fondiFilter.includes("PUI")
          ? value.turCompilazione?.status === x
          : fondiFilter.includes("PUI") && !fondiFilter.includes("TUR")
          ? value.puiCompilazione?.status === x
          : value.turCompilazione?.status === x ||
            value.puiCompilazione?.status === x
      ) {
        ret = true;
      }
    });
    return ret;
  }

  function applyFilterDates(value: OrgCompilazioneListDTO) {
    if (minDate === null || maxDate === null) {
      return true;
    }
    const createdTurValue = value.compilazioneDrafts?.filter(
      (w: any) => w?.compilazioneType === 0
    )[0]?.createdAt!;
    const createdPuiValue = value.compilazioneDrafts?.filter(
      (w: any) => w?.compilazioneType === 1
    )[0]?.createdAt!;

    if (
      minDate.toISOString() <= createdPuiValue &&
      maxDate.toISOString() >= createdPuiValue
    ) {
      return true;
    }
    if (
      minDate.toISOString() <= createdTurValue &&
      maxDate.toISOString() >= createdTurValue
    ) {
      return true;
    }
    return false;
  }

  function applyFilterFondi(value: OrgCompilazioneListDTO) {
    const puiFilter = fondiFilter.includes("PUI");
    const turFilter = fondiFilter.includes("TUR");
    if (fondiFilter.length === 0 || listFilters.length === 0) {
      return true;
    }
    const createdTurValue = value.turCompilazione?.status
      ? listFilters.includes(value.turCompilazione?.status)
      : value.compilazioneDrafts?.filter((w: any) => w?.compilazioneType === 0);
    const createdPuiValue = value.puiCompilazione?.status
      ? listFilters.includes(value.puiCompilazione?.status)
      : value.compilazioneDrafts?.filter((w: any) => w?.compilazioneType === 1);

    if (puiFilter && createdPuiValue) {
      return true;
    }
    if (turFilter && createdTurValue) {
      return true;
    }
    return false;
  }

  function filterData(value: string) {
    listFilters.some((e) => e === value)
      ? setListFilters((a) => a.filter((b) => b !== value))
      : setListFilters([...listFilters, value]);
    return;
  }

  function fondiFilterData(value: string) {
    fondiFilter.some((e) => e === value)
      ? setFondiFilter((a) => a.filter((b) => b !== value))
      : setFondiFilter([...fondiFilter, value]);
    return;
  }

  const postApiDraftCompilazioneExcelTurCompilazioneCustom = (
    data?: GetExcelCompilazioniRequest
  ) => {
    return customInstance<Blob>({
      url: `/api/DraftCompilazione/DownloadExcelCompilazioni`,
      method: "post",
      responseType: "blob",
      data,
    });
  };

  function downloadAllExcel(callType: string) {
    callType === "Tur" ? setExcelTurLoader(true) : setExcelPuiLoader(true);
    const filterTurCandidatura = dataFiltered
      ?.filter((e) => e?.turCompilazione != null)
      .map((e) => e?.turCompilazione?.id!) as Array<string>;
    const filterPuiCandidatura = dataFiltered
      ?.filter((e) => e?.puiCompilazione != null)
      .map((e) => e?.puiCompilazione?.id!) as Array<string>;

    postApiDraftCompilazioneExcelTurCompilazioneCustom({
      compilazioniId:
        callType === "Tur" ? filterTurCandidatura : filterPuiCandidatura,
      callType: callType,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Lista-candidature-${callType}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        setExcelPuiLoader(false);
        setExcelTurLoader(false);
      })
      .catch((e) => {
        console.log(
          "🚀 ~ file: ViewOrganization.tsx:275 ~ ViewOrganization ~ e:",
          e
        );
        setExcelTurLoader(false);
        setExcelPuiLoader(false);
      });
  }

  const downloadButtonCondition =
    ((listFilters.includes("Non avviata") || listFilters.includes("Aperta")) &&
      listFilters.length <= 1) ||
    (listFilters.includes("Non avviata") &&
      listFilters.includes("Aperta") &&
      listFilters.length <= 2) ||
    dataFiltered?.length === 0;

  const rows = dataFiltered
    ?.filter((a) => a.organization?.name?.match(new RegExp(searchValue, "i")))
    .map((e, index) => (
      <tr key={index}>
        <td>
          <Flex direction={"column"}>
            <Anchor
              onClick={() =>
                navigate(`/admin-organization/${e.organization?.id}`, {
                  state: {
                    orgUser: e.organization,
                    isAdmin: true,
                  },
                })
              }
              sx={() => ({
                fontSize: 16,
                "@media (max-width: 51.27em)": {
                  width: 112,
                  fontSize: 14,
                  lineBreak: "anywhere",
                },
              })}
            >
              {e.organization?.name}
            </Anchor>
            <Text
              color="#505050"
              sx={() => ({
                fontSize: 12,
                "@media (max-width: 51.27em)": {
                  width: 112,
                  fontSize: 10,
                  lineBreak: "anywhere",
                },
              })}
            >
              {e.organization?.email}
            </Text>
          </Flex>
        </td>
        {(fondiFilter.includes("TUR") ||
          !fondiFilter.includes("PUI") ||
          (fondiFilter.includes("PUI") && fondiFilter.includes("TUR"))) && (
          <td>
            <ViewButtonOrg
              orgId={e.organization?.id}
              orgName={e.organization?.name}
              compilazione={e.turCompilazione!}
              listWarnings={e.turCompilazione?.warningsTur!}
              compilazioneType={0}
              status={
                e.turCompilazione?.status
                  ? e.turCompilazione.status
                  : e.compilazioneDrafts?.filter(
                      (w) => w.compilazioneType === 0
                    ).length
                  ? "Aperta"
                  : "Non avviata"
              }
              protocollo={e.turCompilazione?.protocolloId!}
              redirectUrl={(step) =>
                navigate(
                  `${
                    step === "Domanda"
                      ? `/admin-organization/survey-tur/${e.organization?.id}`
                      : `/admin-organization/TUR_istruttoria/${e.organization?.id}`
                  }`,
                  {
                    state: {
                      turCompilazione: e.turCompilazione,
                      draftSurveyTur: e.compilazioneDrafts?.filter(
                        (w) => w.compilazioneType === 0
                      )[0],
                      isAdmin: true,
                      organizationId: e.organization?.id,
                      monitoraggio: step === "Monitoraggio",
                    },
                  }
                )
              }
              createAt={new Date(
                e?.compilazioneDrafts?.filter(
                  (w) => w?.compilazioneType === 0
                )[0]?.createdAt!
              ).toLocaleString()}
            />
          </td>
        )}
        {(fondiFilter.includes("PUI") ||
          !fondiFilter.includes("TUR") ||
          (fondiFilter.includes("PUI") && fondiFilter.includes("TUR"))) && (
          <td>
            <ViewButtonOrg
              orgId={e.organization?.id}
              orgName={e.organization?.name}
              compilazione={e.puiCompilazione!}
              listWarnings={e.puiCompilazione?.warningsPui!}
              compilazioneType={1}
              status={
                e.puiCompilazione?.status
                  ? e.puiCompilazione.status
                  : e.compilazioneDrafts?.filter(
                      (w) => w.compilazioneType === 1
                    ).length
                  ? "Aperta"
                  : "Non avviata"
              }
              protocollo={e.puiCompilazione?.protocolloId!}
              redirectUrl={(step) =>
                navigate(
                  `${
                    step === "Domanda"
                      ? `/admin-organization/survey-pui/${e.organization?.id}`
                      : `/admin-organization/PUI_istruttoria/${e.organization?.id}`
                  }`,
                  {
                    state: {
                      puiCompilazione: e.puiCompilazione,
                      draftSurveyPui: e.compilazioneDrafts?.filter(
                        (w) => w.compilazioneType === 1
                      )[0],
                      isAdmin: true,
                      organizationId: e.organization?.id,
                      monitoraggio: step === "Monitoraggio",
                    },
                  }
                )
              }
              createAt={new Date(
                e?.compilazioneDrafts?.filter(
                  (w) => w?.compilazioneType === 1
                )[0]?.createdAt!
              ).toLocaleString()}
            />
          </td>
        )}
      </tr>
    ));

  const SortMenu = () => {
    return (
      <Menu
        shadow="md"
        width={matches ? "100%" : 274}
        opened={visibleSort}
        radius={"md"}
      >
        <Menu.Target>
          <Button
            radius={"xl"}
            bg={"#B7D5EF"}
            c={"black"}
            mt={matches ? -10 : 0}
            mb={matches ? 10 : 0}
            sx={{ ":hover": { color: "white", background: "#B7D5EF" } }}
            rightIcon={
              visibleSort ? (
                <IconChevronUp size={12} />
              ) : (
                <IconChevronDown size={12} />
              )
            }
            onClick={() => {
              setVisibleSort(!visibleSort);
              setVisibleFilter(false);
            }}
          >
            <Text fw={400} size={12}>
              Ordina
            </Text>
          </Button>
        </Menu.Target>
        <Menu.Dropdown mt={matches ? 0 : 18} ml={matches ? 0 : 31}>
          <Menu.Label>
            <Group align="center" position="apart">
              <Text fw={600} size={16} c={"#212121"}>
                Ordina per
              </Text>
              <Text
                fw={600}
                size={10}
                underline
                c={"dark"}
                style={{
                  cursor: "pointer",
                  marginTop: 4,
                  marginLeft: matches ? 137 : 50,
                }}
                onClick={resetSortData}
              >
                Reset
              </Text>
              <IconX
                color="black"
                style={{ cursor: "pointer", marginTop: 3 }}
                onClick={() => setVisibleSort(false)}
                width={20}
              />
            </Group>
          </Menu.Label>
          <Radio.Group value={radio} spacing={"xs"}>
            <Menu.Item onClick={() => sortData("asc")}>
              <Group position="apart">
                <Text fw={400} size={12}>
                  Nome A-Z
                </Text>
                <Radio value={"NomeAsc"} mr={matches ? 120 : 60} />
              </Group>
            </Menu.Item>
            <Menu.Item onClick={() => sortData("desc")}>
              <Group position="apart">
                <Text fw={400} size={12}>
                  Nome Z-A
                </Text>
                <Radio value={"NomeDesc"} mr={matches ? 120 : 60} />
              </Group>
            </Menu.Item>
            {fondiFilter.includes("PUI") && !fondiFilter.includes("TUR") ? (
              <></>
            ) : (
              <>
                <Menu.Item onClick={() => sortDates("rec", 0)}>
                  <Group position="apart">
                    <Text fw={400} size={12}>
                      Data: dal più recente TUR
                    </Text>
                    <Radio mr={matches ? 120 : 60} value={"DataRecTur"} />
                  </Group>
                </Menu.Item>
                <Menu.Item onClick={() => sortDates("vecc", 0)}>
                  <Group position="apart">
                    <Text fw={400} size={12}>
                      Data: dal più vecchio TUR
                    </Text>
                    <Radio mr={matches ? 120 : 60} value={"DataVeccTur"} />
                  </Group>
                </Menu.Item>
              </>
            )}
            {fondiFilter.includes("TUR") && !fondiFilter.includes("PUI") ? (
              <></>
            ) : (
              <>
                <Menu.Item onClick={() => sortDates("rec", 1)}>
                  <Group position="apart">
                    <Text fw={400} size={12}>
                      Data: dal più recente PUI
                    </Text>
                    <Radio mr={matches ? 120 : 60} value={"DataRecPui"} />
                  </Group>
                </Menu.Item>
                <Menu.Item onClick={() => sortDates("vecc", 1)}>
                  <Group position="apart">
                    <Text fw={400} size={12}>
                      Data: dal più vecchio PUI
                    </Text>
                    <Radio mr={matches ? 120 : 60} value={"DataVeccPui"} />
                  </Group>
                </Menu.Item>
              </>
            )}
          </Radio.Group>
        </Menu.Dropdown>
      </Menu>
    );
  };

  // const filterCompilazione =
  // console.log("🚀 ~ file: ViewOrganization.tsx:517 ~ ViewOrganization ~ filterCompilazione:", filterCompilazione)

  const FilterMenu = () => {
    return (
      <Menu
        shadow="md"
        width={matches ? "100%" : 442}
        opened={visibleFilter}
        radius={"md"}
      >
        <Menu.Target>
          <Button
            radius={"xl"}
            bg={"#B7D5EF"}
            c={"black"}
            mt={matches ? -10 : 0}
            mb={matches ? 10 : 0}
            rightIcon={<IconFilter size={12} />}
            onClick={() => {
              setVisibleFilter(!visibleFilter);
              setVisibleSort(false);
            }}
            sx={{ ":hover": { color: "white", background: "#B7D5EF" } }}
          >
            <Text fw={400} size={12}>
              Tutti i filtri
            </Text>
          </Button>
        </Menu.Target>
        <Menu.Dropdown mt={matches ? 0 : 18}>
          <Menu.Label>
            <Group align="center" position="apart">
              <Text fw={600} size={16} c={"#212121"}>
                Filtra per
              </Text>
              <Text
                fw={600}
                size={10}
                underline
                c={"dark"}
                style={{
                  cursor: "pointer",
                  marginTop: 4,
                  marginLeft: matches ? 150 : 230,
                }}
                onClick={resetFilterData}
              >
                Reset
              </Text>
              <IconX
                color="black"
                style={{ cursor: "pointer", marginTop: 3 }}
                onClick={() => setVisibleFilter(false)}
                width={20}
              />
            </Group>
            <Text fw={600} size={10} underline c={"dark"} mt={15}>
              Fondo
            </Text>
          </Menu.Label>
          <Grid grow gutter={"xs"} justify="center" align="center">
            <Grid.Col span={4}>
              <Menu.Item onClick={() => fondiFilterData("TUR")}>
                <Group h={30}>
                  <Checkbox
                    value={"TUR"}
                    color="gray"
                    defaultChecked={fondiFilter.includes("TUR")}
                    icon={CheckboxIcon}
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    TUR
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => fondiFilterData("PUI")}>
                <Group h={30}>
                  <Checkbox
                    value={"PUI"}
                    color="gray"
                    defaultChecked={fondiFilter.includes("PUI")}
                    icon={CheckboxIcon}
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    PUI
                  </Text>
                </Group>
              </Menu.Item>
            </Grid.Col>
            <Grid.Col span={8}></Grid.Col>
          </Grid>
          <Menu.Label>
            <Text fw={600} size={10} underline c={"dark"} mt={15}>
              Stato candidatura
            </Text>
          </Menu.Label>
          <Grid grow gutter={"xs"} justify="center" align="center">
            <Grid.Col span={4}>
              <Menu.Item onClick={() => filterData("Non avviata")}>
                <Group h={30}>
                  <Checkbox
                    value={"Non avviata"}
                    color="gray"
                    defaultChecked={listFilters.includes("Non avviata")}
                    icon={CheckboxIcon}
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    0-Non avviata
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Aperta")}>
                <Group h={30}>
                  <Checkbox
                    defaultChecked={listFilters.includes("Aperta")}
                    icon={CheckboxIcon}
                    value={"Aperta"}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    1-Aperta
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Candidatura Sottomessa")}>
                <Group h={30}>
                  <Checkbox
                    value={"Candidatura Sottomessa"}
                    defaultChecked={listFilters.includes(
                      "Candidatura Sottomessa"
                    )}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    2-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Candidatura Sottomessa
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("In integrazione")}>
                <Group h={30}>
                  <Checkbox
                    value={"In integrazione"}
                    defaultChecked={listFilters.includes("In integrazione")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    3-In integrazione
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Integrazione Sottomessa")}>
                <Group h={30}>
                  <Checkbox
                    value={"Integrazione Sottomessa"}
                    defaultChecked={listFilters.includes(
                      "Integrazione Sottomessa"
                    )}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    3.a-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Integrazione Sottomessa
                  </Text>
                </Group>
              </Menu.Item>
            </Grid.Col>
            <Grid.Col span={4} ml={-20}>
              <Menu.Item onClick={() => filterData("In valutazione")}>
                <Group h={30}>
                  <Checkbox
                    value={"In valutazione"}
                    color="gray"
                    defaultChecked={listFilters.includes("In valutazione")}
                    icon={CheckboxIcon}
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    4-In valutazione
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Ammissibile")}>
                <Group h={30}>
                  <Checkbox
                    value={"Ammissibile"}
                    defaultChecked={listFilters.includes("Ammissibile")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    4.a-Ammissibile
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Rifiutata")}>
                <Group h={30}>
                  <Checkbox
                    value={"Rifiutata"}
                    defaultChecked={listFilters.includes("Rifiutata")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    4.b-Rifiutata
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Istruttoria Sottomessa")}>
                <Group h={30}>
                  <Checkbox
                    value={"Istruttoria Sottomessa"}
                    defaultChecked={listFilters.includes(
                      "Istruttoria Sottomessa"
                    )}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    5-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Istruttoria Sottomessa
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Deliberata")}>
                <Group h={30}>
                  <Checkbox
                    value={"Deliberata"}
                    defaultChecked={listFilters.includes("Deliberata")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    6-Deliberata
                  </Text>
                </Group>
              </Menu.Item>
            </Grid.Col>
            <Grid.Col span={4} ml={-20}>
              <Menu.Item onClick={() => filterData("Monitoraggio Aperto")}>
                <Group h={30}>
                  <Checkbox
                    value={"Monitoraggio Aperto"}
                    defaultChecked={listFilters.includes("Monitoraggio Aperto")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    7-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Monitoraggio Aperto
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Monitoraggio Sottomesso")}>
                <Group h={30}>
                  <Checkbox
                    value={"Monitoraggio Sottomesso"}
                    defaultChecked={listFilters.includes(
                      "Monitoraggio Sottomesso"
                    )}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    8-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Monitoraggio Sottomesso
                  </Text>
                </Group>
              </Menu.Item>
              <Menu.Item onClick={() => filterData("Monitoraggio Chiuso")}>
                <Group h={30}>
                  <Checkbox
                    value={"Monitoraggio Chiuso"}
                    defaultChecked={listFilters.includes("Monitoraggio Chiuso")}
                    icon={CheckboxIcon}
                    color="gray"
                    radius={"xs"}
                  />
                  <Text fw={400} size={10} ml={-5}>
                    9-
                  </Text>
                  <Text
                    fw={400}
                    size={10}
                    ml={-15}
                    style={{ inlineSize: "min-content" }}
                  >
                    Monitoraggio Chiuso
                  </Text>
                </Group>
              </Menu.Item>
            </Grid.Col>
          </Grid>
          <Divider ml={11} mr={11} mt={10} />
          <Text fw={600} size={10} underline c={"dark"} ml={10} mt={20}>
            Data candidatura
          </Text>
          <Group
            ml={matches ? 0 : 10}
            mt={20}
            mb={20}
            position={matches ? "center" : "left"}
          >
            <Text fw={400} size={10}>
              da
            </Text>
            <DatePicker
              w={100}
              placeholder="00/00/00"
              locale="it"
              inputFormat="DD/MM/YY"
              value={minDate}
              onChange={(e: any) => setMinDate(e)}
            />
            <Text fw={400} size={10}>
              a
            </Text>
            <DatePicker
              w={100}
              placeholder="00/00/00"
              locale="it"
              inputFormat="DD/MM/YY"
              value={maxDate}
              onChange={(e: any) => setMaxDate(e)}
            />
          </Group>
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <>
      <Group
        align="baseline"
        style={{ justifyContent: matches ? "center" : "flex-start" }}
      >
        <TextInput
          placeholder="Cerca"
          mb="md"
          radius={"xl"}
          w={"40%"}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onClick={() => {
            setVisibleFilter(false);
            setVisibleSort(false);
          }}
          sx={() => ({
            "@media (max-width: 56.27em)": {
              width: "30%",
            },
            "@media (max-width: 35em)": {
              width: "100%",
            },
          })}
        />
        <SortMenu />
        <FilterMenu />
        <Group
          align="baseline"
          ml={fondiFilter.length === 1 ? 100 : 30}
          sx={() => ({
            "@media (max-width: 56.27em)": {
              marginLeft: 0,
            },
          })}
        >
          {downloadButtonCondition ||
          (fondiFilter.includes("PUI") && !fondiFilter.includes("TUR")) ? (
            <></>
          ) : excelTurLoader ? (
            <Loader size={24} mt={3} />
          ) : (
            <Anchor
              onClick={() => downloadAllExcel("Tur")}
              sx={() => ({
                fontSize: 14,
                marginTop: 6,
                "@media (max-width: 51.27em)": {
                  marginTop: -10,
                  marginBottom: 10,
                },
              })}
            >
              Scarica Excel TUR
            </Anchor>
          )}
          {downloadButtonCondition ||
          (!fondiFilter.includes("PUI") && fondiFilter.includes("TUR")) ? (
            <></>
          ) : excelPuiLoader ? (
            <Loader size={24} mt={3} />
          ) : (
            <Anchor
              onClick={() => downloadAllExcel("Pui")}
              sx={() => ({
                fontSize: 14,
                marginTop: 6,
                "@media (max-width: 51.27em)": {
                  marginTop: -10,
                  marginBottom: 10,
                },
              })}
            >
              Scarica Excel PUI
            </Anchor>
          )}
        </Group>
      </Group>
      <Divider mt={10} />
      {dataFiltered?.filter((a) =>
        a.organization?.name?.match(new RegExp(searchValue, "i"))
      ).length === 0 ? (
        <Center mt={30}>
          <Text>Non sono stati trovati risultati</Text>
        </Center>
      ) : (
        <>
          <Table
            striped
            verticalSpacing={20}
            horizontalSpacing={10}
            withColumnBorders
            highlightOnHover
          >
            <thead>
              <tr>
                <th>Ragione Sociale</th>
                {(fondiFilter.includes("TUR") ||
                  !fondiFilter.includes("PUI") ||
                  (fondiFilter.includes("PUI") &&
                    fondiFilter.includes("TUR"))) && <th>TUR</th>}
                {(fondiFilter.includes("PUI") ||
                  !fondiFilter.includes("TUR") ||
                  (fondiFilter.includes("PUI") &&
                    fondiFilter.includes("TUR"))) && <th>PUI</th>}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          <Divider color="#DEE2E6" />
        </>
      )}
    </>
  );
};
