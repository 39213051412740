import { Text, Input, Button, Group, Paper, Flex, Grid } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { postApiOrganizationGetPIva } from "../../api/endpoints";
import { OrganizationDto } from "../../api/model";

const useStoreStatusIva = create((set) => ({
  statusDefault: "Default",
  setDefault: () => set({ statusDefault: "Default" }),
  setNotRegistered: () => set({ statusDefault: "NotRegistered" }),
  setAlreadyRegisteredOpen: () =>
    set({ statusDefault: "AlreadyRegisteredOpen" }),
  setAlreadyRegisteredClose: () =>
    set({ statusDefault: "AlreadyRegisteredClose" }),
}));

const NotRegisteredComponent = ({ onclick }: { onclick: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text c={"#1E9CFF"} size={14} fw={400} pt={30}>
        {t("components.connect-organization.vat-number-unregistered")}
      </Text>
      <Group position="center" pt={38}>
        <Button
          style={{ width: 266 }}
          className="btn-primary"
          onClick={onclick}
        >
          {t("components.connect-organization.register-your-organization")}
        </Button>
      </Group>
    </>
  );
};

const AlreadyRegisteredComponent = ({
  nameOrg,
  onclick,
}: {
  nameOrg: string | undefined;
  onclick: () => void;
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(false);
  return (
    <>
      <Text c={"#FFB987"} size={14} fw={400} pt={30}>
        {t("components.connect-organization.vat-number-already-registered")}
      </Text>
      <Text size={14} fw={400} pb={30}>
        Inserisci un altro codice p.iva per proseguire
      </Text>
      <Text size={12} fw={400}>
        {t("components.connect-organization.name-organization")}{" "}
        <span style={{ fontSize: 14, fontWeight: 600 }}>{nameOrg}</span>
      </Text>
      {/* <Group position="center" pt={37}>
        <Flex direction={"column"} align={"center"}>
          <Button
            style={{ width: 294 }}
            className="btn-primary"
            disabled={message}
            onClick={() => (setMessage(true), onclick)}
          >
            {t("components.connect-organization.request-access-organization")}
          </Button>
          {message ? (
            <Text
              c={"#B1B1B1"}
              style={{ fontSize: 12, fontWeight: 400, textAlign: "center" }}
              pt={8}
            >
              {t(
                "components.connect-organization.request-forwarded-admin-organization"
              )}
              <br />
              {t("components.connect-organization.receive-confimation-email")}
            </Text>
          ) : (
            <></>
          )}
        </Flex>
      </Group> */}
    </>
  );
};

const DefaultButtonComponent = ({
  value,
  onclick,
}: {
  value: string;
  onclick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Group position="center" pt={40}>
      <Button
        className="btn-primary"
        onClick={onclick}
        disabled={value.length != 11}
      >
        {t("components.connect-organization.confirm")}
      </Button>
    </Group>
  );
};

function CardConnectOrganization() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [valueIVA, setValueIVA] = useState("");
  const [org, setOrg] = useState<OrganizationDto>();
  const statusDefault = useStoreStatusIva((state: any) => state.statusDefault);
  const setDefault = useStoreStatusIva((state: any) => state.setDefault);
  const setNotRegistered = useStoreStatusIva(
    (state: any) => state.setNotRegistered
  );
  const setAlreadyRegisteredOpen = useStoreStatusIva(
    (state: any) => state.setAlreadyRegisteredOpen
  );
  const setAlreadyRegisteredClose = useStoreStatusIva(
    (state: any) => state.setAlreadyRegisteredClose
  );
  const height =
    statusDefault === "NotRegistered"
      ? 325
      : statusDefault === "AlreadyRegisteredClose"
      ? 380
      : 273;

  const checkIVA = async () => {
    return await postApiOrganizationGetPIva({ pIva: valueIVA }).then((org) => {
      if (org.pIva) {
        if (org.id !== "00000000-0000-0000-0000-000000000000") {
          setOrg(org);
        }
        setAlreadyRegisteredOpen();
      } else {
        setNotRegistered();
      }
    });
  };

  return (
    <Paper mih={height} p={24} radius={12} withBorder>
      <Text size={32} fw={600}>
        {t("components.connect-organization.connect-your-organization")}
      </Text>
      <Text size={16} fw={400} pt={14}>
        {t("components.connect-organization.register-or-connect-organization")}
      </Text>
      <Text size={16} fw={600} pt={14}>
        {t("components.connect-organization.enter-vat-number")}
      </Text>
      <Grid ml={1} mt={5}>
        <Input
          placeholder={t("components.connect-organization.vat-number")}
          pt={12}
          value={valueIVA}
          maxLength={11}
          onChange={(event: any) => (
            setValueIVA(event.target.value), setDefault()
          )}
          onKeyPress={(event: any) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Grid>
      {statusDefault == "AlreadyRegisteredOpen" ||
      statusDefault == "AlreadyRegisteredClose" ? (
        <AlreadyRegisteredComponent
          nameOrg={org?.name}
          onclick={() => setAlreadyRegisteredClose()}
        />
      ) : statusDefault == "NotRegistered" ? (
        <NotRegisteredComponent
          onclick={() => navigate("/register-org", { state: valueIVA })}
        />
      ) : (
        <DefaultButtonComponent value={valueIVA} onclick={checkIVA} />
      )}
    </Paper>
  );
}

export default CardConnectOrganization;
