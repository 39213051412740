import { ReactNode, useEffect } from "react";
import TopBar from "./TopBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container, Flex } from "@mantine/core";
import styled from "@emotion/styled";

import { create, useStore } from "zustand";
import { useAuth } from "../auth/AuthContext";
import { preload } from "swr";
import {
  getApiCallGetCallList,
  getApiOrganizationGetOrganizations,
  getGetApiCallGetCallListKey,
  getGetApiOrganizationGetOrganizationsKey,
} from "../api/endpoints";

const StyledContainer = styled(Container)`
  padding-top: 5rem;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledFlex = styled(Flex)`
  background-color: #fafafa;
`;
type SidebarStoreState = {
  content: ReactNode | null;
  setContent: (content: ReactNode) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
};

export const sidebarStore = create<SidebarStoreState>((set) => ({
  content: null,
  setContent: (content: ReactNode) => set({ content }),
  sidebarOpen: false,
  setSidebarOpen: (open: boolean) => set({ sidebarOpen: open }),
}));

function Layout() {
  const setSidebarOpen = useStore(
    sidebarStore,
    (state) => state.setSidebarOpen
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    sidebarStore.setState({ content: null });
    if (location.pathname === "/" && isAuthenticated) {
      preload(getGetApiCallGetCallListKey(), getApiCallGetCallList);
      preload(
        getGetApiOrganizationGetOrganizationsKey(),
        getApiOrganizationGetOrganizations
      );
      navigate("/calls");
    }
  }, [location.pathname]);

  useEffect(() => {
    setSidebarOpen(isAuthenticated ?? false);
  }, [isAuthenticated]);
  return (
    <StyledFlex direction="column">
      {isAuthenticated && <TopBar />}
      <Flex
        mt={isAuthenticated ? 30 : 0}
        style={{
          zIndex: 1,
          height: "100vh",
          backgroundColor: isAuthenticated ? "" : "white",
        }}
      >
        <StyledContainer w={{ sm: 800, md: 1024, lg: 1080, xl: 1400 }} pb={100}>
          <Outlet />
        </StyledContainer>
      </Flex>
    </StyledFlex>
  );
}

export default Layout;
