import { useParams } from "react-router-dom";
import { useGetApiPBITokens } from "../../api/endpoints/index";
import PbiReportComponent from "./PbiReportComponent";
import { Center, Text } from "@mantine/core";

export function PbiReport() {
  let params = useParams();
  const {
    data: pbi,
    error,
    isLoading,
  } = useGetApiPBITokens({
    workspaceId: params.wid ?? "0616d1be-5b6f-419e-9e6c-85eda340ed00",
    reportId: params.rid ?? "a97c1dc4-097b-4be5-a069-ff0f210a8b64",
  });

  if (isLoading) return <div>loading</div>;
  if (error) return <div>error</div>;
  return <PbiReportComponent pbi={pbi!}></PbiReportComponent>;
  //return (
  //  <Center>
  //    <Text>Nessun report disponibile</Text>
  //  </Center>
  //);
}
